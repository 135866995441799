import React, { useState } from "react";
import { Overlay } from "./overlay";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "@emotion/styled";
import { Flex, Box } from "@rebass/grid/emotion";
import { colors, mq } from "~style";
import css from "@emotion/css";
import { WieBezitOnzeStadComponentProps, Layer } from "~src/@types/types";
import { layers, districts } from "~src/data";
import { find } from "lodash";

const Input = styled.input`
  padding: 8px;
  flex: 1;
  display: flex;
  margin: 0;
  border: none;
  background: ${colors.blue2};
  color: ${colors.white};
  overflow: hidden;
`;

const Button = styled.button`
  background: ${colors.blue2};
  color: ${colors.white};
  cursor: pointer;
  border: none;
  font-weight: bold;
  padding: 8px 16px;
`;

export const ShareDialog = (props: any & WieBezitOnzeStadComponentProps) => {
  const { district, layer, fromYear, toYear } = props;
  const [copied, setCopied] = useState(-1);
  const links = [
    `${window.location.protocol}//${window.location.host}`,
    `${window.location.protocol}//${
      window.location.host
    }/${district}/${layer}/${fromYear}/${toYear}`
  ];
  return (
    <Overlay {...props}>
      <>
        <h1>Delen</h1>
        <p>Kopieer één van de onderstaande links om te delen.</p>
        <Box
          css={css`
            background: ${copied === 0 ? colors.yellow : colors.white};
            color: ${copied === 0 ? colors.black : colors.black};
            padding: 8px;
            margin: 16px 0px;
            position: relative;
            ${mq("lg")} {
              padding: 16px;
            }
          `}
        >
          <p>Vanaf het begin</p>
          <Flex
            css={css`
              margin-bottom: 16px;
              width: 100%;
            `}
          >
            <Input type="text" readOnly value={links[0]} />{" "}
            <CopyToClipboard text={links[0]} onCopy={() => setCopied(0)}>
              <Button
                css={css`
                  background: ${copied === 0 ? colors.black : colors.blue1};
                  color: ${copied === 0 ? colors.white : colors.white};
                `}
              >
                Kopieer
              </Button>
            </CopyToClipboard>
          </Flex>
          {copied === 0 && (
            <div
              css={css`
                color: ${colors.black};
                text-align: right;
                position: absolute;
                top: 0;
                right: 0;
                position: absolute;
                top: 8px;
                right: 16px;
                font-size: 0.7rem;
              `}
            >
              Gekopieerd
            </div>
          )}
        </Box>
        <Box
          css={css`
            background: ${copied === 1 ? colors.yellow : colors.white};
            color: ${copied === 1 ? colors.black : colors.black};
            padding: 8px;
            margin: 16px 0px;
            position: relative;
            ${mq("lg")} {
              padding: 16px;
            }
          `}
        >
          <p>
            Directe link naar{" "}
            {find(layers, (l: Layer) => {
              // console.log(l, layer);
              return l.key === layer;
            }).title.toLowerCase()}{" "}
            van {districts[district].name.replace("Gebied: ", "")} tussen{" "}
            {fromYear} en {toYear}
          </p>
          <Flex
            css={css`
              margin-bottom: 16px;
            `}
          >
            <Input type="text" readOnly value={links[1]} />{" "}
            <CopyToClipboard text={links[1]} onCopy={() => setCopied(1)}>
              <Button
                css={css`
                  background: ${copied === 1 ? colors.black : colors.blue1};
                  color: ${copied === 1 ? colors.white : colors.white};
                `}
              >
                Kopieer
              </Button>
            </CopyToClipboard>
          </Flex>
          {copied === 1 && (
            <div
              css={css`
                color: ${colors.black};
                text-align: right;
                position: absolute;
                top: 8px;
                right: 16px;
                font-size: 0.7rem;
              `}
            >
              Gekopieerd
            </div>
          )}
        </Box>
      </>
    </Overlay>
  );
};
