import styled from "@emotion/styled";
import { shuffle } from "lodash";
import React, { useState } from "react";
import districts from "~data/districts";
import { WieBezitOnzeStadComponentProps } from "~src/@types/types";
import { colors, mq } from "~src/style";
import { Flex, Box } from "@rebass/grid/emotion";
import css from "@emotion/css";
import useInterval from "@use-it/interval";
import { strings } from "~src/data";

const Container = styled(Flex)`
  flex: 1;
  background: ${colors.blue1};
  color: ${colors.white};
`;

const Label = styled.div`
  font-family: proxima-nova-condensed, sans-serif;
  font-style: italic;
  line-height: 1.5;
  font-size: 2rem;
  text-align: center;
  ${mq("lg")} {
    text-align: left;
  }
`;

const NavigationButton = (props: any) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    css={css`
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #0008;
      ${props.css}
    `}
    {...props}
  />
);

export const Quotes = ({
  district: districtKey,
  data
}: WieBezitOnzeStadComponentProps) => {
  const district = districts[districtKey!];
  if (!district) {
    return <Container />;
  }
  if (!data!.quotes) {
    return <Container />;
  }
  const quotesForDistrict = shuffle(
    data!.quotes.edges.map(edge => edge.node)
  ).filter(node => node.cbsBuurt === district.name);
  const defaultQuotes = shuffle(
    data!.quotes.edges.map(edge => edge.node).filter(node => !node.cbsBuurt)
  );
  const selectedQuotes = quotesForDistrict.concat(defaultQuotes);
  const [index, setIndex] = useState(0);
  const decrementIndex = () => {
    setIndex(index <= 0 ? selectedQuotes.length - 1 : index - 1);
  };
  const incrementIndex = () => {
    setIndex(index >= selectedQuotes.length - 1 ? 0 : index + 1);
  };
  const [isPlaying, setIsPlaying] = useState(true);
  useInterval(() => isPlaying && incrementIndex(), 10000);
  const quote = selectedQuotes[index];
  return (
    <Container flexDirection="column" alignItems="center" p={[16, 48]}>
      <Box mb={[16, 32]} mt={[16, 0]} width={[150, 300]}>
        <img width="100%" src={require("../assets/quotes.gif")} />
      </Box>
      <Label>{strings.quotesTitle}</Label>
      <Flex
        css={css`
          font-weight: bold;
        `}
        width={1}
        justifyContent="center"
        alignItems="center"
      >
        <NavigationButton
          onClick={() => {
            decrementIndex();
            setIsPlaying(false);
          }}
        >
          {"<"}
        </NavigationButton>
        <Flex
          flexDirection="column"
          justifyContent="center"
          css={css`
            background: ${colors.yellow};
            color: ${colors.blue1};
            padding: 16px 32px;
            text-align: center;
            height: 300px;
            overflow-y: hidden;

            scrollbar-width: none;
            margin: 32px 4px;
            ${mq("lg")} {
              margin: 32px 56px 0;
            }
          `}
          width={600}
        >
          {quote && (
            <>
              <Box
                css={css`
                  margin-bottom: 16px;
                  font-size: 1rem;
                  ${mq("lg")} {
                    font-size: 1.5rem;
                  }
                `}
              >
                “{quote.text}”
              </Box>
              <Box
                css={css`
                  font-weight: normal;
                `}
              >
                {quote.name} - {quote.title}
              </Box>
            </>
          )}
        </Flex>
        <NavigationButton
          onClick={() => {
            incrementIndex();
            setIsPlaying(false);
          }}
        >
          {">"}
        </NavigationButton>
      </Flex>
      {/* <DataContainer>
        <dl>
          {selectedQuotes.map((quote, i) => (
            <span key={`key-${i}`}>
              <dt>
                {quote.name}, {quote.title}
              </dt>
              <dd>{quote.text}</dd>
            </span>
          ))}
        </dl>
      </DataContainer> */}
    </Container>
  );
};
