import React from "react";
import styled from "@emotion/styled";
import { colors } from "~src/style";
import { Flex, Box } from "@rebass/grid/emotion";
import css from "@emotion/css";
import LogoFacebook from "react-ionicons/lib/LogoFacebook";
import LogoInstagram from "react-ionicons/lib/LogoInstagram";
import LogoTwitter from "react-ionicons/lib/LogoTwitter";
import LogoYoutube from "react-ionicons/lib/LogoYoutube";
import LogoRss from "react-ionicons/lib/LogoRss";
import { strings } from "~src/data";
import { Link } from "gatsby";

const Container = styled.div`
  background: ${colors.blue1};
  color: ${colors.white};
  padding: 48px 16px 16px;
`;

const Logo = (props: any) => (
  <a href={props.url} target="_blank">
    <img
      css={css`
        display: block;
        opacity: 0.5;
        max-width: 100%;
        max-height: 60px;
      `}
      {...props}
    />
  </a>
);

const SocialIconContainer = styled.a`
  padding: 8px;
  background: ${colors.white};
  margin: 2px;
`;

export const Footer = (props: any) => (
  <Container>
    <Flex
      css={css`
        max-width: 800px;
        margin: 0 auto;
      `}
      flexDirection="column"
      alignItems="center"
    >
      <img
        css={css`
          margin-bottom: 24px;
        `}
        src={require("../assets/vb2.png")}
      />
      <Flex flexWrap={["wrap", "nowrap"]} css={css``}>
        <Box mx={16} width={[1, 1 / 2]}>
          <h3>Over</h3>
          <div
            css={css`
              a {
                color: ${colors.white};
              }
            `}
            dangerouslySetInnerHTML={{ __html: strings.aboutHTML }}
          />
          <Link
            css={css`
              color: ${colors.white};
              display: block;
            `}
            to="/colofon"
          >
            Colofon
          </Link>
          <Link
            css={css`
              color: ${colors.white};
              display: block;
            `}
            to="/disclaimer"
          >
            Disclaimer
          </Link>
        </Box>
        <Box mx={16} width={[1, 1 / 4]}>
          <h3>Contact</h3>
          <p>
            Stichting Vers beton
            <br />
            Gouvernestraat 7c
            <br />
            3014 H Rotterdam
            <br />
            KvK: 59018364
            <br />
            BTW: 853282031B01
            <br />
          </p>
        </Box>
        <Box mx={16} width={[1, 1 / 4]}>
          <h3>Volg Vers Beton!</h3>
          <Flex>
            <SocialIconContainer
              target="_blank"
              href="https://www.facebook.com/VersBeton"
            >
              <LogoFacebook color={colors.blue1} />
            </SocialIconContainer>
            <SocialIconContainer
              target="_blank"
              href="https://www.instagram.com/versbeton/"
            >
              <LogoInstagram color={colors.blue1} />
            </SocialIconContainer>
            <SocialIconContainer
              target="_blank"
              href="https://twitter.com/versbeton"
            >
              <LogoTwitter color={colors.blue1} />
            </SocialIconContainer>
          </Flex>
          <Flex>
            <SocialIconContainer
              target="_blank"
              href="https://www.youtube.com/channel/UCUWxqUVo3ipEzTq856-a7xQ"
            >
              <LogoYoutube color={colors.blue1} />
            </SocialIconContainer>
            <SocialIconContainer
              target="_blank"
              href="https://versbeton.nl/feed/"
            >
              <LogoRss color={colors.blue1} />
            </SocialIconContainer>
          </Flex>
        </Box>
      </Flex>
      <div>
        <Flex
          css={css`
            margin: 64px 0;
          `}
          justifyContent="center"
          alignItems="center"
          flexWrap={["wrap", "nowrap"]}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="16px"
            my={[16, 0]}
            width={[1 / 2, 1 / 5]}
          >
            <Logo
              src={require("../assets/svdj.png")}
              url="https://www.svdj.nl"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="16px"
            my={[16, 0]}
            width={[1 / 2, 1 / 5]}
          >
            <Logo
              src={require("../assets/sci.png")}
              url="https://www.stimuleringsfonds.nl"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="16px"
            my={[16, 0]}
            width={[1 / 2, 1 / 5]}
          >
            <Logo
              src={require("../assets/fbjp.png")}
              url="https://fondsbjp.nl/"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="16px"
            my={[16, 0]}
            width={[1 / 2, 1 / 5]}
          >
            <Logo
              src={require("../assets/open-rotterdam.png")}
              url="https://www.openrotterdam.nl/"
            />
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            mx="16px"
            my={[16, 0]}
            width={[1 / 2, 1 / 5]}
          >
            <Logo
              src={require("../assets/afbuza.png")}
              url="http://afbuza.nl"
            />
          </Flex>
        </Flex>
      </div>
      <div
        css={css`
          font-size: 0.6rem;
          margin-bottom: 64px;
        `}
      >
        {" "}
        &#9400; 2019 Stichting Vers Beton
      </div>
    </Flex>
  </Container>
);
