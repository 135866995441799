import React from "react";
import { Flex } from "@rebass/grid/emotion";
import css from "@emotion/css";
import { colors } from "~src/style";

type Props = {
  onZoomIn: () => void;
  onZoomOut: () => void;
  showRoffa: boolean;
  onShowRoffa: () => void;
};

export const ZoomControls = (props: Props) => {
  const { onZoomIn, onZoomOut, showRoffa, onShowRoffa } = props;
  return (
    <Flex
      flexDirection="column"
      css={css`
        position: absolute;
        bottom: 0px;
        right: 0px;
      `}
    >
      {showRoffa && (
        <Flex
          alignItems="center"
          justifyContent="center"
          css={css`
            cursor: pointer;
            padding: 4px;
            width: 32px;
            height: 32px;
            border: 2px solid ${colors.blue2};
            margin: 2px 4px;
            background: ${colors.white};
          `}
          onClick={() => onShowRoffa()}
        >
          <img
            css={css`
              width: 24px;
            `}
            width="24px"
            src="/roffa.png"
          />
        </Flex>
      )}
      <Flex
        alignItems="center"
        justifyContent="center"
        css={css`
          cursor: pointer;
          padding: 4px;
          width: 32px;
          height: 32px;
          border: 2px solid ${colors.blue2};
          margin: 2px 4px;
          background: ${colors.white};
        `}
        onClick={() => onZoomIn()}
      >
        <img
          css={css`
            width: 24px;
          `}
          width="24px"
          src="/zoom-in.png"
        />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        css={css`
          cursor: pointer;
          padding: 4px;
          width: 32px;
          height: 32px;
          border: 2px solid ${colors.blue2};
          margin: 2px 4px;
          background: ${colors.white};
        `}
        onClick={() => onZoomOut()}
      >
        <img
          css={css`
            width: 24px;
          `}
          width="24px"
          src="/zoom-out.png"
        />
      </Flex>
    </Flex>
  );
};
