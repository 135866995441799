import React from "react";
import { colors } from "~src/style";

export const PriceIllustration = (props: any) => (
  <svg viewBox="0 0 131.24 173.44" {...props}>
    <title>{"price"}</title>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <rect
          x={15.06}
          y={60.13}
          width={7.39}
          height={7.39}
          rx={3.7}
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={1.58}
        />
        <rect
          x={9.33}
          y={53.99}
          width={9.34}
          height={9.34}
          rx={4.67}
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <rect
          x={1.29}
          y={46.63}
          width={12.05}
          height={12.05}
          rx={6.03}
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={2.58}
        />
        <path d="M127.07 11.91h-73a.64.64 0 0 1-.64-.64v-7.9L49.85.16a.66.66 0 0 0-.87 0l-3.45 3.2v7.9a.64.64 0 0 1-.64.64H32.78l-3.66 4.18a.64.64 0 0 0 0 .86l3.46 3.74a.64.64 0 0 0 .47.21h11.84a.64.64 0 0 1 .64.64v51.8a.65.65 0 0 0 .65.65h6.63a.65.65 0 0 0 .64-.65V21.54a.64.64 0 0 1 .64-.64h73a.64.64 0 0 0 .48-.22l3.54-4a.65.65 0 0 0 0-.87l-3.52-3.68a.63.63 0 0 0-.52-.22z" />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.18}
          d="M72.37 22.51v4.35M110.94 22.51v4"
        />
        <text
          transform="translate(67.76 81.34)"
          fontSize={21}
          fill={colors.blue2}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          {props.percentage}
        </text>
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.18}
          d="M68.87 58.9h12.45M68.87 54h12.45M80.54 91.23h12.45M80.54 86.33h12.45"
        />
        <path
          d="M93.53 115.37a3 3 0 0 1 2.47 2.37.61.61 0 0 1-.75.69 3.11 3.11 0 0 1-2.41-2.36.6.6 0 0 1 .69-.7z"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={0.14}
        />
        <path d="M32.31 76.22l-21.75 25.43a1.28 1.28 0 0 0 1 2.11h17.19a1.26 1.26 0 0 0 1-.45l21.73-25.43a1.28 1.28 0 0 0-1-2.11h-17.2a1.31 1.31 0 0 0-.97.45z" />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.82}
          d="M62.12 114.98H45.35"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.11}
          d="M27.83 137.29l.05-31.01"
        />
        <path d="M37.85 110.28v11a1.21 1.21 0 0 1-1.21 1.21h-4a1.21 1.21 0 0 1-1.2-1.21v-11a1.21 1.21 0 0 1 1.2-1.21h4a1.22 1.22 0 0 1 1.21 1.21zM71.25 125.11v3.76a1.18 1.18 0 0 1-1.18 1.18H66a1.18 1.18 0 0 1-1.18-1.18v-3.74A1.18 1.18 0 0 1 66 124h4.11a1.18 1.18 0 0 1 1.14 1.11zM63.56 125.11v3.76a1.18 1.18 0 0 1-1.18 1.18h-4.1a1.18 1.18 0 0 1-1.18-1.18v-3.74a1.18 1.18 0 0 1 1.17-1.13h4.11a1.18 1.18 0 0 1 1.18 1.11zM71.25 132.34v3.76a1.18 1.18 0 0 1-1.18 1.18H66a1.18 1.18 0 0 1-1.18-1.18v-3.74a1.17 1.17 0 0 1 1.18-1.18h4.11a1.18 1.18 0 0 1 1.14 1.16zM63.56 132.34v3.76a1.18 1.18 0 0 1-1.18 1.18h-4.1a1.18 1.18 0 0 1-1.18-1.18v-3.74a1.17 1.17 0 0 1 1.17-1.18h4.11a1.18 1.18 0 0 1 1.18 1.16z" />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.11}
          d="M60.33 101.28H46.15M60.33 96.95H46.15"
        />
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          d="M76.71 115.01H64.85"
        />
        <path
          d="M90.36 114.65a3 3 0 0 0 2.42-2.37.61.61 0 0 0-.75-.69 3.12 3.12 0 0 0-2.4 2.36.6.6 0 0 0 .73.7zM90.36 115.37a3 3 0 0 1 2.42 2.37.6.6 0 0 1-.75.69 3.1 3.1 0 0 1-2.4-2.36.6.6 0 0 1 .73-.7zM93.43 114.65a3 3 0 0 0 2.42-2.37.61.61 0 0 0-.75-.69 3.13 3.13 0 0 0-2.4 2.41.6.6 0 0 0 .73.65zM95.21 114.56a3 3 0 0 1 3.36.11.61.61 0 0 1-.1 1 3.13 3.13 0 0 1-3.35-.12.61.61 0 0 1 .09-.99z"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={0.14}
        />
        <path d="M15.35 89V72.27a1.72 1.72 0 0 1 1.71-1.72h4.31a1.72 1.72 0 0 1 1.73 1.72v11.54a1.77 1.77 0 0 1-.4 1.1l-4.31 5.16A1.72 1.72 0 0 1 15.35 89z" />
        <circle cx={75.32} cy={172.22} r={1.13} />
        <circle cx={71.19} cy={172.22} r={1.13} />
        <circle cx={67.07} cy={172.22} r={1.12} />
        <path d="M24.43 72v7.83a1.42 1.42 0 0 0 2.46 1 1.45 1.45 0 0 0 .38-1V72a1.42 1.42 0 0 0-1.46-1.41A1.42 1.42 0 0 0 24.43 72z" />
        <circle cx={57.64} cy={108.7} r={2.82} />
        <circle cx={49.18} cy={108.7} r={2.82} />
        <g
          fill="none"
          stroke={colors.blue2}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.5}
        >
          <path d="M83.95 106.49l6.25-7.75 6.5 6.75 7-10.5 5.35 5.1 8.59-11.24" />
          <path d="M112.19 89.64l5.45-.79 1.04 5.4" />
        </g>
        <path
          d="M106.22 35.94L58.58 48.39a1.27 1.27 0 0 1-1.58-.92L54.92 39a1.26 1.26 0 0 1 .91-1.53L103.47 25a1.27 1.27 0 0 1 1.55.92l2.11 8.49a1.26 1.26 0 0 1-.91 1.53z"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={1.61}
        />
        <g fill={colors.yellow}>
          <path d="M63.44 37.31l.35 1.32-2.24.58 1.59 6.14-1.57.41L60 39.62l-2.24.58-.34-1.33zM70.22 37l-4 1 .41 1.59 3.62-.94.34 1.29-3.59.93.5 1.92 4.13-1.07.37 1.34-5.66 1.47-1.94-7.47 5.47-1.41zM74.18 34.53l1.53-.39.8 3.07 2.09-3.82 2-.52-2.27 3.86 4.36 3.56-2 .52-3.14-2.66-.55 1 .64 2.5-1.53.4zM89.15 37.92a3.43 3.43 0 0 1-2.22 1.5 3.32 3.32 0 0 1-2.67-.24 4.12 4.12 0 0 1-1.94-2.78 4.08 4.08 0 0 1 .34-3.4 4 4 0 0 1 4.89-1.27 4.08 4.08 0 0 1 1.93 2.79 4.19 4.19 0 0 1-.33 3.4zM87.88 37a3.19 3.19 0 0 0 0-2.09 3.16 3.16 0 0 0-1-1.8 1.85 1.85 0 0 0-1.65-.31 1.88 1.88 0 0 0-1.3 1.07 3.18 3.18 0 0 0 0 2.09 3.12 3.12 0 0 0 1.06 1.8 1.85 1.85 0 0 0 1.65.3A1.87 1.87 0 0 0 87.88 37zM97.21 35.83a3.31 3.31 0 0 1-2.21 1.5 3.36 3.36 0 0 1-2.67-.23 4.19 4.19 0 0 1-1.93-2.79 4.08 4.08 0 0 1 .34-3.37 3.31 3.31 0 0 1 2.26-1.5 3.37 3.37 0 0 1 2.67.23 4.07 4.07 0 0 1 1.92 2.79 4.15 4.15 0 0 1-.38 3.37zM95.94 35a3.18 3.18 0 0 0 0-2.09 3.23 3.23 0 0 0-1-1.8 2 2 0 0 0-3 .76 3.12 3.12 0 0 0 0 2.09 3.16 3.16 0 0 0 1 1.8 1.87 1.87 0 0 0 1.66.3A1.81 1.81 0 0 0 95.94 35zM103.88 31.37a3 3 0 0 1-1.73 1l-1.56.4.7 2.68-1.55.41-1.93-7.47 3.2-.83a2.69 2.69 0 0 1 1.92.12 2.32 2.32 0 0 1 1.12 1.62 2.4 2.4 0 0 1-.17 2.07zM102 29a1.26 1.26 0 0 0-.89 0l-1.41.37.57 2.19 1.41-.36a1.23 1.23 0 0 0 .76-.48 1.15 1.15 0 0 0 .07-.93A1 1 0 0 0 102 29z" />
        </g>
        <path d="M87.7 120.29l-7.91 10.46a1 1 0 0 1-1.76-.59v-4.61a2.83 2.83 0 0 1 .47-1.55l2.61-4s6.89.54 6.59.29z" />
        <path d="M84.93 122.34s-2.86.56-4-1.42c-.5-1.06-.3-1.52-1.37-2.26s-2.49-1.93-1.93-2.85 1.54-.69 3 .24c1 .7 1.69 1.34 2.24.88s.56-1.15-.33-2a1.75 1.75 0 0 0-2.35-.28c-.42.37-.84.69-1.45.41s-1.15-1-.35-2a3.47 3.47 0 0 1 2.21-1.22 1.36 1.36 0 0 1 1.4-1.69 4 4 0 0 1 3.53 1.85s2.07-.59 2.94 2a5.66 5.66 0 0 1 0 2.57c-.12.84-.31.84-.24 1.7a4.15 4.15 0 0 1-3.3 4.07z" />
        <path
          d="M79.88 111.65s4.52-.86 4.71 3.64M85.3 111.25a5.52 5.52 0 0 1 1.49 4.25"
          fill="none"
          stroke={colors.yellow}
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={0.64}
        />
        <path
          d="M95 164.57s-7.11.1-8.11 7.35M89 161.07s-5.71 3.05-5.71 11.05M80 172.12s.5-5-3.25-7.25"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.1}
        />
        <path d="M23.37 170.89l10.24-4.38-6.88-20.35a1.78 1.78 0 0 1 1.68-2.43l9.64.13a1.77 1.77 0 0 1 1.75 1.78v21.05l.35 5.23a1.41 1.41 0 0 1-2.7.68l-.36-.77-1 1a1.46 1.46 0 0 1-1 .38H23.34s-.83.14-.83-.61v-.4a1.42 1.42 0 0 1 .86-1.31zM59 170.89l-10.23-4.38 6.88-20.35a1.78 1.78 0 0 0-1.68-2.43l-9.64.13a1.78 1.78 0 0 0-1.76 1.78v21.05l-.35 5.23a1.42 1.42 0 0 0 1.42 1.51 1.41 1.41 0 0 0 1.29-.83l.35-.77 1 1a1.44 1.44 0 0 0 1 .38H59s.83.14.83-.61v-.4a1.42 1.42 0 0 0-.83-1.31z" />
        <path
          d="M15.8 127.11a2 2 0 0 0 2.74-1.75h0c0-.74.21-2.53-.46-2.85l-3.33-1.83c-1.33-.64-2.39-1.26-1.19-2.1l6.49-4.58a1.91 1.91 0 0 0 .82-1.58v-2.25a1.88 1.88 0 0 0-1.92-1.87h-3.4a2 2 0 0 0-1.37.57l-7.54 7.6-2.07 2.24a2 2 0 0 0-.3 2.6s3.73 3.26 11.53 5.8z"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={0.52}
        />
        <g strokeLinecap="round" strokeLinejoin="round">
          <path
            d="M16.16 122.09a4.24 4.24 0 0 1 4.81 1 14.29 14.29 0 0 0 2.46 2.2c1.09.68 3 1.36 2.61 2.88s-2.34.82-3 .29-1.32-.93-1.69-.54.15 1.24.57 2.12 1.75 3.06.5 3.82a1.73 1.73 0 0 1-2.11-.28 1.16 1.16 0 0 1-1.05.82 2.14 2.14 0 0 1-1.53-.51 2.1 2.1 0 0 1-1.45.37c-.86-.11-1.95-1.35-2.6-2.84-.54-1.22-2-4.11-.32-7.14.64-1.56 2.8-2.19 2.8-2.19z"
            stroke="#000"
            strokeWidth={0.56}
          />
          <path
            d="M20.83 134.77s-1.18 0-2.32-5M17.78 134.82a6.7 6.7 0 0 1-2-4.62"
            fill="none"
            stroke={colors.yellow}
            strokeWidth={0.84}
          />
        </g>
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.11}
          d="M11 106.24v3.33"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.11}
          d="M11 130.41v10.34h64.16v-23.01"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.47}
          d="M44.09 137.25v-3.42h-3.75l-.08-.01v-3.91h-3.74l-.08-.01v-3.92h-3.3"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.11}
          d="M53.91 78.41l21.18 21.37v12.08"
        />
        <path
          d="M22.35 93.55h2.88a.71.71 0 0 0 .54-.25l2.82-3.22c.37-.38-.06-.34-.06-.34h-2.74a.71.71 0 0 0-.54.25l-3 3.42s-.1.14.1.14zM28.69 93.55h2.87a.69.69 0 0 0 .54-.25l2.82-3.22c.38-.38-.06-.34-.06-.34h-2.73a.73.73 0 0 0-.55.25l-3 3.42s-.1.14.11.14zM27.45 87.62h2.88a.71.71 0 0 0 .54-.25l2.82-3.22c.38-.38-.06-.34-.06-.34h-2.74a.75.75 0 0 0-.54.24l-3 3.43s-.1.14.1.14zM33.79 87.62h2.87a.69.69 0 0 0 .54-.25l2.8-3.22c.38-.38-.06-.34-.06-.34h-2.71a.72.72 0 0 0-.54.24l-3 3.43s-.11.14.1.14z"
          fill={colors.yellow}
          stroke={colors.yellow}
          strokeMiterlimit={10}
          strokeWidth={0.97}
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.11}
          d="M58.83 51.24v26.83M109.33 29.11h14.33v80.13H99.5M58.7 34.27v-4.7h18.63"
        />
        <text
          transform="translate(85.04 60.41)"
          fontSize={12}
          fill={colors.blue2}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          {props.year}
        </text>
      </g>
    </g>
  </svg>
);

export default PriceIllustration;
