export type Districts = {
  [key: string]: {
    slug: string;
    name: string;
    children: string[];
    parent: string | null;
    hideInDropdown: boolean | null;
  };
};

export const districts: Districts = {
  rotterdam: {
    slug: "rotterdam",
    name: "Rotterdam",
    children: [
      "gebied-centrum",
      "gebied-delfshaven",
      "gebied-overschie",
      "gebied-noord",
      "gebied-hillegersberg-schiebroek",
      "gebied-kralingen-crooswijk",
      "gebied-feijenoord",
      "gebied-ijsselmonde",
      "gebied-pernis",
      "gebied-prins-alexander",
      "gebied-charlois",
      "gebied-hoogvliet",
      "gebied-hoek-van-holland",
      "gebied-rozenburg",
      "gebied-overig-haven-industrie"
    ],
    parent: null
  },
  "gebied-centrum": {
    slug: "gebied-centrum",
    name: "Gebied: Centrum",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "rotterdam"
  },
  stadsdriehoek: {
    slug: "stadsdriehoek",
    name: "Stadsdriehoek",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  "oude-westen": {
    slug: "oude-westen",
    name: "Oude Westen",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  cool: {
    slug: "cool",
    name: "Cool",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  "cs-kwartier": {
    slug: "cs-kwartier",
    name: "CS Kwartier",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  "nieuwe-werk": {
    slug: "nieuwe-werk",
    name: "Nieuwe Werk",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  dijkzigt: {
    slug: "dijkzigt",
    name: "Dijkzigt",
    children: [
      "stadsdriehoek",
      "oude-westen",
      "cool",
      "cs-kwartier",
      "nieuwe-werk",
      "dijkzigt"
    ],
    parent: "gebied-centrum"
  },
  "gebied-delfshaven": {
    slug: "gebied-delfshaven",
    name: "Gebied: Delfshaven",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "rotterdam"
  },
  delfshaven: {
    slug: "delfshaven",
    name: "Delfshaven",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  bospolder: {
    slug: "bospolder",
    name: "Bospolder",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  tussendijken: {
    slug: "tussendijken",
    name: "Tussendijken",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  spangen: {
    slug: "spangen",
    name: "Spangen",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  "nieuwe-westen": {
    slug: "nieuwe-westen",
    name: "Nieuwe Westen",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  middelland: {
    slug: "middelland",
    name: "Middelland",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  "nieuw-mathenesse": {
    slug: "nieuw-mathenesse",
    name: "Nieuw-Mathenesse",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  "oud-mathenesse": {
    slug: "oud-mathenesse",
    name: "Oud-Mathenesse",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  "witte-dorp": {
    slug: "witte-dorp",
    name: "Witte Dorp",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  schiemond: {
    slug: "schiemond",
    name: "Schiemond",
    children: [
      "delfshaven",
      "bospolder",
      "tussendijken",
      "spangen",
      "nieuwe-westen",
      "middelland",
      "nieuw-mathenesse",
      "oud-mathenesse",
      "witte-dorp",
      "schiemond"
    ],
    parent: "gebied-delfshaven"
  },
  "gebied-overschie": {
    slug: "gebied-overschie",
    name: "Gebied: Overschie",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "rotterdam"
  },
  kleinpolder: {
    slug: "kleinpolder",
    name: "Kleinpolder",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  "noord-kethel": {
    slug: "noord-kethel",
    name: "Noord-Kethel",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  zestienhoven: {
    slug: "zestienhoven",
    name: "Zestienhoven",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  overschie: {
    slug: "overschie",
    name: "Overschie",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  landzicht: {
    slug: "landzicht",
    name: "Landzicht",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  schieveen: {
    slug: "schieveen",
    name: "Schieveen",
    children: [
      "kleinpolder",
      "noord-kethel",
      "zestienhoven",
      "overschie",
      "landzicht",
      "schieveen"
    ],
    parent: "gebied-overschie"
  },
  "gebied-noord": {
    slug: "gebied-noord",
    name: "Gebied: Noord",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "rotterdam"
  },
  agniesebuurt: {
    slug: "agniesebuurt",
    name: "Agniesebuurt",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  provenierswijk: {
    slug: "provenierswijk",
    name: "Provenierswijk",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  bergpolder: {
    slug: "bergpolder",
    name: "Bergpolder",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  blijdorp: {
    slug: "blijdorp",
    name: "Blijdorp",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  liskwartier: {
    slug: "liskwartier",
    name: "Liskwartier",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  "oude-noorden": {
    slug: "oude-noorden",
    name: "Oude Noorden",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  blijdorpsepolder: {
    slug: "blijdorpsepolder",
    name: "Blijdorpsepolder",
    children: [
      "agniesebuurt",
      "provenierswijk",
      "bergpolder",
      "blijdorp",
      "liskwartier",
      "oude-noorden",
      "blijdorpsepolder"
    ],
    parent: "gebied-noord"
  },
  "gebied-hillegersberg-schiebroek": {
    slug: "gebied-hillegersberg-schiebroek",
    name: "Gebied: Hillegersberg-Schiebroek",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "rotterdam"
  },
  schiebroek: {
    slug: "schiebroek",
    name: "Schiebroek",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "gebied-hillegersberg-schiebroek"
  },
  "hillegersberg-zuid": {
    slug: "hillegersberg-zuid",
    name: "Hillegersberg-Zuid",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "gebied-hillegersberg-schiebroek"
  },
  "hillegersberg-noord": {
    slug: "hillegersberg-noord",
    name: "Hillegersberg-Noord",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "gebied-hillegersberg-schiebroek"
  },
  terbregge: {
    slug: "terbregge",
    name: "Terbregge",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "gebied-hillegersberg-schiebroek"
  },
  molenlaankwartier: {
    slug: "molenlaankwartier",
    name: "Molenlaankwartier",
    children: [
      "schiebroek",
      "hillegersberg-zuid",
      "hillegersberg-noord",
      "terbregge",
      "molenlaankwartier"
    ],
    parent: "gebied-hillegersberg-schiebroek"
  },
  "gebied-kralingen-crooswijk": {
    slug: "gebied-kralingen-crooswijk",
    name: "Gebied: Kralingen-Crooswijk",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "rotterdam"
  },
  rubroek: {
    slug: "rubroek",
    name: "Rubroek",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "nieuw-crooswijk": {
    slug: "nieuw-crooswijk",
    name: "Nieuw-Crooswijk",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "oud-crooswijk": {
    slug: "oud-crooswijk",
    name: "Oud-Crooswijk",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "kralingen-west": {
    slug: "kralingen-west",
    name: "Kralingen-West",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "kralingen-oost": {
    slug: "kralingen-oost",
    name: "Kralingen-Oost",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "kralingse-bos": {
    slug: "kralingse-bos",
    name: "Kralingse Bos ",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "de-esch": {
    slug: "de-esch",
    name: "De Esch",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  struisenburg: {
    slug: "struisenburg",
    name: "Struisenburg",
    children: [
      "rubroek",
      "nieuw-crooswijk",
      "oud-crooswijk",
      "kralingen-west",
      "kralingen-oost",
      "kralingse-bos",
      "de-esch",
      "struisenburg"
    ],
    parent: "gebied-kralingen-crooswijk"
  },
  "gebied-feijenoord": {
    slug: "gebied-feijenoord",
    name: "Gebied: Feijenoord",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "rotterdam"
  },
  "kop-van-zuid": {
    slug: "kop-van-zuid",
    name: "Kop van Zuid",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  "kop-van-zuid-entrepot": {
    slug: "kop-van-zuid-entrepot",
    name: "Kop van Zuid - Entrepot",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  vreewijk: {
    slug: "vreewijk",
    name: "Vreewijk",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  bloemhof: {
    slug: "bloemhof",
    name: "Bloemhof",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  hillesluis: {
    slug: "hillesluis",
    name: "Hillesluis",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  katendrecht: {
    slug: "katendrecht",
    name: "Katendrecht",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  afrikaanderwijk: {
    slug: "afrikaanderwijk",
    name: "Afrikaanderwijk",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  feijenoord: {
    slug: "feijenoord",
    name: "Feijenoord",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  noordereiland: {
    slug: "noordereiland",
    name: "Noordereiland",
    children: [
      "kop-van-zuid",
      "kop-van-zuid-entrepot",
      "vreewijk",
      "bloemhof",
      "hillesluis",
      "katendrecht",
      "afrikaanderwijk",
      "feijenoord",
      "noordereiland"
    ],
    parent: "gebied-feijenoord"
  },
  "gebied-ijsselmonde": {
    slug: "gebied-ijsselmonde",
    name: "Gebied: IJsselmonde",
    children: [
      "oud-ijsselmonde",
      "lombardijen",
      "groot-ijsselmonde",
      "beverwaard"
    ],
    parent: "rotterdam"
  },
  "oud-ijsselmonde": {
    slug: "oud-ijsselmonde",
    name: "Oud-IJsselmonde",
    children: [
      "oud-ijsselmonde",
      "lombardijen",
      "groot-ijsselmonde",
      "beverwaard"
    ],
    parent: "gebied-ijsselmonde"
  },
  lombardijen: {
    slug: "lombardijen",
    name: "Lombardijen",
    children: [
      "oud-ijsselmonde",
      "lombardijen",
      "groot-ijsselmonde",
      "beverwaard"
    ],
    parent: "gebied-ijsselmonde"
  },
  "groot-ijsselmonde": {
    slug: "groot-ijsselmonde",
    name: "Groot-IJsselmonde",
    children: [
      "oud-ijsselmonde",
      "lombardijen",
      "groot-ijsselmonde",
      "beverwaard"
    ],
    parent: "gebied-ijsselmonde"
  },
  beverwaard: {
    slug: "beverwaard",
    name: "Beverwaard",
    children: [
      "oud-ijsselmonde",
      "lombardijen",
      "groot-ijsselmonde",
      "beverwaard"
    ],
    parent: "gebied-ijsselmonde"
  },
  "gebied-pernis": {
    slug: "gebied-pernis",
    name: "Gebied: Pernis",
    children: ["pernis"],
    parent: "rotterdam"
  },
  pernis: {
    slug: "pernis",
    name: "Pernis",
    children: ["pernis"],
    parent: "gebied-pernis"
  },
  "gebied-prins-alexander": {
    slug: "gebied-prins-alexander",
    name: "Gebied: Prins Alexander",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "rotterdam"
  },
  "s-gravenland": {
    slug: "s-gravenland",
    name: "s-Gravenland",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  kralingseveer: {
    slug: "kralingseveer",
    name: "Kralingseveer",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  prinsenland: {
    slug: "prinsenland",
    name: "Prinsenland",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  "het-lage-land": {
    slug: "het-lage-land",
    name: "Het Lage Land",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  ommoord: {
    slug: "ommoord",
    name: "Ommoord",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  zevenkamp: {
    slug: "zevenkamp",
    name: "Zevenkamp",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  oosterflank: {
    slug: "oosterflank",
    name: "Oosterflank",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  nesselande: {
    slug: "nesselande",
    name: "Nesselande",
    children: [
      "s-gravenland",
      "kralingseveer",
      "prinsenland",
      "het-lage-land",
      "ommoord",
      "zevenkamp",
      "oosterflank",
      "nesselande"
    ],
    parent: "gebied-prins-alexander"
  },
  "gebied-charlois": {
    slug: "gebied-charlois",
    name: "Gebied: Charlois",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "rotterdam"
  },
  tarwewijk: {
    slug: "tarwewijk",
    name: "Tarwewijk",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  carnisse: {
    slug: "carnisse",
    name: "Carnisse",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  zuidwijk: {
    slug: "zuidwijk",
    name: "Zuidwijk",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  "oud-charlois": {
    slug: "oud-charlois",
    name: "Oud-Charlois",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  wielewaal: {
    slug: "wielewaal",
    name: "Wielewaal",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  zuidplein: {
    slug: "zuidplein",
    name: "Zuidplein",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  pendrecht: {
    slug: "pendrecht",
    name: "Pendrecht",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  zuiderpark: {
    slug: "zuiderpark",
    name: "Zuiderpark",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  heijplaat: {
    slug: "heijplaat",
    name: "Heijplaat",
    children: [
      "tarwewijk",
      "carnisse",
      "zuidwijk",
      "oud-charlois",
      "wielewaal",
      "zuidplein",
      "pendrecht",
      "zuiderpark",
      "heijplaat"
    ],
    parent: "gebied-charlois"
  },
  "gebied-hoogvliet": {
    slug: "gebied-hoogvliet",
    name: "Gebied: Hoogvliet",
    children: ["hoogvliet-noord", "hoogvliet-zuid"],
    parent: "rotterdam"
  },
  "hoogvliet-noord": {
    slug: "hoogvliet-noord",
    name: "Hoogvliet-Noord",
    children: ["hoogvliet-noord", "hoogvliet-zuid"],
    parent: "gebied-hoogvliet"
  },
  "hoogvliet-zuid": {
    slug: "hoogvliet-zuid",
    name: "Hoogvliet-Zuid",
    children: ["hoogvliet-noord", "hoogvliet-zuid"],
    parent: "gebied-hoogvliet"
  },
  "gebied-hoek-van-holland": {
    slug: "gebied-hoek-van-holland",
    name: "Gebied: Hoek van Holland",
    children: ["strand-en-duin", "dorp"],
    parent: "rotterdam"
  },
  "strand-en-duin": {
    slug: "strand-en-duin",
    name: "Strand en Duin",
    children: ["strand-en-duin", "dorp"],
    parent: "gebied-hoek-van-holland"
  },
  dorp: {
    slug: "dorp",
    name: "Dorp",
    children: ["strand-en-duin", "dorp"],
    parent: "gebied-hoek-van-holland"
  },
  "gebied-rozenburg": {
    slug: "gebied-rozenburg",
    name: "Gebied: Rozenburg",
    children: ["rozenburg"],
    parent: "rotterdam"
  },
  rozenburg: {
    slug: "rozenburg",
    name: "Rozenburg",
    children: ["rozenburg"],
    parent: "gebied-rozenburg"
  },
  "gebied-overig-haven-industrie": {
    slug: "gebied-overig-haven-industrie",
    name: "Gebied: Overig (haven/industrie)",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    showInDropdown: false,
    parent: "rotterdam"
  },
  rijnpoort: {
    slug: "rijnpoort",
    name: "Rijnpoort",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    hideInDropdown: true,
    parent: "gebied-overig-haven-industrie"
  },
  botlek: {
    slug: "botlek",
    name: "Botlek",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    hideInDropdown: true,
    parent: "gebied-overig-haven-industrie"
  },
  europoort: {
    slug: "europoort",
    name: "Europoort",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    hideInDropdown: true,
    parent: "gebied-overig-haven-industrie"
  },
  "spaanse-polder": {
    slug: "spaanse-polder",
    name: "Spaanse Polder",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    hideInDropdown: true,
    parent: "gebied-overig-haven-industrie"
  },
  "bedrijvenpark-rdam-noord-west": {
    slug: "bedrijvenpark-rdam-noord-west",
    name: "Bedrijvenpark Rdam Noord-West",
    children: [
      "rijnpoort",
      "botlek",
      "europoort",
      "spaanse-polder",
      "bedrijvenpark-rdam-noord-west"
    ],
    hideInDropdown: true,
    parent: "gebied-overig-haven-industrie"
  }
};

export default districts;
