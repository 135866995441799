import React from "react";
import { WieBezitOnzeStadComponentProps } from "~src/@types/types";
import districts from "~data/districts";
import { Link, navigateTo } from "gatsby";
import css from "@emotion/css";
import { colors, mq, LargeScreen, SmallScreen } from "~style";
import styled from "@emotion/styled";
import IosArrowBack from "react-ionicons/lib/IosArrowBack";
import { Box, Flex } from "@rebass/grid/emotion";
import { Select } from "~components";
import { values } from "lodash";

const Section = styled(Flex)`
  font-weight: 700;
  ${mq("lg")} {
    display: block;
    padding: 8px 48px;
  }
`;

export const MapInfo = (
  props: WieBezitOnzeStadComponentProps & { cssProps: any }
) => {
  const { district, layer, fromYear, toYear, cssProps } = props;
  const selectOptions = values(districts)
    .filter(district => !district.hideInDropdown)
    .map(district => ({
      value: district.slug,
      label: district.name
    }));
  return (
    <Flex
      flexDirection={["column", "column"]}
      css={css`
        ${mq("lg")} {
          display: block;
        }
        ${cssProps}
      `}
    >
      <Flex
        flexDirection={["row", "column"]}
        css={css`
          ${mq("lg")} {
            background: ${colors.white};
            display: inline-block;
          }
        `}
      >
        {district && districts[district] && districts[district].parent && (
          <Section
            justifyContent="center"
            alignItems="center"
            css={css`
              display: none;
              ${mq("lg")} {
                display: block;
                background: ${colors.yellow};
                color: ${colors.black};
                height: 26px;
                width: 26px;
                background: ${colors.white};
                position: relative;
                width: auto;
                height: auto;
              }
            `}
          >
            <IosArrowBack
              css={css`
                ${mq("lg")} {
                  position: absolute;
                  left: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  z-index: 2;
                }
              `}
              onClick={() =>
                navigateTo(`/app/rotterdam/${layer}/${fromYear}/${toYear}`)
              }
            />
            <Link
              css={css`
                color: ${colors.black};
                display: none;
                ${mq("lg")} {
                  display: inline;
                }
              `}
              to={`/app/rotterdam/${layer}/${fromYear}/${toYear}`}
            >
              Overzicht
            </Link>
          </Section>
        )}
        <LargeScreen>
          <Section
            justifyContent="center"
            alignItems="center"
            css={css`
              background: ${colors.blue1};
              display: none;
              ${mq("lg")} {
                display: block;
              }
            `}
          >
            Getoond jaar in kaart:
          </Section>
        </LargeScreen>
        <Section
          flex={1}
          alignItems="center"
          justifyContent={["flex-start", "center"]}
          css={css`
            background: ${colors.yellow};
            color: ${colors.blue1};
            font-size: 1rem;
            padding: 0;
            ${mq("lg")} {
              font-size: 2.8rem;
              padding: 0 48px;
            }
          `}
        >
          <SmallScreen
            css={css`
              text-align: center;
              font-size: 0.8rem;
              background: ${colors.blue1};
              color: ${colors.white};
              padding: 4px 8px;
              height: 100%;
              display: flex;
              flex: 1;
              justify-content: flex-end;
              align-items: center;
            `}
          >
            Getoond jaar in kaart:
          </SmallScreen>
          <Flex
            flex={1}
            alignItems="center"
            css={css`
              padding: 4px;
              font-size: 0.8rem;
              ${mq("lg")} {
                font-size: 3rem;
              }
            `}
          >
            {toYear}
          </Flex>
        </Section>
      </Flex>
      <Section
        flexDirection={["column", "row"]}
        css={css`
          background: ${colors.lightgrey};
          color: ${colors.black};
          font-size: 1rem;
          flex: 1;
          justify-content: flex-start;
          padding: 8px;
          ${mq("lg")} {
            background: ${colors.white};
            justify-content: center;
            font-size: 2.8rem;
          }
        `}
      >
        <LargeScreen>
          {district && districts[district].name.replace("Gebied: ", "")}
        </LargeScreen>
        <SmallScreen
          css={css`
            width: 100%;
          `}
        >
          <Box width={1}>
            <Select
              color={colors.black}
              css={css`
                color: ${colors.black};
              `}
              value={selectOptions.find(option => option.value === district)}
              options={selectOptions}
              onChange={({ value }: any) =>
                district &&
                navigateTo(`/app/${value}/${layer}/${fromYear}/${toYear}`)
              }
            />
          </Box>
        </SmallScreen>
      </Section>
    </Flex>
  );
};
