import css from "@emotion/css";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { head } from "lodash";
import React from "react";
import districts from "~data/districts";
import {
  DataKey,
  DataNodeKey,
  WieBezitOnzeStadComponentProps
} from "~src/@types/types";
import { colors, mq } from "~src/style";
import Bricks from "./bricks";
import { YearLabel } from "./year-label";
import { PriceIllustration } from "./illustrations";
import WozIllustration from "./illustrations/woz";

const Container = styled.div`
  background: ${colors.lightgrey};
`;

const DataBlock = (props: any) => (
  <Flex
    justifyContent="center"
    alignContent="center"
    css={css`
      color: ${colors.black};
      ${props.css}

      padding: 32px 16px;

      ${mq("lg")} {
        padding: 32px 48px;
      }
    `}
    flexWrap={["wrap", "nowrap"]}
    {...props}
  />
);

const DataBlockInner = (props: any) => (
  <Flex
    alignItems="center"
    css={css`
      max-width: 480px;
    `}
    width={[1, "auto"]}
    {...props}
  />
);

const Label = styled.div`
  font-family: proxima-nova-condensed, sans-serif;
  font-style: italic;
  line-height: 1.5;
  font-size: 1.5rem;
  margin: 0 auto 16px;
  ${mq("lg")} {
    line-height: 1.5;
    font-size: 2rem;
    margin: 0 0;
  }
`;

const Value = styled.span`
  line-height: 1.7;
  font-weight: bold;
  font-family: proxima-nova, sans-serif;
  font-size: 2rem;
  ${mq("lg")} {
    font-size: 3.3rem;
  }
`;

const Source = styled.div`
  color: ${colors.semiTransGrey};
  font-family: proxima-nova, sans-serif;
  font-size: 0.7rem;
  margin-bottom: 0.3rem;
  font-weight: bold;
`;

type OwnerData = { from: string; to: string; name: string };

const Owner = ({
  owner,
  fromYear,
  toYear,
  image
}: {
  owner: OwnerData;
  fromYear: string;
  toYear: string;
  image: string;
}) => (
  <Flex
    css={css`
      padding: 8px;
      position: relative;
    `}
    mb={[32, 0]}
    justifyContent="center"
    width={[1, 1 / 4]}
  >
    <Flex
      width={1}
      css={css`
        padding: 16px 8px;
        /* background: ${colors.white}; */
      `}
      flexDirection="column"
    >
      <Flex
        css={css`
          margin-bottom: 16px;
        `}
        alignItems="flex-end"
        justifyContent={["center", "flex-start"]}
      >
        <Flex width={[100, 100]} justifyContent="center" alignItems="flex-end">
          <div
            css={css`
              transition: height 1s;
              height: ${owner ? getBrickHeightFor(owner.from) : 0};
              overflow: hidden;
              width: 2rem;
              position: relative;
              left: 1rem;
              z-index: 0;
              border-bottom: 2px solid ${colors.blue2};
              ${mq("lg")} {
                height: ${owner ? getBrickHeightFor(owner.from, false) : 0};
              }
            `}
          >
            <Bricks fill={colors.blue2} />
          </div>
          <div
            css={css`
              transition: height 1s;
              overflow: hidden;
              width: 2rem;
              z-index: 1;
              border-bottom: 2px solid ${colors.black};
              height: ${owner ? getBrickHeightFor(owner.to) : 0};
              ${mq("lg")} {
                height: ${owner ? getBrickHeightFor(owner.to, false) : 0};
              }
            `}
          >
            <Bricks fill={colors.black} />
          </div>
        </Flex>
        <Flex
          css={css``}
          flex={["none", 1]}
          // alignItems={["flex-end", "center"]}
          justifyContent="center"
        >
          <img
            css={css`
              max-width: 100%;
              height: 220px;
              ${mq("lg")} {
                height: auto;
              }
            `}
            src={image}
          />
        </Flex>
      </Flex>
      <Flex flexDirection={["column", "row"]}>
        <Flex
          css={css`
            font-size: 0.7rem;
            font-weight: bold;
          `}
          width={[1, 100]}
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            width={1}
            css={css`
              background: ${colors.blue2};
              color: ${colors.white};
              padding: 4px 8px;
            `}
          >
            <div>
              {owner && typeof owner.from === "undefined"
                ? "onbekend"
                : `${owner.from}`}
            </div>
            <div>{fromYear}</div>
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            width={1}
            css={css`
              background: ${colors.black};
              color: ${colors.white};
              padding: 4px 8px;
              z-index: 1;
            `}
          >
            <div>
              {owner && typeof owner.to === "undefined"
                ? "onbekend"
                : `${owner.to}`}
            </div>
            <div>{toYear}</div>
          </Flex>
        </Flex>
        <Box
          flex={1}
          css={css`
            position: absolute;
            top: 0;
            ${mq("lg")} {
              position: static;
            }
          `}
        >
          <Label
            css={css`
              line-height: 1;
              font-weight: normal;
              font-style: normal;
              text-align: center;
              font-size: 1.2rem;
              padding: 8px;
              margin: -8px;
              background: ${colors.lightgrey}88;
              ${mq("lg")} {
                font-size: 1rem;
                padding: 0 1rem;
                margin: 0;
              }
            `}
          >
            {owner.name}
          </Label>
        </Box>
      </Flex>
    </Flex>
  </Flex>
);

function getBrickHeightFor(percentage: string, isSmallScreen: boolean = true) {
  const intPercentage = parseInt(percentage, 10);
  return isNaN(intPercentage)
    ? 0
    : isSmallScreen
    ? `${0 + (120 * intPercentage) / 100}px`
    : `${0 + (140 * intPercentage) / 100}px`;
}

export const Stats = ({
  district: districtKey,
  fromYear,
  toYear,
  data
}: WieBezitOnzeStadComponentProps) => {
  const district = districts[districtKey!];
  if (!district) {
    return <div />;
  }
  const getDataForLayer = (dataKey: DataKey, name?: string) => {
    const districtLayerSelection = head(
      data![dataKey].edges
        .map(edge => edge.node)
        .filter(node => node.cbsBuurt === district.name)
    );
    const fromKey = `${dataKey}${fromYear}` as DataNodeKey;
    const toKey = `${dataKey}${toYear}` as DataNodeKey;
    const layerTimeSelection = districtLayerSelection
      ? {
          from: districtLayerSelection[fromKey],
          to: districtLayerSelection[toKey],
          name
        }
      : {};
    return layerTimeSelection;
  };
  const totaal = getDataForLayer("totaal");
  const price = getDataForLayer("price");
  const woz = getDataForLayer("woz");
  const gemeente = getDataForLayer("gemeente", "Gemeente") as OwnerData;
  const corporaties = getDataForLayer(
    "corporaties",
    "Corporaties"
  ) as OwnerData;
  const institutioneleBeleggers = getDataForLayer(
    "institutioneleBeleggers",
    "Institutionele beleggers"
  ) as OwnerData;
  const particuliereVerhuur19 = getDataForLayer(
    "particuliereVerhuur19",
    "Particuliere verhuur 1-9 woningen"
  ) as OwnerData;
  const particuliereVerhuur1099 = getDataForLayer(
    "particuliereVerhuur1099",
    "Particuliere verhuur 10-99 woningen"
  ) as OwnerData;
  const particuliereVerhuur100 = getDataForLayer(
    "particuliereVerhuur100",
    "Particuliere verhuur 100+ woningen"
  ) as OwnerData;
  const eigenaarBewoner = getDataForLayer(
    "eigenaarBewoner",
    "Bewoner is eigenaar"
  ) as OwnerData;

  return (
    <Container>
      <Flex
        flexDirection={["column-reverse", "row"]}
        flexWrap={["wrap", "nowrap"]}
        py={[0, 32]}
      >
        <DataBlock
          css={css`
            padding-top: 0;
            ${mq("lg")} {
              /* padding-top: inherit; */
            }
          `}
          justifyContent={["center", "flex-end"]}
          width={[1, 1 / 2]}
        >
          <DataBlockInner width={null}>
            <div
              css={css`
                text-align: right;
              `}
            >
              {totaal && totaal.to && totaal.to < 100000 ? (
                <img height={80} src={require("../assets/total-1.svg")} />
              ) : (
                <img height={80} src={require("../assets/total-2.svg")} />
              )}
              <Value
                css={css`
                  display: block;
                  margin: 0;
                  line-height: 1;
                `}
              >
                <span
                  css={css`
                    display: inline-block;
                    margin: 0 4px 0 32px;
                  `}
                >
                  {totaal && totaal.to
                    ? totaal.to.toLocaleString("nl-NL")
                    : "onbekend"}
                </span>
                <img height={40} src={require("../assets/grass.svg")} />
              </Value>
            </div>
          </DataBlockInner>
        </DataBlock>
        <DataBlock
          justifyContent={["center", "flex-start"]}
          width={[1, 1 / 2]}
          css={css`
            padding-bottom: 16;
            ${mq("lg")} {
              /* padding-bottom: inherit; */
            }
          `}
        >
          <DataBlockInner width={null}>
            <div css={css``}>
              <Source>Bron: gemeente Rotterdam-OBI</Source>
              <Label>
                <div
                  css={css`
                    margin-left: 12px;
                  `}
                >
                  Aantal woningen in
                </div>
                <div>
                  <span
                    css={css`
                      margin-right: 4px;
                      background: ${colors.black};
                      color: ${colors.white};
                      padding: 2px 8px;

                      ${mq("lg")} {
                        padding: 4px 12px;
                      }
                    `}
                  >
                    {district.name.replace("Gebied: ", "")}
                  </span>{" "}
                  in{" "}
                  <span
                    css={css`
                      margin-left: 4px;
                      background: ${colors.yellow};
                      padding: 2px 8px;

                      ${mq("lg")} {
                        padding: 4px 12px;
                      }
                    `}
                  >
                    {toYear}
                  </span>
                </div>
              </Label>
            </div>
          </DataBlockInner>
        </DataBlock>
      </Flex>

      <Flex flexDirection="row" flexWrap={["wrap", "nowrap"]}>
        <DataBlock
          css={css`
            background: ${colors.black};
            color: ${colors.white};
          `}
          width={[1, 1 / 2]}
        >
          <DataBlockInner width="auto">
            <Box>
              <Label>
                Gemiddelde{" "}
                <span
                  css={css`
                    border-bottom: 2px solid ${colors.white};
                    padding: 0 4px;
                    ${mq("lg")} {
                      margin-left: -4px;
                    }
                  `}
                >
                  verkoopprijs
                </span>
              </Label>
              <Flex justifyContent="space-between" alignItems="center">
                <Value>
                  {price && price.from && price.from !== "Onbekend"
                    ? `€${price.from.toLocaleString("nl-NL")}`
                    : "onbekend"}
                </Value>
                <YearLabel
                  css={css`
                    border-color: ${colors.white};

                    ${mq("lg")} {
                      margin: 0 48px;
                    }
                  `}
                >
                  {fromYear}
                </YearLabel>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Value>
                  {price && price.to && price.to !== "Onbekend"
                    ? `€${price.to.toLocaleString("nl-NL")}`
                    : "onbekend"}
                </Value>
                <YearLabel
                  css={css`
                    border-color: ${colors.white};
                    background: ${colors.white};
                    color: ${colors.black};

                    ${mq("lg")} {
                      margin: 0 48px;
                    }
                  `}
                >
                  {toYear}
                </YearLabel>
              </Flex>
              <Source>Bron: NVM; bewerking gemeente Rotterdam-OBI</Source>
            </Box>
          </DataBlockInner>
        </DataBlock>
        <DataBlock
          css={css`
            background: ${colors.yellow};
          `}
          width={[1, 1 / 2]}
        >
          <DataBlockInner justifyContent={["center", "flex-start"]}>
            <PriceIllustration
              year={`'${fromYear!.substr(2)}/'${toYear!.substr(2)}`}
              percentage={
                price &&
                price.from &&
                price.to &&
                typeof price.from === "number" &&
                typeof price.to === "number"
                  ? Math.floor(((price.to - price.from) / price.from) * 100) +
                    "%"
                  : "?"
              }
              height={250}
            />
          </DataBlockInner>
        </DataBlock>
      </Flex>
      <Flex
        flexDirection={["column-reverse", "row"]}
        flexWrap={["wrap", "nowrap"]}
      >
        <DataBlock
          css={css`
            background: ${colors.white};
          `}
          width={[1, 1 / 2]}
        >
          <DataBlockInner justifyContent={["center", "flex-start"]}>
            <WozIllustration
              year={`'${fromYear!.substr(2)}/'${toYear!.substr(2)}`}
              percentage={
                woz &&
                woz.from &&
                woz.to &&
                typeof woz.from === "number" &&
                typeof woz.to === "number"
                  ? Math.floor(((woz.to - woz.from) / woz.from) * 100) + "%"
                  : "?"
              }
              height={250}
            />
          </DataBlockInner>
        </DataBlock>
        <DataBlock
          css={css`
            background: ${colors.blue1};
            color: ${colors.white};
          `}
          width={[1, 1 / 2]}
        >
          <DataBlockInner width="auto">
            <div>
              <Label>
                Gemiddelde{" "}
                <span
                  css={css`
                    border-bottom: 2px solid ${colors.white};
                    padding: 0 4px;
                    margin-left: -4px;
                  `}
                >
                  WOZ-waarde
                </span>
              </Label>
              <Flex justifyContent="space-between" alignItems="center">
                <Value>
                  {woz && woz.from && woz.from !== "Onbekend"
                    ? `€${woz.from.toLocaleString("nl-NL")}`
                    : "onbekend"}
                </Value>
                <YearLabel
                  css={css`
                    border-color: ${colors.white};
                    margin: 0 48px;
                  `}
                >
                  {fromYear}
                </YearLabel>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Value>
                  {woz && woz.to && woz.to !== "Onbekend"
                    ? `€${woz.to.toLocaleString("nl-NL")}`
                    : "onbekend"}
                </Value>
                <YearLabel
                  css={css`
                    border-color: ${colors.white};
                    background: ${colors.white};
                    color: ${colors.black};
                    margin: 0 48px;
                  `}
                >
                  {toYear}
                </YearLabel>
              </Flex>
              <Source>Bron: CBS</Source>
            </div>
          </DataBlockInner>
        </DataBlock>
      </Flex>
      <Flex flexDirection="row" flexWrap={["wrap", "nowrap"]}>
        <DataBlock
          flexDirection="column"
          alignItems="center"
          css={css`
            background: ${colors.lightgrey};
          `}
          width={1}
        >
          <Label>Actieve partijen</Label>
          <Flex justifyContent="center" width={1} flexWrap={["wrap", "nowrap"]}>
            <Owner
              owner={gemeente}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/gemeente.svg")}
            />
            <Owner
              owner={corporaties}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/corporaties.svg")}
            />
            <Owner
              owner={institutioneleBeleggers}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/institutionele-beleggers.svg")}
            />
            <Owner
              owner={particuliereVerhuur19}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/particuliere-verhuur-1-9.svg")}
            />
          </Flex>
          <Flex justifyContent="center" width={1} flexWrap={["wrap", "nowrap"]}>
            <Owner
              owner={particuliereVerhuur1099}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/particuliere-verhuur-10-99.svg")}
            />
            <Owner
              owner={particuliereVerhuur100}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/particuliere-verhuur-100.svg")}
            />
            <Owner
              owner={eigenaarBewoner}
              fromYear={fromYear as string}
              toYear={toYear as string}
              image={require("../assets/eigenaar-bewoner.svg")}
            />
            <Flex
              justifyContent="flex-end"
              alignItems="flex-end"
              width={[1, 1 / 4]}
            >
              <Source>Bron: gemeente Rotterdam-OBI</Source>
            </Flex>
          </Flex>
        </DataBlock>
      </Flex>
    </Container>
  );
};
