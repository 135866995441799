import React from "react";
import { Box, Flex } from "@rebass/grid/emotion";
import styled from "@emotion/styled";
import css from "@emotion/css";
import { colors, mq } from "~src/style";
import { Link } from "gatsby";
import { strings } from "~src/data";

const Container = styled(Flex)`
  background: ${colors.white};
  color: ${colors.black};
  padding: 45px 16px;
`;

const Label = styled.div`
  font-family: proxima-nova-condensed, sans-serif;
  font-style: italic;
  line-height: 1.5;
  font-size: 2rem;
  max-width: 480px;
  text-align: center;
`;

const Article = (props: any) => (
  <Box width={[1, 300]} mx={[0, 32]} my={[32, 0]}>
    <a
      css={css`
        text-decoration: none;
        color: inherit;
        display: block;
      `}
      href={props.url}
      target="_blank"
    >
      <Flex flexDirection="column">
        <Box
          css={css`
            height: 200px;
            background-image: url(${props.image
              ? props.image
              : `https://s3-eu-west-1.amazonaws.com/versbeton/app/uploads/2019/03/19110807/investeren-in-de-woningmarkt1-560x315.jpg`});
            background-size: cover;
            background-position: center;
          `}
        />
        <Box bg={colors.blue5} px="8px" py="16px" m="0px">
          <Label
            css={css`
              margin: 0;
              font-size: 1rem;
              text-align: left;
              line-height: 1.2;
            `}
          >
            {props.children}
          </Label>
          <Box
            css={css`
              text-transform: uppercase;
              color: ${colors.blue2};
              font-weight: bold;
              margin-top: 16px;
              font-size: 0.8rem;
            `}
          >
            Lees meer
          </Box>
        </Box>
      </Flex>
    </a>
  </Box>
);

export const Articles = (props: any) => (
  <Container css={css``} flexDirection="column" alignItems="center">
    <Box>
      <Label>{strings.articlesTitle}</Label>
    </Box>
    <Flex
      css={css`
        margin: 32px 0 56px;
        flex-wrap: wrap;
      `}
    >
      {strings.articles.map((article, i) => (
        <Article key={`article-${i}`} url={article.url} image={article.image}>
          {article.title}
        </Article>
      ))}
    </Flex>
    <Box>
      <a
        css={css`
          background: ${colors.blue2};
          color: ${colors.white};
          font-weight: bold;
          text-transform: uppercase;
          padding: 8px 24px;
          text-decoration: none;
        `}
        href={strings.articlesButtonUrl}
        target="_blank"
      >
        {strings.articlesButtonText}
      </a>
    </Box>
  </Container>
);
