export const colors = {
  blue1: "#020a43",
  blue2: "#0720df",
  blue3: "#111c5b",
  blue4: "#444973",
  blue5: "#e7f2fc",
  black: "#000",
  white: "#fff",
  yellow: "#fff103",
  red: "#ff0000",
  semiTransGrey: "#8888",
  darkGrey: "#bebebe",
  grey: "#ccc",
  lightgrey: "#f7f7f7"
};
