import styled from "@emotion/styled";
import { head, debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import asyncLoading from "react-async-loader";
import districts from "~data/districts";
import { WieBezitOnzeStadComponentProps } from "~src/@types/types";
import css from "@emotion/css";
import { Flex } from "@rebass/grid/emotion";
import { slugify } from "~src/utils";
import { streetviewLocations } from "~src/data";

const Container = styled.div`
  height: 90vh;
  position: relative;
`;

type ReactStreetviewProps = {
  apiKey: string;
  streetViewPanoramaOptions: {
    position: { lat: number; lng: number };
    pov: { heading: number; pitch: number };
    zoom: number;
  };
  googleMaps: any;
};

const ReactStreetviewBase = ({
  streetViewPanoramaOptions,
  googleMaps,
  onClick
}: ReactStreetviewProps & { onClick?: () => void }) => {
  const canvas = useRef(null);
  useEffect(() => {
    // console.log("yuh");
    initialize();
    return () => {
      if (streetView) {
        googleMaps.event.clearInstanceListeners(streetView);
      }
    };
  }, [streetViewPanoramaOptions, googleMaps]);
  const [streetView, setStreetView] = useState();

  function initialize() {
    if (googleMaps) {
      setStreetView(
        new googleMaps.StreetViewPanorama(
          canvas.current,
          streetViewPanoramaOptions
        )
      );
    }
  }

  return (
    <div
      onClick={() => onClick && onClick}
      style={{
        height: "100%"
      }}
      ref={canvas}
    />
  );
};

function mapScriptsToProps({ apiKey }: ReactStreetviewProps) {
  return {
    googleMaps: {
      globalPath: "google.maps",
      url: `https://maps.googleapis.com/maps/api/js?key=${apiKey}`,
      jsonp: true
    }
  };
}

const ReactStreetView = asyncLoading(mapScriptsToProps)(ReactStreetviewBase);

export const StreetView = ({
  district: districtKey,
  data
}: WieBezitOnzeStadComponentProps) => {
  const district = districts[districtKey];
  if (!district) {
    return <Container />;
  }
  // console.log(
  //   JSON.stringify(
  //     data!.streetview.edges.reduce((acc, item) => {
  //       console.log("ja hoor", item);
  //       const regex = item.node.url.match(
  //         /^.*@(?<lat>\d+\.\d+),(?<lng>\d+\.\d+).*,(?<heading>\d+.\d+)h/
  //       );
  //       if (regex) {
  //         acc[slugify(item.node.cbsBuurt)] = regex.groups;
  //       }
  //       return acc;
  //     }, {})
  //   )
  // );
  const selectedLocation = head(
    data!.streetview.edges.filter(edge => edge.node.cbsBuurt === district.name)
  );
  const [showOverlay, setShowOverlay] = useState(true);
  const resetShowOverlay = debounce(() => {
    setShowOverlay(true);
  }, 15000);
  return (
    <Container>
      {showOverlay && (
        <Flex
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            /* pointer-events: none; */
            z-index: 999;
            background: #00f2;
            color: white;
            font-family: Proxima-nova-condensed;
            font-size: 2rem;
            font-style: italic;
            cursor: pointer;
            text-align: center;
          `}
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            setShowOverlay(false);
            resetShowOverlay();
          }}
        >
          Klik om de wijk te bekijken
        </Flex>
      )}
      <iframe
        src={
          selectedLocation
            ? selectedLocation!
                .node!.url.replace('<iframe src="', "")
                .replace(
                  '" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>',
                  ""
                )
            : ""
        }
        width="100%"
        height="100%"
        onClick={() => {
          console.log("yo");
          debounce(() => setShowOverlay(true), 1000);
        }}
        style={{ border: 0 }}
        allowFullScreen
      />
    </Container>
  );
};
