import styled from "@emotion/styled";

export const breakpoints = ["64em", "70em", "80em"];

function getBreakpointForSize(size: string) {
  switch (size) {
    case "sm":
      return breakpoints[0];
    case "md":
      return breakpoints[0];
    case "lg":
    default:
      return breakpoints[0];
  }
}

export const mq = (size: string) =>
  `@media (min-width: ${getBreakpointForSize(size)})`;

export const SmallScreen = styled.div`
  ${mq("lg")} {
    display: none;
  }
`;

export const LargeScreen = styled.div`
  display: none;
  ${mq("lg")} {
    display: block;
  }
`;
