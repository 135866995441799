import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Flex } from "@rebass/grid/emotion";
import { navigate, navigateTo } from "gatsby";
import { TimelineLite } from "gsap";
import { camelCase, head } from "lodash";
import React, { useEffect, useState, useRef } from "react";
import { transformTargets } from "~data";
import districts from "~data/districts";
import {
  DataKey,
  DataNodeKey,
  DistrictKey,
  SelectedData,
  WieBezitOnzeStadComponentProps
} from "~src/@types/types";
import { colors, mq } from "~src/style";
import { slugify } from "~utils";
import { Rotterdam } from "./maps";
import isTouchDevice from "is-touch-device";
import { ZoomControls } from "./zoom-controls";

const disableBodyScroll = () => {
  document.body.ontouchmove = e => {
    e.preventDefault;
    return false;
  };
};
const enableBodyScroll = () => {
  document.body.ontouchmove = e => {};
};

const SVGContainer = styled.div`
  position: relative;
  flex: 1;
  .district, .district-text {
    cursor: pointer;
    /*fill: ${colors.white};*/
  }
  .district:hover path,
  .district:hover polygon,
  .district:hover polyline,
  .district.active path,
  .district.active polygon,
  .district.active polyline {
    fill: ${colors.yellow} !important;
  }
  text {
    font-family: proxima-nova-condensed, sans-serif;
    font-style: italic;
    text-anchor: middle;
    font-weight: bold;
  }
  .name {
    font-style: normal;
    font-weight: bold;
  }
  svg {
    width: 100%;
  }
  ${mq("lg")} {
    height: 100%;
    svg {
      width: auto;
      height: 100%;
      margin: 0 auto;
      display: block;
    }
  }
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;

  ${mq("lg")} {
    height: 100%;
  }
`;

export const Map = (
  props: WieBezitOnzeStadComponentProps & {
    showLabels?: boolean;
    style?: any;
  }
) => {
  const {
    district: districtKey,
    layer,
    fromYear,
    toYear,
    data,
    showLabels = true
  } = props;
  const district = districts[districtKey!];
  if (!district) {
    return <div />;
  }
  const camelCasedLayer = camelCase(layer) as DataKey;
  const selectedData: SelectedData = district.children.reduce((acc, val) => {
    const subDistrict = districts[val];
    const districtLayerSelection = head(
      data![camelCasedLayer].edges
        .map(edge => edge.node)
        .filter(node => node.cbsBuurt === subDistrict.name)
    );
    const timeKeys = {
      from: `${camelCasedLayer}${fromYear}` as DataNodeKey,
      to: `${camelCasedLayer}${toYear}` as DataNodeKey
    };
    const districtLayerTimeSelection = {
      from: districtLayerSelection && districtLayerSelection[timeKeys.from],
      to: districtLayerSelection && districtLayerSelection[timeKeys.to]
    };
    return {
      [val]: {
        from:
          districtLayerTimeSelection.from === null ||
          (typeof districtLayerTimeSelection.from === "undefined" ||
            districtLayerTimeSelection.from === "Onbekend")
            ? "Onbekend"
            : `${
                layer === "price" || layer === "woz" ? "€" : ""
              }${districtLayerTimeSelection.from!.toLocaleString("nl-NL")}`,
        to:
          districtLayerTimeSelection.to === null ||
          (typeof districtLayerTimeSelection.to === "undefined" ||
            districtLayerTimeSelection.to === "Onbekend")
            ? "Onbekend"
            : `${
                layer === "price" || layer === "woz" ? "€" : ""
              }${districtLayerTimeSelection.to!.toLocaleString("nl-NL")}`
      },
      ...acc
    };
  }, {});

  const handleDistrictClick = (districtName: string) => {
    navigate(`/app/${slugify(districtName)}/${layer}/${fromYear}/${toYear}`);
  };

  const [previousDistrict, setPreviousDistrict] = useState<string>();
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [animationTarget, setAnimationTarget] = useState({
    scale: 1,
    x: 0,
    y: 0
  });
  const [touchStartPosition, setTouchStartPosition] = useState({ x: 0, y: 0 });
  const [savedPosition, setSavedPosition] = useState({ x: 0, y: 0 });

  const showDistrict = (district: DistrictKey, tl: TimelineLite) => {
    const { x, y, scale } = transformTargets[district]!;
    setAnimationTarget({ x, y, scale });
    setScale(scale);
    setPosition({ x, y });
    setSavedPosition({ x, y });
    return tl
      .set(
        `.layer.${district}`,
        {
          display: "block"
        },
        0
      )
      .set(
        ".rotterdam-text",
        {
          display: "none"
        },
        0
      )
      .to(
        `.layer.${district}`,
        1,
        {
          opacity: 1
        },
        0
      );
  };

  const showRotterdam = (tl: TimelineLite) => {
    setAnimationTarget({ scale: 1, x: 0, y: 0 });
    setScale(1);
    setPosition({ x: 0, y: 0 });
    setSavedPosition({ x: 0, y: 0 });
    return tl
      .set(
        `.gebied, .gebied *`,
        {
          fill: colors.white
        },
        0
      )
      .set(
        ".rotterdam-text",
        {
          display: "block"
        },
        0
      );
  };
  useEffect(() => {
    let tl = new TimelineLite()
      .set(
        `.shape`,
        {
          stroke: "none",
          fill: colors.white
        },
        0
      )
      .set(
        `.gebied, .gebied *`,
        {
          fill: colors.lightgrey
        },
        0
      )
      .set(
        `.layer > .gebied`,
        {
          stroke: "none",
          fill: colors.blue2
        },
        0
      )
      .set(
        `.shape.${districtKey}`,
        {
          stroke: colors.blue1,
          fill:
            districtKey.indexOf("gebied-") === 0 ? colors.blue1 : colors.yellow
        },
        0
      );
    if (districtKey.indexOf("gebied-") === 0) {
      tl = showDistrict(districtKey, tl);
      setPreviousDistrict(districtKey);
      if (
        previousDistrict !== "rotterdam" &&
        previousDistrict !== districtKey
      ) {
        tl = tl
          .to(`.layer.${previousDistrict}`, 1, { opacity: 0 }, 0)
          .set(`.layer.${previousDistrict}`, { display: "none" }, 0);
      }
    } else if (districtKey === "rotterdam") {
      tl = showRotterdam(tl);
      setPreviousDistrict(districtKey);
      if (
        previousDistrict !== "rotterdam" &&
        previousDistrict !== districtKey
      ) {
        tl = tl
          .to(`.layer.${previousDistrict}`, 1, { opacity: 0 }, 0)
          .set(`.layer.${previousDistrict}`, { display: "none" }, 0);
      }
    } else {
      tl = showDistrict(districts[districtKey]!.parent!, tl);
      setPreviousDistrict(districts[districtKey]!.parent!);
      if (
        previousDistrict !== "rotterdam" &&
        previousDistrict !== districts[districtKey].parent
      ) {
        tl = tl
          .to(`.layer.${previousDistrict}`, 1, { opacity: 0 }, 0)
          .set(`.layer.${previousDistrict}`, { display: "none" }, 0);
      }
    }
    tl.play();
  }, [districtKey]);
  useEffect(() => {
    new TimelineLite().to(
      ".transform-container",
      0.5,
      {
        scale: animationTarget.scale,
        x: animationTarget.x,
        y: animationTarget.y,
        transformOrigin: "center center",
        onComplete() {
          setScale(animationTarget.scale);
          setPosition({ x: animationTarget.x, y: animationTarget.y });
          setSavedPosition({ x: animationTarget.x, y: animationTarget.y });
        }
      },
      0
    );
  }, [animationTarget]);

  const getValueForDistrict = (districtName: string) => {
    return selectedData[slugify(districtName)]
      ? selectedData[slugify(districtName)].to
      : "onbekend";
  };
  const getDifferenceForDistrict = (districtName: string) => {
    if (
      selectedData[slugify(districtName)] &&
      selectedData[slugify(districtName)].from &&
      selectedData[slugify(districtName)].to &&
      selectedData[slugify(districtName)].from !== "Onbekend" &&
      selectedData[slugify(districtName)].to !== "Onbekend"
    ) {
      // Math.floor(((price.to - price.from) / price.from) * 100)
      const { from, to } = {
        from: parseFloat(
          selectedData[slugify(districtName)].from.replace("€", ""),
          10
        ),
        to: parseFloat(
          selectedData[slugify(districtName)].to.replace("€", ""),
          10
        )
      };
      const diff = Math.round(((to - from) / from) * 1000) / 10;
      console.log(JSON.stringify({ districtName, from, to, diff }));
      return isNaN(diff) || diff === Infinity ? "n.v.t" : diff + "%";
    }
    return "n.v.t";
  };
  const [activeDistrict, setActiveDistrict] = useState("");
  const handleMove = ({ x, y }: { x: number; y: number }) => {
    setPosition({
      x: savedPosition.x + (x - touchStartPosition.x),
      y: savedPosition.y + (y - touchStartPosition.y)
    });

    new TimelineLite().set(".transform-container", {
      x: position.x,
      y: position.y,
      transformOrigin: "center center"
    });
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    handleMove({
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
    });
    e.preventDefault();
  };
  const [touched, setTouched] = useState(false);
  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchStartPosition({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    setTouched(true);
  };
  const handleTouchEnd = () => {
    setSavedPosition(position);
    setTouched(false);
  };
  const handleMouseEnd = () => {
    setSavedPosition(position);
    setTouched(false);
  };
  const handleMouseStart = (e: any) => {
    setTouchStartPosition({ x: e.clientX, y: e.clientY });
    setTouched(true);
  };
  const handleMouseMove = (e: any) => {
    if (touched) {
      handleMove({
        x: e.clientX,
        y: e.clientY
      });
      e.preventDefault();
    }
  };
  useEffect(() => {
    new TimelineLite()
      .set(".transform-container", { transformOrigin: "center center" }, 0)
      .play();
  }, []);

  return (
    <Container style={props.style}>
      <Flex
        alignItems="flex-end"
        justifyContent="center"
        css={css`
          .district-text {
            display: ${showLabels ? "block" : "none"};
          }
          ${mq("lg")} {
            height: 100%;
          }
        `}
        onTouchStart={() => disableBodyScroll()}
        onTouchEnd={() => enableBodyScroll()}
      >
        <SVGContainer
          css={css`
            touch-action: none;
            cursor: grab;
          `}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseStart}
          onMouseUp={handleMouseEnd}
          onMouseMove={handleMouseMove}
        >
          <Rotterdam
            district={districtKey!}
            selectedData={selectedData}
            onDistrictClicked={handleDistrictClick}
            layer={layer!}
            fromYear={fromYear!}
            toYear={toYear!}
            activeDistrict={activeDistrict}
            setActiveDistrict={setActiveDistrict}
            getValueForDistrict={getValueForDistrict}
            getDifferenceForDistrict={getDifferenceForDistrict}
            scale={scale}
            position={position}
          />
          <ZoomControls
            onZoomIn={() => {
              setAnimationTarget({
                x: position.x,
                y: position.y,
                scale: scale + 1
              });
            }}
            onZoomOut={() => {
              setAnimationTarget({
                x: position.x,
                y: position.y,
                scale: Math.max(1, scale - 1)
              });
            }}
            showRoffa={district && !!district.parent}
            onShowRoffa={() =>
              navigateTo(`/app/rotterdam/${layer}/${fromYear}/${toYear}`)
            }
          />
        </SVGContainer>
      </Flex>
    </Container>
  );
};

export default Map;
