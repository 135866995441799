export const layers = [
  { key: "price", title: "Gemiddelde verkoopprijs" },
  { key: "woz", title: "Gemiddelde WOZ waarde" },
  { key: "gemeente", title: "Eigendom van gemeente" },
  { key: "corporaties", title: "Eigendom van corporaties" },
  {
    key: "institutionele-beleggers",
    title: "Eigendom van institutionele beleggers"
  },
  {
    key: "particuliere-verhuur-1-9",
    title: "Particuliere verhuur 1-9 woningen"
  },
  {
    key: "particuliere-verhuur-10-99",
    title: "Particuliere verhuur 10-99 woningen"
  },
  {
    key: "particuliere-verhuur-100",
    title: "Particuliere verhuur 100+ woningen"
  },
  { key: "eigenaar-bewoner", title: "Eigendom van bewoner" }
];
