import React from "react";
import { css } from "@emotion/core";
import { colors } from "~src/style";
import { Box } from "@rebass/grid/emotion";

export const Container = (props: any) => (
  <Box
    css={css`
      background: ${colors.blue2};
      color: ${colors.white};
      max-width: 2048px;
      margin: 0 auto;
      font-family: proxima-nova, sans-serif;
      overflow-x: hidden;
      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: proxima-nova-condensed, sans-serif;
        font-style: italic;
      }
    `}
    {...props}
  />
);
