import React from "react";
import { Flex, Box } from "@rebass/grid/emotion";
import css from "@emotion/css";
import { colors, mq } from "~src/style";
import MdClose from "react-ionicons/lib/MdClose";

export const Overlay = (props: any & { onClose?: () => void }) => {
  return (
    <>
      <Box
        css={[
          css`
            background: ${colors.black}8;
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 999998;
          `,
          props.shadowStyle
        ]}
        onClick={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      />
      <Flex
        css={[
          css`
            position: fixed;
            top: 8px;
            bottom: 8px;
            left: 8px;
            right: 8px;
            z-index: 999999;
            color: ${colors.white};
            pointer-events: none;
            ${mq("lg")} {
              top: 32px;
              bottom: 32px;
              left: 32px;
              right: 32px;
            }
          `
        ]}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <Box
          css={[
            css`
              position: relative;
              background: ${colors.blue1};
              padding: 48px 16px;
              border: 3px solid ${colors.white};
              max-width: 100%;
              overflow-y: auto;
              max-height: 100%;
              pointer-events: all;
            `,
            props.outerContainerStyle
          ]}
        >
          <Box
            css={css`
              position: absolute;
              right: 0;
              top: 0;
              padding: 8px;
              cursor: pointer;
            `}
            onClick={() => {
              if (props.onClose) {
                props.onClose();
              }
            }}
          >
            <MdClose color={colors.white} fontSize="2rem" />
          </Box>
          <Flex
            css={[
              css`
                padding: 0 16px;
                max-width: 100%;
                ${mq("lg")} {
                  padding: 0 32px;
                }
              `,
              props.scrollContainerStyle
            ]}
            alignSelf="stretch"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              css={[
                css`
                  max-width: 400px;
                  width: 100%;
                `,
                props.innerContainerStyle
              ]}
            >
              {props.children}
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
