import React from "react";
import SelectBase from "react-select";
import css from "@emotion/css";
import { colors } from "~src/style";

export const Select = (props: any) => {
  const { color = colors.white } = props;
  return (
    <SelectBase
      styles={{
        control: (provided: any) => ({
          display: "flex",
          height: "1.5rem",
          marginLeft: "-8px"
        }),
        input: (provided: any) => ({
          display: "none"
        }),
        singleValue: (provided: any) => ({
          ...provided,
          maxWidth: "calc(100% - 8px)",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          boxSizing: "border-box",
          color
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          display: "none"
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          color,
          padding: "8px",
          position: "absolute",
          right: "-4px",
          left: "-4px",
          top: "-4px",
          bottom: "-4px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        })
      }}
      {...props}
    />
  );
};
