/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { ReactNode } from "react";
import Header from "./header";
import styled from "@emotion/styled";
import { colors } from "../style/colors";
import Helmet from "react-helmet";
import { Global, css, SerializedStyles } from "@emotion/core";
import { ThemeProvider } from "emotion-theming";
import { theme } from "~src/style";
import ReactGA from "react-ga";

const Container = styled.div`
  background: ${colors.white};
`;

export const Layout = ({
  children,
  cssProp
}: {
  children: ReactNode;
  cssProp?: SerializedStyles;
}) => {
  if (typeof window !== "undefined") {
    ReactGA.initialize("UA-26911216-3");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <ThemeProvider theme={theme}>
          <Helmet>
            <link
              rel="stylesheet"
              id="tabula/fonts-css"
              href="https://use.typekit.net/kxp4ecc.css"
              type="text/css"
              media="all"
            />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="https://versbeton.nl/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="https://versbeton.nl/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="https://versbeton.nl/favicon-16x16.png"
            />
            <meta
              name="description"
              content="Bekijk 10 jaar Rotterdamse woningmarkt"
            />
            <link rel="canonical" href="https://woningmarkt.versbeton.nl/" />
            <meta property="og:locale" content="nl_NL" />
            <meta property="og:type" content="website" />
            <meta
              property="og:title"
              content="Had ik toen maar een huis gekocht"
            />
            <meta
              property="og:description"
              content="Bekijk 10 jaar Rotterdamse woningmarkt"
            />
            <meta
              property="og:url"
              content="https://woningmarkt.versbeton.nl/"
            />
            <meta property="og:site_name" content="Vers Beton" />
            <meta
              property="og:image"
              content="https://woningmarkt.versbeton.nl/og-image.jpg?v=3"
            />
            <meta
              property="og:image:secure_url"
              content="https://woningmarkt.versbeton.nl/og-image.jpg?v=3"
            />
            <meta property="og:image:width" content="800" />
            <meta property="og:image:height" content="400" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:description"
              content="Bekijk 10 jaar Rotterdamse woningmarkt"
            />
            <meta
              name="twitter:title"
              content="Had ik toen maar een huis gekocht"
            />
            <meta name="twitter:site" content="@versbeton" />
            <meta
              name="twitter:image"
              content="https://woningmarkt.versbeton.nl/og-image.jpg?v=3"
            />
            <meta name="twitter:creator" content="@versbeton" />
          </Helmet>
          <Global
            styles={css`
              body,
              html {
                margin: 0;
                min-height: 100%;
              }
              html {
                background: ${colors.blue2};
              }
              * {
                box-sizing: border-box;
              }
            `}
          />
          <link
            itemprop="thumbnailUrl"
            href="https://woningmarkt.versbeton.nl/og-image.jpg?v=3"
          />
          <span
            itemprop="thumbnail"
            itemscope
            itemtype="http://schema.org/ImageObject"
          >
            <link
              itemprop="url"
              href="https://woningmarkt.versbeton.nl/og-image.jpg?v=3"
            />
          </span>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Container css={cssProp}>
            <main>{children}</main>
          </Container>
        </ThemeProvider>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
