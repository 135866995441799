export const strings = {
  quotesTitle: "Rotterdammers en experts aan het woord",
  articlesTitle: "Lees meer over het onderzoek van journalist Maurice Geluk",
  articles: [
    {
      title: "Opdracht aan de stad: niet lullen maar bouwen",
      url:
        "https://versbeton.nl/2019/06/opdracht-aan-de-stad-niet-lullen-maar-bouwen/",
      image:
        "https://s3-eu-west-1.amazonaws.com/versbeton/app/uploads/2019/06/19114602/MG2371-560x315.jpg"
    },
    {
      title:
        "Corporatie blundert bij woningverkoop aan Feyenoordspeler en vastgoedvriend",
      url:
        "https://versbeton.nl/2019/05/corporatie-blundert-bij-woningverkoop-aan-feyenoord-speler-en-vastgoedvriend/ ",
      image:
        "https://s3-eu-west-1.amazonaws.com/versbeton/app/uploads/2019/05/27215157/voetbal-vastgoed-1120x862.jpg"
    },
    {
      title:
        "Dit zijn Rotterdams grootste particulier huizenbezitters: it’ers, bouwers, een enkele vrouw",
      url:
        "https://versbeton.nl/2019/06/dit-zijn-rotterdams-grootste-particulier-huizenbezitters-iters-bouwers-een-enkele-vrouw/ ",
      image:
        "https://s3-eu-west-1.amazonaws.com/versbeton/app/uploads/2019/06/03182312/10-grootste-huizenbezitters-nina-fernande-1120x1083.jpg"
    }
  ],
  articlesButtonText: "Meer op Vers Beton",
  articlesButtonUrl: "https://versbeton.nl/harde-kern/wie-bezit-onze-stad/",
  aboutHTML: `
    <p>
    Deze interactieve kaart is gemaakt door <a href="https://versbeton.nl" target="_blank">Vers Beton</a>, tijdschrift voor de harddenkende Rotterdammer. De kaart geeft de ontwikkelingen op de Rotterdamse woningmarkt weer in periode 2008-2019 en is onderdeel van <a href="https://versbeton.nl/harde-kern/wie-bezit-onze-stad/" target="_blank">onderzoeksdossier ‘Wie bezit onze stad?’</a> van journalist Maurice Geluk.  
    </p>
    <p>
    Ga naar Vers Beton voor <a href="https://versbeton.nl/2019/06/had-ik-toen-maar-een-huis-gekocht-bekijk-10-jaar-rotterdamse-woningmarkt" target="_blank">meer uitleg en de FAQ</a>.
    <br/> 
    Intro gemist? Bekijk hem <a href="/" >hier</a> nogmaals. 
    </p>
    `
};
