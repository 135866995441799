import React from "react";
import { colors } from "~src/style";

export const WozIllustration = (props: any) => (
  <svg viewBox="0 0 133.23 139.47" {...props}>
    <title>{"woz"}</title>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          d="M35.39 10.26S37.08 5 38.89 6c1.58.9-1.17 3.86-3.5 4.26z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.95}
        />
        <path
          d="M35.36 10.54s5.21-2.61 5.68-.12c.42 2.17-3.76 1.97-5.68.12z"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.07}
        />
        <text
          transform="translate(66.82 114.85)"
          fontSize={21}
          fill={colors.blue2}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          {props.percentage}
        </text>
        <path
          d="M7.92 116.71a5.42 5.42 0 0 1 0-7 .67.67 0 0 1 1.07 0 5.6 5.6 0 0 1 0 6.94.66.66 0 0 1-1.07.06zM11.13 121.35a5.43 5.43 0 0 0-7 0 .67.67 0 0 0 0 1.07 5.6 5.6 0 0 0 6.94 0 .67.67 0 0 0 .06-1.07zM11.84 120.64a5.43 5.43 0 0 1-.05-7 .67.67 0 0 1 1.07 0 5.6 5.6 0 0 1 0 6.94.67.67 0 0 1-1.02.06zM15.11 125.33a5.43 5.43 0 0 0-7 0 .67.67 0 0 0 0 1.07 5.6 5.6 0 0 0 6.94 0 .67.67 0 0 0 .06-1.07zM15.82 124.62a5.43 5.43 0 0 1-.05-7 .67.67 0 0 1 1.07 0 5.6 5.6 0 0 1 0 6.94.67.67 0 0 1-1.02.06zM7.33 117.55a5.42 5.42 0 0 0-7 0 .68.68 0 0 0 0 1.07 5.62 5.62 0 0 0 6.94 0 .67.67 0 0 0 .06-1.07zM5.15 115.28A5.42 5.42 0 0 1 .4 110.2a.67.67 0 0 1 .8-.71 5.61 5.61 0 0 1 4.73 5.08.66.66 0 0 1-.78.71zM18.53 108.09a5.44 5.44 0 0 1 1.77-6.73.66.66 0 0 1 1 .31 5.59 5.59 0 0 1-1.77 6.71.67.67 0 0 1-1-.29zM17.75 108.75a5.43 5.43 0 0 0-6.75-1.86.68.68 0 0 0-.25 1 5.61 5.61 0 0 0 6.69 1.84.66.66 0 0 0 .31-.98zM16.23 106A5.42 5.42 0 0 1 13 99.84a.67.67 0 0 1 1-.47 5.61 5.61 0 0 1 3.24 6.14.67.67 0 0 1-1.01.49z"
          stroke="#000"
          strokeMiterlimit={10}
          strokeWidth={0.24}
        />
        <g
          fill="none"
          stroke={colors.blue2}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.5}
        >
          <path d="M36.11 133.87l6.97-7.1 5.81 7.35 8-9.76 4.82 5.6 9.65-10.34" />
          <path d="M65.86 119.87l5.5-.25.5 5.47" />
        </g>
        <rect x={95.85} y={37.22} width={11.41} height={5.7} rx={1.46} />
        <rect x={108.68} y={37.22} width={11.41} height={5.7} rx={1.46} />
        <rect x={121.82} y={37.22} width={11.41} height={5.7} rx={1.46} />
        <rect x={82.85} y={133.4} width={12.15} height={6.08} rx={1.55} />
        <rect x={97.1} y={133.4} width={12.15} height={6.08} rx={1.55} />
        <rect x={90.45} y={125.9} width={12.15} height={6.08} rx={1.55} />
        <rect x={34.98} y={112.03} width={12.15} height={6.08} rx={1.55} />
        <rect x={49.23} y={112.03} width={12.15} height={6.08} rx={1.55} />
        <rect x={42.58} y={104.53} width={12.15} height={6.08} rx={1.55} />
        <rect x={68.57} y={80.49} width={12.15} height={6.08} rx={1.55} />
        <rect x={75.9} y={88.02} width={12.15} height={6.08} rx={1.55} />
        <rect x={102.24} y={30.56} width={11.41} height={5.7} rx={1.46} />
        <rect x={115.38} y={30.56} width={11.41} height={5.7} rx={1.46} />
        <rect x={108.65} y={23.83} width={11.41} height={5.7} rx={1.46} />
        <path d="M70.34 63.14l-3.69-3.43a1.47 1.47 0 0 1 0-2.11l3.53-3.53a1.46 1.46 0 0 1 2 0l3.69 3.43a1.48 1.48 0 0 1 0 2.11l-3.53 3.53a1.46 1.46 0 0 1-2 0zM73.37 68.77H69a.43.43 0 0 1-.43-.45l.09-4a.43.43 0 0 1 .71-.32l1.72 1.43a.44.44 0 0 0 .54 0l1.45-1.14a.44.44 0 0 1 .7.34v3.71a.44.44 0 0 1-.41.43z" />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.11}
          d="M112.18 88.19v49.99"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2.11}
          d="M79.47 138.19l-50.54-.05.02-50.03"
        />
        <path
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={2.11}
          d="M110.45 84.39l-36.04-13.2H67.3l-36.04 13.2"
        />
        <path d="M116.6 60.53H77.43a.35.35 0 0 1-.25-.59l1.21-1.29a.36.36 0 0 0 0-.5l-1.1-1a.35.35 0 0 1 .24-.6h35.07a.35.35 0 0 0 .34-.35v-6.38a.35.35 0 0 1 .35-.34h3.31a.34.34 0 0 1 .34.34v10.36a.35.35 0 0 1-.34.35zM26 60.78h39.2a.32.32 0 0 0 .23-.55l-1.28-1.36a.34.34 0 0 1 0-.45l1.06-1.1a.32.32 0 0 0-.24-.54H30a.32.32 0 0 1-.32-.33v-6.4a.32.32 0 0 0-.33-.32H26a.32.32 0 0 0-.32.32v10.4a.32.32 0 0 0 .32.33z" />
        <path
          d="M17.21 126.52a21.2 21.2 0 0 1 5.51 11.92M19.19 109.94s6.14 13.76 6.49 28.43"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={1.36}
        />
        <path
          d="M129.26 50.31l1.74-4.45a.69.69 0 0 0-.64-1H98.22a.7.7 0 0 0-.65 1l1.78 4.43a.69.69 0 0 0 .63.44c3.45 0 25.22.06 28.66 0a.66.66 0 0 0 .62-.42zM42.15 49.16l1.42-3.56a1.14 1.14 0 0 0-1-1.56h-31a1.14 1.14 0 0 0-1.06 1.56l1.43 3.55a1.12 1.12 0 0 0 1 .71c4.15 0 24 .06 28.15 0a1.15 1.15 0 0 0 1.06-.7zM19.81 12.53S13 13.61 13 18.79c.09 2.25 1.37 3.44 2.38 4.69 1.56 1.93-1 3.44-2.78 4.5-4.95 2.91-3 10.08 2.92 12.25 0 0 5.41 2.58 19.66.41 3.89-.66 7.07-1.85 8-5.2.6-3.65-2.4-6.15-4-7.34-1.64-1.54-2.19-3.19-.37-4.69 4-3.32 3.87-9.62-4.8-11-2.95-.51-11.12-.63-14.2.12zM21 9.29l-2.2-3.52a1.84 1.84 0 0 1 .4-2.47c1.58-1.41 3.33 1.15 3.33 1.15s1.08 1.86 2.41 1c.55-.35.57-1 .36-2.12A2.38 2.38 0 0 1 27.15.52C28.26.37 29.54.91 29.43 3S31 5.06 31.72 4.55s2.56-2.82 4.06-1.38c1.13 1.08.68 2-.63 3.31C33 8.6 33.46 10 32.71 10.08c-1.2.06-7.18-.29-10.66-.13-.59.11-1.05-.66-1.05-.66z"
          stroke="#000"
          strokeMiterlimit={10}
        />
        <text
          transform="translate(20.03 33.28)"
          fontSize={17.35}
          fill="#fff"
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          {"\u20AC"}
        </text>
        <text
          transform="translate(33.56 99.06)"
          fontSize={12}
          fill={colors.blue2}
          fontFamily="Helvetica-Bold, Helvetica"
          fontWeight={700}
        >
          {props.year}
        </text>
      </g>
    </g>
  </svg>
);

export default WozIllustration;
