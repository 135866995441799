import React from "react";
import css from "@emotion/css";

export const Rotterdam = (props: any) => {
  const {
    activeDistrict,
    setActiveDistrict,
    onDistrictClicked,
    getValueForDistrict,
    getDifferenceForDistrict,
    scale,
    position
  } = props;
  return (
    <svg
      className="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 868 521"
      enableBackground="new 0 0 868 521"
      xmlSpace="preserve"
    >
      <g className="transform-container">
        <g className="layer rotterdam">
          <g
            className={`district gebied-overig-haven-industrie ${
              activeDistrict === "gebied-overig-haven-industrie" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-overig-haven-industrie")}
          />
          <g
            className={`district gebied-hoogvliet ${
              activeDistrict === "gebied-hoogvliet" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-hoogvliet")}
          >
            <polyline
              className="shape gebied gebied-hoogvliet"
              fill="#FFFFFF"
              points="406.30426,365.04932 355.4856,367.09024 325.48422,372.80478  288.74783,384.43796 306.29965,427.50119 307.9086,430.14526 318.5451,448.72665 337.3215,467.09485 377.32333,485.87122  399.77335,492.60623 403.03882,456.68619 402.74713,424.98398 403.24292,422.3989 411.81473,398.92844 408.95746,395.66296  409.16156,376.8866 406.30426,365.04932  "
            />
          </g>
          <g
            className={`district gebied-hoek-van-holland ${
              activeDistrict === "gebied-hoek-van-holland" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-hoek-van-holland")}
          >
            <polyline
              className="shape gebied gebied-hoek-van-holland"
              fill="#FFFFFF"
              points="77.7155,110.53298 80.29614,101.50074  109.19931,111.30717 172.68306,58.14599 180.68304,64.08146 178.61853,67.43629 192.81206,87.56528 196.42494,89.11367  221.45715,100.72655 212.68298,122.40393 303.77957,177.62962 294.74734,185.88768 307.65054,215.56503 361.84399,262.27463  348.68271,287.30682 264.81192,230.01662 210.87653,181.24252 163.90887,148.21033 160.55405,146.40388 77.7155,110.53298  "
            />
          </g>
          <g
            className={`district gebied-rozenburg ${
              activeDistrict === "gebied-rozenburg" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-rozenburg")}
          >
            <g className="shape gebied gebied-rozenburg">
              <polygon
                fill="#FFFFFF"
                points="174.81209,272.79074 173.84435,275.11331 188.1669,310.14551 193.3927,339.56479  258.42484,362.59702 264.4248,354.0809 268.29578,341.69382 226.29585,316.91968  "
              />
            </g>
          </g>
          <g
            className={`district gebied-pernis ${
              activeDistrict === "gebied-pernis" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-pernis")}
          >
            <g className="shape gebied gebied-pernis">
              <path
                fill="#FFFFFF"
                d="M309.62042,339.43161l4.31027-7.63861l0.90744-1.74594c0-0.21823,0.2269-0.21823,0.2269-0.21823 l0.22687-0.21826l10.66238-6.76562l3.40289-8.51157l-1.58801-3.92841l-1.36118-2.40073 c-0.00418-0.22342-0.00757-0.40869-0.01172-0.63211l4.32202-8.31589l0.68054-1.52774l-0.73877-0.39597l-25.91721-9.86163 l-2.49545,6.98389l-1.19547-0.05389c-0.17212,0.17459-0.44583,0.27213-0.73273,0.27213l-6.36649-0.32812v18.21844 l9.99622,29.2507L309.62042,339.43161z"
              />
            </g>
          </g>
          <g
            className={`district gebied-centrum ${
              activeDistrict === "gebied-centrum" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-centrum")}
          >
            <g className="shape gebied gebied-centrum">
              <path
                fill="#FFFFFF"
                d="M558.35413,254.23375l-1.37421-2.74847l-26.11005-4.80977l-11.45184-3.43556l-6.64288-1.32112 l-1.76562-0.26562l-13.80786,0.44167l-15.8035,4.80977l-17.40674,2.06128l0.20569,2.64038l13.45309-1.35936l3.84244,3.65695 l0.47006,1.48366l1.03867,36.40814l-0.10614,1.9938l-12.27625,7.44171l0.74997,1.59375l10.09375,2.28125l1.90625,6.74997 l-1.21875,1.875l7.44849,20.05573l1.11719-0.44693l25.69672-14.74765l-1.7876-5.13931 c0.22351-0.22333,0.22351-0.44681,0.4469-0.67023l8.49109-10.27872l1.58093-1.77603l2.51947-1.79919l3.43549,0.65399 l19.46808-22.90356l0.22906-0.22906l13.284-6.18399L558.35413,254.23375z"
              />
            </g>
          </g>
          <g
            className={`district gebied-delfshaven ${
              activeDistrict === "gebied-delfshaven" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-delfshaven")}
          >
            <g className="shape gebied gebied-delfshaven">
              <polygon
                fill="#FFFFFF"
                points="462.38062,253.62981 476.76483,252.25011 479.9389,254.58232 481.14117,292.35083  467.77167,300.47406 467.92419,301.05939 468.94711,303.09058 469.68149,304.2937 479.85083,306.63568 481.50055,311.57916  480.11414,312.4549 479.42932,312.81195 482.18146,320.40305 478.22833,320.9968 482.61896,333.80927 477.34454,334.36542  475.20465,323.01892 471.93848,324.47931 471.39139,325.13593 472.52963,329.25653 472.94214,335.69107 457.6973,334.96973  466.83847,331.56387 467.09558,330.99512 466.19464,325.82739 455.38275,330.99512 443.66791,333.07269 414.90326,324.4433  425.14737,317.56094 422.64148,314.80801 407.8327,324.21683 403.05624,323.52563 411.47974,318.24918 409.42828,315.03741  393.7092,323.98889 394.39301,320.3183 407.60623,311.83008 406.46799,309.52713 387.5592,322.14917 386.19156,325.36536  377.53571,326.74182 377.3107,322.82858 391.88715,295.53305 387.5592,294.38599 376.85629,310.67419 373.8916,306.08597  381.86362,294.38599 378.67834,292.78012 371.69894,302.30069 370.45483,298.65216 373.8916,293.23453 369.13791,291.74554  370.31805,286.78894 372.23276,283.811 373.15042,282.19852 376.47025,275.62061 377.95923,274.01691 377.96216,270.80811  380.24747,261.42282 381.25333,260.95813 422.20621,256.07578 424.7709,255.91695 425.91501,255.69342 432.56689,254.67613  462.24731,252.76317  "
              />
            </g>
          </g>
          <g
            className={`district gebied-prins-alexander ${
              activeDistrict === "gebied-prins-alexander" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-prins-alexander")}
          >
            <polygon
              className="shape gebied gebied-prins-alexander"
              fill="#FFFFFF"
              points="774.40411,68.06602 784.15906,28.12711  774.85791,7.39377 695.91095,43.84086 714.51343,83.12504 713.56647,84.82987 692.06592,93.89457 690.22211,94.4094  683.64319,94.19114 671.16589,82.1876 655.7395,91.13566 645.98456,94.19114 626.70154,103.35746 619.62311,110.26365  630.33447,150.04881 627.43518,154.71996 629.42841,160.03539 630.1734,162.04881 642.81769,200.30376 643.22034,202.23662  648.69684,232.76006 648.21344,234.93456 644.96362,240.16588 647.93176,241.94121 641.56934,261.10901 641.6499,275.16266  641.71112,275.16046 642.05237,279.02844 643.98529,288.45123 660.29395,320.4646 671.28723,316.96124 677.08588,304.15588  691.8241,297.75323 686.62946,281.32373 684.33417,277.45795 677.93152,284.58545 675.63623,286.0351 669.23352,311.64581  659.20673,314.5451 645.31415,287.84723 643.74365,278.42438 643.78387,275.08673 643.82922,275.08514 643.80035,273.71906  643.80408,273.41101 643.79401,273.41821 643.54248,261.51166 645.55591,260.54523 649.04694,262.4585 668.55676,262.4585  690.56201,260.02625 700.09009,253.91539 706.44214,248.02275 709.3913,242.13005 706.44214,221.83324 706.039,220.11577  703.784,208.19633 703.49329,206.11961 700.3172,189.31468 725.15833,182.54906 727.427,181.02129 727.427,179.9301  713.47516,164.65288 703.72021,142.82835 702.89807,140.94817 701.11133,134.12999 701.99658,130.87968 703.0556,127.21196  706.76056,123.67766 713.79327,129.5703 716.51562,129.5703 722.18707,127.16954 732.62256,120.62224 745.55347,111.23759  751.45172,105.56321 752.85266,104.51304 765.32996,95.78324 768.73285,95.56498 790.28448,83.77976  "
            />
          </g>
          <g
            className={`district gebied-kralingen-crooswijk ${
              activeDistrict === "gebied-kralingen-crooswijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-kralingen-crooswijk")}
          >
            <g className="shape gebied gebied-kralingen-crooswijk">
              <polygon
                fill="#FFFFFF"
                points="536.28766,187.65445 534.14337,182.24843 602.1416,168.49173 623.08618,164.80717  628.15997,162.8743 641.29755,202.19637 647.06598,233.12248 645.82776,234.87416 642.26398,241.00502 645.58612,243.14931  640.05927,260.93787 640.02911,277.15594 640.72369,279.69287 642.95862,288.96466 656.00555,315.14917 648.21362,315.63239  641.59955,318.01828 641.14655,319.58875 633.20361,324.57196 630.48547,323.03171 625.7439,327.4411 621.90833,329.04175  610.91504,326.08203 605.96204,321.43103 605.99225,313.45789 602.3681,313.45789 601.43182,293.76666 592.16003,279.36066  590.64996,278.24319 580.41174,269.75665 564.79767,266.94794 559.57288,251.42447 558.12323,249.52177 556.43195,249.40097  530.88165,244.29697 531.90851,228.35068 532.24072,226.75002 536.3783,217.41782 541.84473,211.86078 542.59973,210.29031  536.80109,205.1259 532.51251,196.85074  "
              />
            </g>
          </g>
          <g
            className={`district gebied-ijsselmonde ${
              activeDistrict === "gebied-ijsselmonde" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-ijsselmonde")}
          >
            <polyline
              className="shape gebied gebied-ijsselmonde"
              fill="#FFFFFF"
              points="727.96185,363.24033 715.30078,427.41742 714.39319,429.8483  696.0177,429.8483 670.1557,442.28833 658.81274,451.45468 651.19348,458.66568 649.62524,459.78369 640.77765,461.96619  638.50903,462.40268 595.8595,462.40268 594.27142,452.58163 587.0119,440.79636 576.34949,433.15778 592.45654,417.66235  610.76404,415.33688 612.87384,403.91278 618.77246,402.1669 620.7951,401.16144 611.85132,382.92084 610.8446,381.26984  601.36493,364.01074 604.32733,365.90237 620.27765,364.15643 662.65967,349.53394 665.39398,351.49811 666.07758,349.97037  676.78705,348.0062 677.47064,353.6806 682.48352,354.77182 682.25574,348.44269 695.50885,352.77997 709.76385,354.04843  710.24707,356.10211 722.93164,357.73297 724.18219,354.1171 728.72552,357.45334 728.06586,361.35715 727.96185,363.24033  "
            />
          </g>
          <g
            className={`district gebied-charlois ${
              activeDistrict === "gebied-charlois" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-charlois")}
          >
            <polyline
              className="shape gebied gebied-charlois"
              fill="#FFFFFF"
              points="550.55707,359.32553 547.22052,356.78668 547.2085,359.89127  545.36823,364.51819 510.10114,374.83954 507.27432,375.79333 501.35236,377.93375 484.32971,362.95139 472.13779,363.17169  471.995,369.05096 476.07346,371.51242 464.73669,403.01947 470.16635,405.95175 469.90524,408.28992 461.57043,429.47229  463.10956,429.89539 465.24921,445.11163 457.78149,456.16302 433.15662,469.91168 497.13733,487.67902 515.38605,484.92932  518.24426,473.2959 545.57031,463.97748 557.65002,462.27423 593.70917,462.46887 592.1701,453.37366 585.35449,441.74023  574.58118,434.12561 556.88495,414.9342 545.28766,408.65234 544.07959,406.47784 544.80444,393.99466 546.81787,391.49802  547.14001,389.64566 547.38159,387.7128 546.89838,373.94101 551.0863,361.05511 550.55707,359.32553  "
            />
          </g>
          <g
            className={`district gebied-hillegersberg-schiebroek ${
              activeDistrict === "gebied-hillegersberg-schiebroek"
                ? "active"
                : ""
            }`}
            onClick={() => onDistrictClicked("gebied-hillegersberg-schiebroek")}
          >
            <g className="shape gebied gebied-hillegersberg-schiebroek">
              <polygon
                fill="#FFFFFF"
                points="479.32806,192.10915 477.99921,168.43134 477.93881,166.74007 481.68375,106.88112  472.68378,97.82073 479.14685,91.53886 479.81128,80.36439 482.4086,69.49193 488.75085,60.73355 494.42871,57.95504  500.89178,54.87451 544.92523,90.39122 556.28094,83.0825 580.68359,100.23683 583.82452,106.09589 593.91174,115.57909  607.7439,122.40458 608.04596,121.31733 617.95197,111.59252 628.2204,149.76694 624.95868,154.90115 627.61633,160.82062  622.80426,162.45148  "
              />
            </g>
          </g>
          <g
            className={`district gebied-overschie ${
              activeDistrict === "gebied-overschie" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-overschie")}
          >
            <g className="shape gebied gebied-overschie">
              <path
                fill="#FFFFFF"
                d="M479.97235,107.64622L470.8717,98.908l-22.26837,14.73823l-21.8255-24.60401l-3.38251-5.55701 l-16.06708-18.84564l-44.17447,30.32208l-9.78522,6.32214l-20.69794,11.35571l-3.10065,0.64429l-2.97986-9.34229h-2.45636 l-24.76508,6.48322l20.17444,55.48981l34.6308,1.28864l7.1275-1.08728l9.26172-5.55701l0.84564,1.44965l5.43628,9.26172 l1.57043,16.02679l3.86578,11.11407l8.85901,12.04028l0.92621,1.32886l21.38254,26.4563l30.40259-25.24829l1.5705-0.92615 l9.50336-6.76508l7.97308-8.33557l14.81879-4.26843l-1.651-25.36908L479.97235,107.64622z M392.71078,146.2636l-1.20807-0.06042 L379.543,128.26366l-1.63086-7.24835l29.8389-18.60395l14.255-1.93292l14.13422,21.08051L392.71078,146.2636z"
              />
            </g>
          </g>
          <g
            className={`district gebied-noord ${
              activeDistrict === "gebied-noord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-noord")}
          >
            <g className="shape gebied gebied-noord">
              <path
                fill="#FFFFFF"
                d="M462.22836,249.79381l-0.21875-2.625l18.37497-2.59375h1.79688l14.62497-4.49998 c0.06219-0.01465,13.20328-0.76562,13.28122-0.76562l2.65625,0.39062l5.625,0.9375l1.5094,0.69966l9.53741,2.48782 l1.22266-17.87105l4.44141-9.8203l5.41406-5.66014l-4.76953-3.94922l-5.08594-9.5742l4.18359-9.41013l-2.03906-4.99219 l-11.33569,2.82808l-2.49506,0.55203l-25.97845,5.35089l-1.98526,0.32648l-14.44534,2.96126l-12.6369,3.8266l-8.02158,8.40071 l-11.12497,7.42186l-30.40619,25.31245l9.99997,13.31247l14.95309-1.53125L462.22836,249.79381z"
              />
            </g>
          </g>
          <g
            className={`district gebied-feijenoord ${
              activeDistrict === "gebied-feijenoord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("gebied-feijenoord")}
          >
            <g className="shape gebied gebied-feijenoord">
              <polygon
                fill="#FFFFFF"
                points="610.19385,384.04205 609.51331,382.51447 597.98743,361.88507 593.77258,359.19373  588.27765,352.31116 588.00568,348.34375 587.6366,343.94104 589.32782,343.21619 589.56946,339.10883 578.21381,320.86725  580.14667,319.05518 589.20703,332.10211 589.08624,315.67261 584.13324,309.63239 584.37482,307.21625 576.52252,300.08875  576.88495,312.77332 572.77753,309.02838 572.17352,297.79346 568.54938,298.39752 562.02588,304.43774 561.85931,304.62097  559.80328,301.96808 572.91058,291.29285 565.77667,289.27631 552.78143,295.08197 537.04028,312.13879 542.46625,315.21991  549.85211,310.11823 558.60681,302.97076 561.01898,305.54535 560.81781,305.7666 553.32794,313.73975 571.8111,328.35718  568.9118,331.01489 551.28094,318.62573 550.91852,320.67938 557.45648,326.01642 557.07288,327.75317 555.14001,330.04846  543.42194,322.43774 528.32129,333.91418 530.37494,335.9679 539.79779,329.20282 551.75745,333.55182 551.75745,336.20953  551.87823,337.77997 542.81787,344.90747 512.375,346.47797 497.03278,355.1759 505.60992,361.21619 545.83801,349.73969  547.28766,354.20947 551.27423,357.22961 553.19843,357.56476 552.60309,361.82019 552.23126,363.96356 548.82843,374.43933  549.28217,389.71649 549.38159,392.10199 546.67316,394.73615 546.21942,406.52136 558.01617,413.06879 575.25745,431.40137  591.59131,415.46948 609.07275,413.72614 611.55493,401.93823 618.36078,400.41046  "
              />
            </g>
          </g>
          <g className="rotterdam-text rotterdam">
            <g
              className="district-text gebied-kralingen-crooswijk"
              onClick={() => onDistrictClicked("gebied-kralingen-crooswijk")}
              onMouseEnter={() =>
                setActiveDistrict("gebied-kralingen-crooswijk")
              }
              onMouseLeave={() =>
                activeDistrict === "gebied-kralingen-crooswijk" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={546.11383}
                y={221.17365}
                width={110.39896}
                height={17.4067}
              />
              <rect
                x={554.61975}
                y={203.76694}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 600.87793 233.2627)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Kralingen-Crooswijk"}
              </text>
              <text
                className="text gebied-kralingen-crooswijk"
                transform="matrix(1 0 0 1 584.64355 216.58301)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-kralingen-crooswijk")}
              </text>
              <rect
                x={614.21948}
                y={203.76819}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-kralingen-crooswijk"
                transform="matrix(1 0 0 1 631.646 216.58362)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-kralingen-crooswijk")}
              </text>
            </g>
            <g
              className="district-text gebied-prins-alexander"
              onClick={() => onDistrictClicked("gebied-prins-alexander")}
              onMouseEnter={() => setActiveDistrict("gebied-prins-alexander")}
              onMouseLeave={() =>
                activeDistrict === "gebied-prins-alexander" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={665.28674}
                y={107.92258}
                width={85.2498}
                height={17.4067}
              />
              <rect
                x={661.21814}
                y={90.51587}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 707.47656 120.01172)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Prins Alexander"}
              </text>
              <text
                className="text gebied-prins-alexander"
                transform="matrix(1 0 0 1 691.24219 103.33203)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-prins-alexander")}
              </text>
              <rect
                x={720.83905}
                y={90.53097}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-prins-alexander"
                transform="matrix(1 0 0 1 738.2655 103.34641)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-prins-alexander")}
              </text>
            </g>
            <g
              className="district-text gebied-hillegersberg-schiebroek"
              onClick={() =>
                onDistrictClicked("gebied-hillegersberg-schiebroek")
              }
              onMouseEnter={() =>
                setActiveDistrict("gebied-hillegersberg-schiebroek")
              }
              onMouseLeave={() =>
                activeDistrict === "gebied-hillegersberg-schiebroek" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={466.44574}
                y={108.84307}
                width={133.58446}
                height={17.4067}
              />
              <rect
                x={487.54443}
                y={91.43637}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 532.80273 120.93262)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Hillegersberg-Schiebroek"}
              </text>
              <text
                className="text gebied-hillegersberg-schiebroek"
                transform="matrix(1 0 0 1 517.56836 104.25195)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-hillegersberg-schiebroek")}
              </text>
              <rect
                x={547.17462}
                y={91.43701}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-hillegersberg-schiebroek"
                transform="matrix(1 0 0 1 564.60107 104.25244)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-hillegersberg-schiebroek")}
              </text>
            </g>
            <g
              className="district-text gebied-noord"
              onClick={() => onDistrictClicked("gebied-noord")}
              onMouseEnter={() => setActiveDistrict("gebied-noord")}
              onMouseLeave={() =>
                activeDistrict === "gebied-noord" && setActiveDistrict("")
              }
            >
              <rect
                x={459.59348}
                y={209.72176}
                width={43.1581}
                height={17.4067}
              />
              <rect
                x={436.74527}
                y={192.31505}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 480.73764 221.81055)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Noord"}
              </text>
              <text
                className="text gebied-noord"
                transform="matrix(1 0 0 1 466.76953 205.13086)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-noord")}
              </text>
              <rect
                x={496.34235}
                y={192.31621}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-noord"
                transform="matrix(1 0 0 1 513.7688 205.13164)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-noord")}
              </text>
            </g>
            <g
              className="district-text gebied-overschie"
              onClick={() => onDistrictClicked("gebied-overschie")}
              onMouseEnter={() => setActiveDistrict("gebied-overschie")}
              onMouseLeave={() =>
                activeDistrict === "gebied-overschie" && setActiveDistrict("")
              }
            >
              <rect
                x={365.10477}
                y={146.47069}
                width={62.1454}
                height={17.4067}
              />
              <rect
                x={349.59042}
                y={129.064}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 395.74216 158.55957)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Overschie"}
              </text>
              <text
                className="text gebied-overschie"
                transform="matrix(1 0 0 1 379.61426 141.87891)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-overschie")}
              </text>
              <rect
                x={409.19513}
                y={129.05438}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-overschie"
                transform="matrix(1 0 0 1 426.62158 141.86981)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-overschie")}
              </text>
            </g>
            <g
              className="district-text gebied-centrum"
              onClick={() => onDistrictClicked("gebied-centrum")}
              onMouseEnter={() => setActiveDistrict("gebied-centrum")}
              onMouseLeave={() =>
                activeDistrict === "gebied-centrum" && setActiveDistrict("")
              }
            >
              <rect
                x={481.91568}
                y={266.42889}
                width={57.43847}
                height={17.4067}
              />
              <rect
                x={463.18878}
                y={249.02219}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 510.20084 278.51758)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Centrum"}
              </text>
              <text
                className="text gebied-centrum"
                transform="matrix(1 0 0 1 493.21387 261.83789)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-centrum")}
              </text>
              <rect
                x={522.78491}
                y={249.024}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-centrum"
                transform="matrix(1 0 0 1 540.21136 261.83945)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-centrum")}
              </text>
            </g>
            <g
              className="district-text gebied-delfshaven"
              onClick={() => onDistrictClicked("gebied-delfshaven")}
              onMouseEnter={() => setActiveDistrict("gebied-delfshaven")}
              onMouseLeave={() =>
                activeDistrict === "gebied-delfshaven" && setActiveDistrict("")
              }
            >
              <rect
                x={372.94598}
                y={297.51257}
                width={68.33521}
                height={17.4067}
              />
              <rect
                x={365.95026}
                y={280.10587}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 406.6778 309.60156)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Delfshaven"}
              </text>
              <text
                className="text gebied-delfshaven"
                transform="matrix(1 0 0 1 395.97461 292.9209)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-delfshaven")}
              </text>
              <rect
                x={425.50949}
                y={280.10599}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-delfshaven"
                transform="matrix(1 0 0 1 442.93591 292.92142)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-delfshaven")}
              </text>
            </g>
            <g
              className="district-text gebied-charlois"
              onClick={() => onDistrictClicked("gebied-charlois")}
              onMouseEnter={() => setActiveDistrict("gebied-charlois")}
              onMouseLeave={() =>
                activeDistrict === "gebied-charlois" && setActiveDistrict("")
              }
            >
              <rect
                x={489.89133}
                y={430.19043}
                width={49.39812}
                height={17.4067}
              />
              <rect
                x={466.82895}
                y={412.78372}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 514.15479 442.2793)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Charlois"}
              </text>
              <text
                className="text gebied-charlois"
                transform="matrix(1 0 0 1 496.85449 425.59961)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-charlois")}
              </text>
              <rect
                x={526.41553}
                y={412.7637}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-charlois"
                transform="matrix(1 0 0 1 543.84198 425.57913)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-charlois")}
              </text>
            </g>
            <g
              className="district-text gebied-ijsselmonde"
              onClick={() => onDistrictClicked("gebied-ijsselmonde")}
              onMouseEnter={() => setActiveDistrict("gebied-ijsselmonde")}
              onMouseLeave={() =>
                activeDistrict === "gebied-ijsselmonde" && setActiveDistrict("")
              }
            >
              <rect
                x={641.55444}
                y={397.65485}
                width={73.45081}
                height={17.4067}
              />
              <rect
                x={632.12244}
                y={380.24817}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 677.8457 409.74414)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"IJsselmonde"}
              </text>
              <text
                className="text gebied-ijsselmonde"
                transform="matrix(1 0 0 1 662.14746 393.06445)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-ijsselmonde")}
              </text>
              <rect
                x={691.73053}
                y={380.2457}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-ijsselmonde"
                transform="matrix(1 0 0 1 709.15698 393.06113)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-ijsselmonde")}
              </text>
            </g>
            <g
              className="district-text gebied-feijenoord"
              onClick={() => onDistrictClicked("gebied-feijenoord")}
              onMouseEnter={() => setActiveDistrict("gebied-feijenoord")}
              onMouseLeave={() =>
                activeDistrict === "gebied-feijenoord" && setActiveDistrict("")
              }
            >
              <rect
                x={537.45282}
                y={380.94772}
                width={74.45734}
                height={17.4067}
              />
              <rect
                x={528.98798}
                y={363.54102}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 574.24609 393.03662)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Feijenoord"}
              </text>
              <text
                className="text gebied-feijenoord"
                transform="matrix(1 0 0 1 559.01172 376.35693)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-feijenoord")}
              </text>
              <rect
                x={588.58514}
                y={363.54102}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-feijenoord"
                transform="matrix(1 0 0 1 606.0116 376.35645)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-feijenoord")}
              </text>
            </g>
            <g
              className="district-text gebied-pernis"
              onClick={() => onDistrictClicked("gebied-pernis")}
              onMouseEnter={() => setActiveDistrict("gebied-pernis")}
              onMouseLeave={() =>
                activeDistrict === "gebied-pernis" && setActiveDistrict("")
              }
            >
              <rect
                x={268.53415}
                y={300.94772}
                width={43.32816}
                height={17.4067}
              />
              <rect
                x={244.98793}
                y={283.54102}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 289.76282 313.03613)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Pernis"}
              </text>
              <text
                className="text gebied-pernis"
                transform="matrix(1 0 0 1 275.01172 296.35645)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-pernis")}
              </text>
              <rect
                x={304.58514}
                y={283.54102}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-pernis"
                transform="matrix(1 0 0 1 322.01163 296.35645)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-pernis")}
              </text>
            </g>
            <g
              className="district-text gebied-hoogvliet"
              onClick={() => onDistrictClicked("gebied-hoogvliet")}
              onMouseEnter={() => setActiveDistrict("gebied-hoogvliet")}
              onMouseLeave={() =>
                activeDistrict === "gebied-hoogvliet" && setActiveDistrict("")
              }
            >
              <rect
                x={314.66092}
                y={410.94772}
                width={60.04111}
                height={17.4067}
              />
              <rect
                x={298.67926}
                y={393.54105}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 344.24609 423.03711)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Hoogvliet"}
              </text>
              <text
                className="text gebied-pernis"
                transform="matrix(1 0 0 1 328.70303 406.35693)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-pernis")}
              </text>
              <rect
                x={358.30325}
                y={393.54102}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-hoogvliet"
                transform="matrix(1 0 0 1 375.72974 406.35645)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-hoogvliet")}
              </text>
            </g>
            <g
              className="district-text gebied-rozenburg"
              onClick={() => onDistrictClicked("gebied-rozenburg")}
              onMouseEnter={() => setActiveDistrict("gebied-rozenburg")}
              onMouseLeave={() =>
                activeDistrict === "gebied-rozenburg" && setActiveDistrict("")
              }
            >
              <rect
                x={172.66092}
                y={350.94772}
                width={60.04111}
                height={17.4067}
              />
              <rect
                x={155.71275}
                y={333.54102}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 202.24609 363.03662)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Rozenburg"}
              </text>
              <text
                className="text gebied-rozenburg"
                transform="matrix(1 0 0 1 185.73654 346.35645)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-rozenburg")}
              </text>
              <rect
                x={215.33679}
                y={333.54102}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="difference gebied-rozenburg"
                transform="matrix(1 0 0 1 232.76324 346.35645)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-rozenburg")}
              </text>
            </g>
            <g
              className="district-text gebied-hoek-van-holland"
              onClick={() => onDistrictClicked("gebied-hoek-van-holland")}
              onMouseEnter={() => setActiveDistrict("gebied-hoek-van-holland")}
              onMouseLeave={() =>
                activeDistrict === "gebied-hoek-van-holland" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={161.08151}
                y={170.94772}
                width={90.81065}
                height={17.4067}
              />
              <rect
                x={158.98793}
                y={153.54102}
                fill="#FFF103"
                width={59.59958}
                height={17.4067}
              />
              <rect
                x={218.66566}
                y={153.54102}
                fill="#041056"
                width={35.07613}
                height={17.4067}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 206.05145 183.03613)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="9.67039px"
              >
                {"Hoek van Holland"}
              </text>
              <text
                className="text gebied-hoek-van-holland"
                transform="matrix(1 0 0 1 189.01172 166.35645)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getValueForDistrict("gebied-hoek-van-holland")}
              </text>
              <text
                className="difference gebied-hoek-van-holland"
                transform="matrix(1 0 0 1 236.0921 166.35645)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="9.67039px"
              >
                {getDifferenceForDistrict("gebied-hoek-van-holland")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-centrum" display="none">
          <path
            className="shape gebied gebied-centrum"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            d="M558.35413,254.23375 l-1.37421-2.74847l-26.11005-4.80977l-11.45184-3.43556l-6.64288-1.32112l-1.76562-0.26562l-13.80786,0.44167l-15.8035,4.80977 l-17.40674,2.06128l0.20569,2.6404l13.45309-1.35938l3.84244,3.65695l0.47006,1.48366l1.03867,36.40814l-0.10614,1.9938 l-12.27628,7.44171l0.75,1.59375l10.09375,2.28125l1.90625,6.74997l-1.21875,1.875l7.44849,20.05573l1.11719-0.44693 l25.69672-14.74765l-1.7876-5.13931c0.22351-0.22333,0.22351-0.44681,0.4469-0.67023l8.49109-10.27872l1.58093-1.77603 l2.51941-1.79919l3.43555,0.65399l19.46802-22.90356l0.22906-0.22906l13.28406-6.18399L558.35413,254.23375z"
          />
          <g
            className={`district stadsdriehoek ${
              activeDistrict === "stadsdriehoek" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("stadsdriehoek")}
          >
            <path
              className="shape stadsdriehoek"
              fill="#FFFFFF"
              d="M558.35413,254.23375l-1.37421-2.74846l-26.11011-4.80977 l-11.45178-3.43555l-6.6507-1.32506l0.9248,7.28001l8.47437,23.13264c0.229,0.22903,0,0.68707-0.22906,0.91614l-1.22479,0.86255 l-1.59375,17.81247l-5.53125,2.71872l1.8125,3.5l5.62109-1.49893l4.12268,4.08951l2.51941-1.79916l3.43555,0.65396 l19.46808-22.90359l0.229-0.22903l13.28406-6.18399L558.35413,254.23375z"
            />
          </g>
          <g
            className={`district oude-westen ${
              activeDistrict === "oude-westen" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oude-westen")}
          >
            <polygon
              className="shape oude-westen"
              fill="#FFFFFF"
              points="492.77518,254.57504 481.96271,255.45004 483.00293,291.79056  490.33209,288.12601 502.27515,276.63751 497.40018,255.38754  "
            />
          </g>
          <g
            className={`district cs-kwartier ${
              activeDistrict === "cs-kwartier" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("cs-kwartier")}
          >
            <polygon
              className="shape cs-kwartier"
              fill="#FFFFFF"
              points="493.45322,252.98383 497.93143,253.57504 511.96265,248.26256  511.02515,241.63757 497.20166,242.09486 481.39816,246.90463 463.99142,248.96591 464.18149,251.60631 477.63458,250.24693  481.49265,253.90388  "
            />
          </g>
          <g
            className={`district cool ${
              activeDistrict === "cool" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("cool")}
          >
            <path
              className="shape cool"
              fill="#FFFFFF"
              d="M512.54694,250.34018l-13.51309,4.80977l5.03882,21.75841l8.70248,16.29163 l5.03967-2.09137l1.1452-17.40674c0-0.22903,0.229-0.4581,0.45807-0.4581l1.1452-0.68707L512.54694,250.34018z"
            />
          </g>
          <g
            className={`district nieuwe-werk ${
              activeDistrict === "nieuwe-werk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("nieuwe-werk")}
          >
            <path
              className="shape nieuwe-werk"
              fill="#FFFFFF"
              d="M490.71594,333.33997l25.69666-14.74768l-1.78754-5.13928 c0.22345-0.22333,0.22345-0.44678,0.4469-0.67023l8.49109-10.27869l-3.57526-3.57526l-5.58765,1.52112l-31.99994,13.93747 l7.19849,19.39951L490.71594,333.33997z"
            />
          </g>
          <g
            className={`district dijkzigt ${
              activeDistrict === "dijkzigt" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("dijkzigt")}
          >
            <polygon
              className="shape dijkzigt"
              fill="#FFFFFF"
              points="483.36896,311.85617 513.96265,298.57495 510.8689,293.69998  503.00574,279.04187 491.08768,290.01248 482.89526,293.78949 470.61896,301.2312 471.40021,302.82495 481.46271,305.1062  "
            />
          </g>
          <g className="districten-centrum">
            <g
              className="district-text oude-westen"
              onClick={() => onDistrictClicked("oude-westen")}
              onMouseEnter={() => setActiveDistrict("oude-westen")}
              onMouseLeave={() =>
                activeDistrict === "oude-westen" && setActiveDistrict("")
              }
            >
              <rect
                x={470.79205}
                y={263.85684}
                width={25.82757}
                height={6.46868}
              />
              <rect
                x={465.34079}
                y={257.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={487.48907}
                y={257.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 483.64529 268.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oude Westen"}
              </text>
              <text
                className="text oude-westen"
                transform="matrix(1 0 0 1 476.39828 262.01715)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oude-westen")}
              </text>
              <text
                className="difference oude-westen"
                transform="matrix(1 0 0 1 493.69058 261.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oude-westen")}
              </text>
            </g>
            <g
              className="district-text stadsdriehoek"
              onClick={() => onDistrictClicked("stadsdriehoek")}
              onMouseEnter={() => setActiveDistrict("stadsdriehoek")}
              onMouseLeave={() =>
                activeDistrict === "stadsdriehoek" && setActiveDistrict("")
              }
            >
              <rect
                x={533.79205}
                y={273.85684}
                width={25.82757}
                height={6.46868}
              />
              <rect
                x={528.34082}
                y={267.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={550.48907}
                y={267.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 546.68359 278.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Stadsdriehoek"}
              </text>
              <text
                className="text stadsdriehoek"
                transform="matrix(1 0 0 1 539.39832 272.01718)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("stadsdriehoek")}
              </text>
              <text
                className="difference stadsdriehoek"
                transform="matrix(1 0 0 1 556.69061 271.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("stadsdriehoek")}
              </text>
            </g>
            <g
              className="district-text cs-kwartier"
              onClick={() => onDistrictClicked("cs-kwartier")}
              onMouseEnter={() => setActiveDistrict("cs-kwartier")}
              onMouseLeave={() =>
                activeDistrict === "cs-kwartier" && setActiveDistrict("")
              }
            >
              <rect
                x={477.79205}
                y={243.85683}
                width={25.82757}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 490.97925 248.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"CS-kwartier"}
              </text>
              <rect
                x={472.34079}
                y={237.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={494.48907}
                y={237.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="text cs-kwartier"
                transform="matrix(1 0 0 1 483.39828 242.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("cs-kwartier")}
              </text>
              <text
                className="difference cs-kwartier"
                transform="matrix(1 0 0 1 500.69058 241.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("cs-kwartier")}
              </text>
            </g>
            <g
              className="district-text cool"
              onClick={() => onDistrictClicked("cool")}
              onMouseEnter={() => setActiveDistrict("cool")}
              onMouseLeave={() =>
                activeDistrict === "cool" && setActiveDistrict("")
              }
            >
              <rect
                x={508.64124}
                y={262.85684}
                width={16.12919}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 517.38666 267.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Cool"}
              </text>
              <rect
                x={498.34079}
                y={256.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={520.48907}
                y={256.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="text cool"
                transform="matrix(1 0 0 1 509.39828 261.01715)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("cool")}
              </text>
              <text
                className="difference cool"
                transform="matrix(1 0 0 1 526.69055 260.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("cool")}
              </text>
            </g>
            <g
              className="district-text nieuwe-werk"
              onClick={() => onDistrictClicked("nieuwe-werk")}
              onMouseEnter={() => setActiveDistrict("nieuwe-werk")}
              onMouseLeave={() =>
                activeDistrict === "nieuwe-werk" && setActiveDistrict("")
              }
            >
              <rect
                x={493.67068}
                y={317.85684}
                width={26.07027}
                height={6.46868}
              />
              <rect
                x={488.34079}
                y={311.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={510.48907}
                y={311.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="text nieuwe-werk"
                transform="matrix(1 0 0 1 499.39828 316.01718)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("nieuwe-werk")}
              </text>
              <text
                className="difference nieuwe-werk"
                transform="matrix(1 0 0 1 516.69055 315.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("nieuwe-werk")}
              </text>
              <text
                className="name "
                transform="matrix(1 0 0 1 506.74805 322.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Nieuwe Werk"}
              </text>
            </g>
            <g
              className="district-text dijkzigt"
              onClick={() => onDistrictClicked("dijkzigt")}
              onMouseEnter={() => setActiveDistrict("dijkzigt")}
              onMouseLeave={() =>
                activeDistrict === "dijkzigt" && setActiveDistrict("")
              }
            >
              <rect
                x={487.40402}
                y={294.85684}
                width={18.60361}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 496.97223 299.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Dijkzigt"}
              </text>
              <rect
                x={478.34079}
                y={288.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={500.48907}
                y={288.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="text dijkzigt"
                transform="matrix(1 0 0 1 489.39828 293.01718)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("dijkzigt")}
              </text>
              <text
                className="difference dijkzigt"
                transform="matrix(1 0 0 1 506.69058 292.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("dijkzigt")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-delfshaven" display="none">
          <polygon
            className="shape gebied gebied-delfshaven"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="462.38062,253.62982  476.76483,252.25012 479.9389,254.58234 481.14117,292.35083 467.77167,300.47406 467.92419,301.05939 468.94711,303.09058  469.68146,304.2937 479.85083,306.63568 481.50055,311.57916 480.11414,312.4549 479.42932,312.81195 482.18146,320.40305  478.22833,320.9968 482.61896,333.80927 477.34454,334.36542 475.20465,323.01892 471.93848,324.47931 471.39139,325.13593  472.52963,329.2565 472.94214,335.69107 457.6973,334.96973 466.83847,331.56387 467.09558,330.99512 466.19464,325.82739  455.38275,330.99512 443.66791,333.07269 414.90326,324.4433 425.14737,317.56094 422.64148,314.80801 407.8327,324.21683  403.05624,323.52563 411.47974,318.24918 409.42828,315.03741 393.7092,323.98889 394.39301,320.3183 407.60623,311.83008  406.46799,309.52713 387.5592,322.14917 386.19156,325.36536 377.53571,326.74182 377.3107,322.82858 391.88715,295.53305  387.5592,294.38599 376.85629,310.67419 373.8916,306.08597 381.86365,294.38599 378.67834,292.78012 371.69894,302.30069  370.45483,298.65216 373.8916,293.23453 369.13794,291.74557 370.31805,286.78894 372.23276,283.811 373.15042,282.19852  376.47028,275.62061 377.95923,274.01691 377.96216,270.80811 380.24747,261.42282 381.25333,260.95813 422.20621,256.07578  424.7709,255.91696 425.91501,255.69342 432.56689,254.67615 462.24731,252.76318  "
          />
          <g
            className={`district middelland ${
              activeDistrict === "middelland" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("middelland")}
          >
            <path
              className="shape middelland"
              fill="#FFFFFF"
              d="M468.86899,256.99692l-1.40625,27.20306l3.49997,14.24997l10.17847-6.09912 l-1.20227-37.76849l-3.17407-2.33221l-0.83911,0.08051l-9.30673,0.91629 C466.61899,253.24692,468.86899,256.76791,468.86899,256.99692z"
            />
          </g>
          <g
            className={`district delfshaven ${
              activeDistrict === "delfshaven" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("delfshaven")}
          >
            <polygon
              className="shape delfshaven"
              fill="#FFFFFF"
              points="480.11414,312.4549 481.50055,311.57916 479.85083,306.63568  469.71271,304.2937 466.47836,304.6687 452.88464,300.59058 451.85339,299.7937 449.79089,299.69995 449.04089,320.41867  449.52945,320.94781 457.90527,321.91425 479.75958,313.69992 479.42932,312.81195  "
            />
          </g>
          <g
            className={`district oud-mathenesse ${
              activeDistrict === "oud-mathenesse" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oud-mathenesse")}
          >
            <path
              className="shape oud-mathenesse"
              fill="#FFFFFF"
              d="M409.65039,267.07501l12.48431-10.94528l-40.88489,4.83017l-1,0.45807 l-2.29034,9.39044v3.20654l-1.48871,1.60327l-3.32031,6.58173l36.68744,4.3125l1.8125-2.5625l-2.875-8.93744L409.65039,267.07501 z M396.18161,281.79373l7.09375-8.56247l5,10.34372L396.18161,281.79373z"
            />
          </g>
          <g
            className={`district nieuw-mathenesse ${
              activeDistrict === "nieuw-mathenesse" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("nieuw-mathenesse")}
          >
            <polygon
              className="shape nieuw-mathenesse"
              fill="#FFFFFF"
              points="421.08856,326.29623 414.89722,324.4465 425.14844,317.5621  422.64258,314.80829 407.83524,324.21701 403.05133,323.52859 411.48013,318.25058 409.42993,315.03784 393.71143,323.98758  394.39481,320.31586 407.60745,311.8251 406.46844,309.53027 387.56064,322.15167 386.19379,325.36444 377.53729,326.74127  377.30945,322.84012 391.88895,295.53195 387.56064,294.38455 376.85382,310.6777 373.8924,306.0881 381.86551,294.38455  378.6763,292.7782 371.69678,302.3038 370.45132,298.65341 373.8924,293.23721 369.13712,291.74738 370.31735,286.79019  372.23349,283.81268 409.90033,288.26248 418.66595,290.27811 435.05655,311.29367 422.47626,321.00043  "
            />
          </g>
          <g
            className={`district nieuwe-westen ${
              activeDistrict === "nieuwe-westen" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("nieuwe-westen")}
          >
            <polygon
              className="shape nieuwe-westen"
              fill="#FFFFFF"
              points="439.99762,268.51794 427.5856,255.44049 432.56689,254.67615  462.24731,252.76318 462.38062,253.62982 464.80676,253.39709 467.25287,257.06622 465.9931,284.09247 465.9931,284.32147  469.68744,299.31006 467.77167,300.47406 467.92419,301.05939 468.92957,303.06885 466.56573,303.33154 453.28162,298.9798  441.60083,274.70197  "
            />
          </g>
          <g
            className={`district spangen ${
              activeDistrict === "spangen" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("spangen")}
          >
            <path
              className="shape spangen"
              fill="#FFFFFF"
              d="M439.90027,273.96564l-1.03122-4.35938L425.91174,255.692l-1.14514,0.22906 l-13.51309,11.9099l-0.91614,7.1001l2.97742,8.70334c-0.034,0.26178-0.07684,0.59164-0.11087,0.85339l-1.72141,2.58215 l8.87094,1.89566l6.32809-9.09375L439.90027,273.96564z"
            />
          </g>
          <g
            className={`district tussendijken ${
              activeDistrict === "tussendijken" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("tussendijken")}
          >
            <polygon
              className="shape tussendijken"
              fill="#FFFFFF"
              points="451.44925,298.32581 440.45557,275.19318 428.08755,280.91907  422.16608,289.4035 449.84598,298.32581  "
            />
          </g>
          <g
            className={`district bospolder ${
              activeDistrict === "bospolder" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("bospolder")}
          >
            <polygon
              className="shape bospolder"
              fill="#FFFFFF"
              points="420.86908,290.69998 436.6503,310.88742 447.43552,319.25653  448.24402,299.32495  "
            />
          </g>
          <g
            className={`district schiemond ${
              activeDistrict === "schiemond" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("schiemond")}
          >
            <polygon
              className="shape schiemond"
              fill="#FFFFFF"
              points="478.22693,321.00433 482.60803,333.80286 477.34454,334.36542  475.20465,323.01892 471.93848,324.47931 471.39276,325.13501 472.5318,329.26556 472.94342,335.69196 457.66229,334.97589  466.83679,331.56042 467.09558,330.99512 466.19464,325.82739 455.38275,330.99512 443.64563,333.0741 440.76404,332.30957  422.55325,326.7374 423.68158,321.91867 436.00967,312.44992 448.92371,322.4343 458.15805,323.60617 480.27521,315.07492  482.16608,320.4035  "
            />
          </g>
          <g
            className={`district witte-dorp ${
              activeDistrict === "witte-dorp" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("witte-dorp")}
          >
            <polygon
              className="shape witte-dorp"
              fill="#FFFFFF"
              points="398.33786,281.01248 403.02536,275.26251 406.28122,282.04855  "
            />
          </g>
          <g className="districten-delfshaven">
            <g
              className="district-text middelland"
              onClick={() => onDistrictClicked("middelland")}
              onMouseEnter={() => setActiveDistrict("middelland")}
              onMouseLeave={() =>
                activeDistrict === "middelland" && setActiveDistrict("")
              }
            >
              <rect x={471.32574} y={275.10666} width={22} height={6.46868} />
              <rect
                x={465.09048}
                y={268.63684}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 482.35464 279.59882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Middelland"}
              </text>
              <text
                className="text middelland"
                transform="matrix(1 0 0 1 476.43964 273.39862)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("middelland")}
              </text>
              <rect
                x={487.23712}
                y={268.63815}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference middelland"
                transform="matrix(1 0 0 1 493.48398 273.38019)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("middelland")}
              </text>
            </g>
            <g
              className="district-text delfshaven"
              onClick={() => onDistrictClicked("delfshaven")}
              onMouseEnter={() => setActiveDistrict("delfshaven")}
              onMouseLeave={() =>
                activeDistrict === "delfshaven" && setActiveDistrict("")
              }
            >
              <rect x={459.28973} y={310.60825} width={22} height={6.46868} />
              <rect
                x={459.21552}
                y={304.13925}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 470.37646 315.06757)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Delfshaven"}
              </text>
              <text
                className="text delfshaven"
                transform="matrix(1 0 0 1 470.35049 308.90106)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("delfshaven")}
              </text>
              <rect
                x={481.36292}
                y={304.13928}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference delfshaven"
                transform="matrix(1 0 0 1 487.60977 308.88132)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("delfshaven")}
              </text>
            </g>
            <g
              className="district-text nieuwe-westen"
              onClick={() => onDistrictClicked("nieuwe-westen")}
              onMouseEnter={() => setActiveDistrict("nieuwe-westen")}
              onMouseLeave={() =>
                activeDistrict === "nieuwe-westen" && setActiveDistrict("")
              }
            >
              <rect x={437.98883} y={253.85683} width={31} height={6.46868} />
              <rect
                x={436.34079}
                y={247.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 453.42804 258.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Nieuwe Westen"}
              </text>
              <rect
                x={458.48907}
                y={247.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="text nieuwe-westen"
                transform="matrix(1 0 0 1 447.39832 252.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("nieuwe-westen")}
              </text>
              <text
                className="difference nieuwe-westen"
                transform="matrix(1 0 0 1 465.44897 251.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("nieuwe-westen")}
              </text>
            </g>
            <g
              className="district-text spangen"
              onClick={() => onDistrictClicked("spangen")}
              onMouseEnter={() => setActiveDistrict("spangen")}
              onMouseLeave={() =>
                activeDistrict === "spangen" && setActiveDistrict("")
              }
            >
              <rect x={414.54016} y={275.51294} width={19} height={6.46868} />
              <rect
                x={412.96597}
                y={269.04398}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 424.08868 280.00507)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Spangen"}
              </text>
              <text
                className="text spangen"
                transform="matrix(1 0 0 1 424.25272 273.80585)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("spangen")}
              </text>
              <rect
                x={435.11432}
                y={269.04453}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference spangen"
                transform="matrix(1 0 0 1 441.36115 273.78656)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("spangen")}
              </text>
            </g>
            <g
              className="district-text tussendijken"
              onClick={() => onDistrictClicked("tussendijken")}
              onMouseEnter={() => setActiveDistrict("tussendijken")}
              onMouseLeave={() =>
                activeDistrict === "tussendijken" && setActiveDistrict("")
              }
            >
              <rect x={429.12619} y={287.16904} width={25} height={6.46868} />
              <rect
                x={430.55197}
                y={280.69141}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 441.49188 291.66132)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Tussendijken"}
              </text>
              <text
                className="text tussendijken"
                transform="matrix(1 0 0 1 441.77585 285.45331)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("tussendijken")}
              </text>
              <rect
                x={452.70425}
                y={280.69525}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference tussendijken"
                transform="matrix(1 0 0 1 458.74487 285.48105)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("tussendijken")}
              </text>
            </g>
            <g
              className="district-text bospolder"
              onClick={() => onDistrictClicked("bospolder")}
              onMouseEnter={() => setActiveDistrict("bospolder")}
              onMouseLeave={() =>
                activeDistrict === "bospolder" && setActiveDistrict("")
              }
            >
              <rect x={428.54016} y={302.12793} width={21} height={6.46868} />
              <rect
                x={427.96597}
                y={295.63773}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 439.14282 306.59882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Bospolder"}
              </text>
              <text
                className="text bospolder"
                transform="matrix(1 0 0 1 439.24658 300.3996)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("bospolder")}
              </text>
              <rect
                x={450.11362}
                y={295.63766}
                fill="#041056"
                width={12}
                height={6.46868}
              />
              <text
                className="difference bospolder"
                transform="matrix(1 0 0 1 456.36047 300.3797)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("bospolder")}
              </text>
            </g>
            <g
              className="district-text schiemond"
              onClick={() => onDistrictClicked("schiemond")}
              onMouseEnter={() => setActiveDistrict("schiemond")}
              onMouseLeave={() =>
                activeDistrict === "schiemond" && setActiveDistrict("")
              }
            >
              <rect x={421.16507} y={322.94992} width={23} height={6.46868} />
              <rect
                x={421.59085}
                y={316.47946}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={443.73962}
                y={316.47726}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 432.8739 327.44257)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Schiemond"}
              </text>
              <text
                className="text schiemond"
                transform="matrix(1 0 0 1 432.74872 321.24139)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("schiemond")}
              </text>
              <text
                className="difference schiemond"
                transform="matrix(1 0 0 1 449.98648 321.2193)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("schiemond")}
              </text>
            </g>
            <g
              className="district-text witte-dorp"
              onClick={() => onDistrictClicked("witte-dorp")}
              onMouseEnter={() => setActiveDistrict("witte-dorp")}
              onMouseLeave={() =>
                activeDistrict === "witte-dorp" && setActiveDistrict("")
              }
            >
              <rect
                x={373.26547}
                y={276.38791}
                width={20.05}
                height={6.46868}
              />
              <rect
                x={372.21628}
                y={269.91922}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 383.44333 280.88007)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Witte dorp"}
              </text>
              <text
                className="text witte-dorp"
                transform="matrix(1 0 0 1 383.29874 274.68085)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("witte-dorp")}
              </text>
              <rect
                x={394.36447}
                y={269.92004}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference witte-dorp"
                transform="matrix(1 0 0 1 400.28595 274.66208)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("witte-dorp")}
              </text>
            </g>
            <g
              className="district-text oud-mathenesse"
              onClick={() => onDistrictClicked("oud-mathenesse")}
              onMouseEnter={() => setActiveDistrict("oud-mathenesse")}
              onMouseLeave={() =>
                activeDistrict === "oud-mathenesse" && setActiveDistrict("")
              }
            >
              <rect x={352.22052} y={256.73181} width={31} height={6.46868} />
              <rect
                x={356.6463}
                y={250.26207}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 367.87509 261.22382)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oud-Mathenesse"}
              </text>
              <text
                className="text oud-mathenesse"
                transform="matrix(1 0 0 1 367.83731 255.0246)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oud-mathenesse")}
              </text>
              <rect
                x={378.79321}
                y={250.26161}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="difference oud-mathenesse"
                transform="matrix(1 0 0 1 384.85562 255.00365)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oud-mathenesse")}
              </text>
            </g>
            <g className=":disctrict-text:nieuw-mathenesse">
              <rect x={351.09869} y={307.94614} width={35.5} height={6.46868} />
              <rect
                x={349.74689}
                y={301.47745}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={371.89423}
                y={301.47726}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 368.88449 312.43866)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Nieuw-Mathenesse"}
              </text>
              <text
                className="difference nieuw-mathenesse"
                transform="matrix(1 0 0 1 379.84927 306.2193)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("nieuw-mathenesse")}
              </text>
              <text
                className="text nieuw-mathenesse"
                transform="matrix(1 0 0 1 360.90262 306.23944)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("nieuw-mathenesse")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-noord" display="none">
          <path
            className="shape gebied gebied-noord"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            d="M462.22836,249.79381l-0.21875-2.625 l18.37497-2.59373h1.79688l14.62497-4.5c0.06219-0.01465,13.20328-0.76562,13.28122-0.76562l2.65625,0.39062l5.625,0.9375 l1.5094,0.69966l9.53741,2.48784l1.22266-17.87106l4.44141-9.8203l5.41406-5.66014l-4.76953-3.9492l-5.08594-9.5742 l4.18359-9.41014l-2.03906-4.99217l-11.33569,2.82806l-2.49506,0.55203l-25.97845,5.35091l-1.98529,0.32646l-14.44531,2.96127 l-12.6369,3.8266l-8.02158,8.40071l-11.12497,7.42186l-30.40619,25.31244l9.99997,13.31247l14.95309-1.53123L462.22836,249.79381z "
          />
          <g
            className={`district provenierswijk ${
              activeDistrict === "provenierswijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("provenierswijk")}
          >
            <polygon
              className="shape provenierswijk"
              fill="#FFFFFF"
              points="481.51834,242.15749 482.18146,244.57507 496.80643,240.07507  510.1026,239.32034 496.26422,224.91612  "
            />
          </g>
          <g
            className={`district liskwartier ${
              activeDistrict === "liskwartier" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("liskwartier")}
          >
            <polygon
              className="shape liskwartier"
              fill="#FFFFFF"
              points="518.9314,185.93456 492.97476,191.27478 496.33768,214.10638  499.6189,218.70012 502.9939,214.10638 517.71265,197.79391 516.58765,189.26268  "
            />
          </g>
          <g
            className={`district agniesebuurt ${
              activeDistrict === "agniesebuurt" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("agniesebuurt")}
          >
            <polygon
              className="shape agniesebuurt"
              fill="#FFFFFF"
              points="503.65015,216.73137 500.27515,220.20012 497.17172,223.3569  512.7439,239.70007 518.3689,240.63757 524.84851,229.6861  "
            />
          </g>
          <g
            className={`district blijdorp ${
              activeDistrict === "blijdorp" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("blijdorp")}
          >
            <polygon
              className="shape blijdorp"
              fill="#FFFFFF"
              points="439.09564,251.32394 462.23529,249.7962 462.00845,247.1772  480.40021,244.57507 479.83771,241.66882 495.02518,223.7626 491.18143,221.79387 481.43146,223.10635 472.71271,206.2314  480.11896,201.82515 476.52753,194.58008 463.90024,198.38766 455.88327,206.80183 444.76712,214.22217 414.36804,239.53867  424.34982,252.85168  "
            />
          </g>
          <g
            className={`district bergpolder ${
              activeDistrict === "bergpolder" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("bergpolder")}
          >
            <polygon
              className="shape bergpolder"
              fill="#FFFFFF"
              points="494.67624,214.87686 491.18143,191.60643 478.0708,194.27884  481.97217,202.21867 481.43146,202.95015 474.86896,206.84077 482.41583,221.32512 491.50021,220.11475 496.05643,222.45012  498.40018,219.91887  "
            />
          </g>
          <g
            className={`district oude-noorden ${
              activeDistrict === "oude-noorden" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oude-noorden")}
          >
            <path
              className="shape oude-noorden"
              fill="#FFFFFF"
              d="M535.05707,216.15474l5.44458-5.67442l-4.76404-3.92839l-5.10419-9.60289 l4.19672-9.38455l-2.04156-5.01949l-11.34302,2.83714l-3.17596,4.36496l1.13428,8.51158 c0,0.21822-14.97275,16.80493-14.97275,16.80493l22.56271,14.04274l-7.12494,12.28122l9.56244,2.4375l1.20209-17.84929 L535.05707,216.15474z"
            />
          </g>
          <g className="districten-noord">
            <g
              className="district-text oude-noorden"
              onClick={() => onDistrictClicked("oude-noorden")}
              onMouseEnter={() => setActiveDistrict("oude-noorden")}
              onMouseLeave={() =>
                activeDistrict === "oude-noorden" && setActiveDistrict("")
              }
            >
              <rect
                x={512.39215}
                y={207.85683}
                width={28.62737}
                height={6.46868}
              />
              <rect
                x={508.34079}
                y={201.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={530.48907}
                y={201.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 526.71283 212.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oude Noorden"}
              </text>
              <text
                className="text oude-noorden"
                transform="matrix(1 0 0 1 519.39832 206.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oude-noorden")}
              </text>
              <text
                className="difference oude-noorden"
                transform="matrix(1 0 0 1 536.69055 205.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oude-noorden")}
              </text>
            </g>
            <g
              className="district-text provenierswijk"
              onClick={() => onDistrictClicked("provenierswijk")}
              onMouseEnter={() => setActiveDistrict("provenierswijk")}
              onMouseLeave={() =>
                activeDistrict === "provenierswijk" && setActiveDistrict("")
              }
            >
              <rect
                x={477.64926}
                y={241.85683}
                width={30.11311}
                height={6.46868}
              />
              <rect
                x={474.34079}
                y={235.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={496.48907}
                y={235.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 492.6987 246.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Provenierswijk"}
              </text>
              <text
                className="text provenierswijk"
                transform="matrix(1 0 0 1 485.39828 240.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("provenierswijk")}
              </text>
              <text
                className="difference provenierswijk"
                transform="matrix(1 0 0 1 502.69058 239.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("provenierswijk")}
              </text>
            </g>
            <g
              className="district-text liskwartier"
              onClick={() => onDistrictClicked("liskwartier")}
              onMouseEnter={() => setActiveDistrict("liskwartier")}
              onMouseLeave={() =>
                activeDistrict === "liskwartier" && setActiveDistrict("")
              }
            >
              <rect
                x={482.67313}
                y={191.85683}
                width={24.06538}
                height={6.46868}
              />
              <rect
                x={476.34079}
                y={185.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={498.48907}
                y={185.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 495.17963 196.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Liskwartier"}
              </text>
              <text
                className="text liskwartier"
                transform="matrix(1 0 0 1 487.39828 190.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("liskwartier")}
              </text>
              <text
                className="difference liskwartier"
                transform="matrix(1 0 0 1 504.69058 189.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("liskwartier")}
              </text>
            </g>
            <g
              className="district-text agniesebuurt"
              onClick={() => onDistrictClicked("agniesebuurt")}
              onMouseEnter={() => setActiveDistrict("agniesebuurt")}
              onMouseLeave={() =>
                activeDistrict === "agniesebuurt" && setActiveDistrict("")
              }
            >
              <rect
                x={502.75275}
                y={227.85683}
                width={29.90617}
                height={6.46868}
              />
              <rect
                x={499.34079}
                y={221.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={521.48907}
                y={221.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 518.25549 232.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Agniesebuurt"}
              </text>
              <text
                className="text agniesebuurt"
                transform="matrix(1 0 0 1 510.39828 226.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("agniesebuurt")}
              </text>
              <text
                className="difference agniesebuurt"
                transform="matrix(1 0 0 1 527.69055 225.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("agniesebuurt")}
              </text>
            </g>
            <g
              className="district-text blijdorp"
              onClick={() => onDistrictClicked("blijdorp")}
              onMouseEnter={() => setActiveDistrict("blijdorp")}
              onMouseLeave={() =>
                activeDistrict === "blijdorp" && setActiveDistrict("")
              }
            >
              <rect
                x={432.87311}
                y={233.85683}
                width={19.66543}
                height={6.46868}
              />
              <rect
                x={424.34079}
                y={227.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={446.48907}
                y={227.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 442.97437 238.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Blijdorp"}
              </text>
              <text
                className="text bergpolder"
                transform="matrix(1 0 0 1 435.39828 232.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("bergpolder")}
              </text>
              <text
                className="difference bergpolder"
                transform="matrix(1 0 0 1 452.69058 231.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("bergpolder")}
              </text>
            </g>
            <g
              className="district-text bergpolder"
              onClick={() => onDistrictClicked("bergpolder")}
              onMouseEnter={() => setActiveDistrict("bergpolder")}
              onMouseLeave={() =>
                activeDistrict === "bergpolder" && setActiveDistrict("")
              }
            >
              <rect
                x={468.39215}
                y={213.85683}
                width={28.62737}
                height={6.46868}
              />
              <rect
                x={464.34079}
                y={207.38754}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={486.48907}
                y={207.38663}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 482.9765 218.34882)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Bergpolder"}
              </text>
              <text
                className="text bergpolder"
                transform="matrix(1 0 0 1 475.39828 212.01717)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("bergpolder")}
              </text>
              <text
                className="difference bergpolder"
                transform="matrix(1 0 0 1 492.69058 211.85629)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("bergpolder")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-hillegersberg-schiebroek" display="none">
          <polygon
            className="shape gebied gebied-hillegersberg-schiebroek"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points=" 479.32806,192.10915 477.99921,168.43135 477.93878,166.74007 481.68375,106.88113 472.68378,97.82075 479.14685,91.53888  479.81128,80.36441 482.4086,69.49194 488.75085,60.73357 494.42868,57.95506 500.89178,54.87453 554.48877,96.98464  556.28094,83.08252 580.68353,100.23685 583.82446,106.0959 593.91174,115.5791 607.7439,122.40459 608.0459,121.31734  617.95197,111.59253 628.2204,149.76695 624.95862,154.90117 627.61633,160.82062 622.80426,162.45149  "
          />
          <g
            className={`district hillegersberg-zuid ${
              activeDistrict === "hillegersberg-zuid" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("hillegersberg-zuid")}
          >
            <path
              className="shape hillegersberg-zuid"
              fill="#FFFFFF"
              d="M512.59186,157.09903l-3.53668,12.03123 c0.00168,0.21828-3.27081,2.2608-3.27081,2.2608l-27.78516-2.89932l1.36911,23.63754l14.41608-2.97986l28.46976-5.8792 l11.43622-2.41609l2.57715-5.79865l5.87921-2.85905l-8.255-6.16107l-4.16779,5.67784L512.59186,157.09903z"
            />
          </g>
          <g
            className={`district molenlaankwartier ${
              activeDistrict === "molenlaankwartier" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("molenlaankwartier")}
          >
            <path
              className="shape molenlaankwartier"
              fill="#FFFFFF"
              d="M584.87146,151.21661l-0.32214,2.41611l9.26172-2.01343l10.79193-7.57045 l1.44965-6.80536l-2.09393-4.75166l2.255-10.75166l-12.32593-6.15755c0,0-9.78284-9.26655-10.01038-9.48207l-3.20697-5.84135 l-24.29797-17.19301l0.1015,13.1072l-1.16351,28.93269L584.87146,151.21661z"
            />
          </g>
          <g
            className={`district terbregge ${
              activeDistrict === "terbregge" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("terbregge")}
          >
            <polygon
              className="shape terbregge"
              fill="#FFFFFF"
              points="605.99225,144.99515 594.23523,153.21112 583.58289,155.32399  574.95935,154.20201 567.2666,156.88062 560.80627,172.2083 544.47961,173.20776 537.69604,176.09755 535.86487,180.41118  622.72375,162.5723 627.67676,161.00183 624.98871,154.90578 628.23932,149.86086 617.96021,111.52807 608.05475,121.42613  605.53918,132.31061 607.56268,137.23341  "
            />
          </g>
          <g
            className={`district hillegersberg-noord ${
              activeDistrict === "hillegersberg-noord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("hillegersberg-noord")}
          >
            <polygon
              className="shape hillegersberg-noord"
              fill="#FFFFFF"
              points="524.39044,127.78001 518.53955,133.93648 513.1535,155.12265  529.42194,169.13605 533.44873,163.82062 544.80444,171.4716 559.4621,170.58571 565.82452,155.60587 574.68359,152.30386  582.89832,153.35085 583.33624,151.98602 553.41736,126.24566 554.55878,97.8641 545.44073,92.26013 531.93604,106.11459  "
            />
          </g>
          <g
            className={`district schiebroek ${
              activeDistrict === "schiebroek" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("schiebroek")}
          >
            <polygon
              className="shape schiebroek"
              fill="#FFFFFF"
              points="477.95776,166.73886 505.65744,169.57983 507.43539,168.53201  511.46222,155.60587 517.26086,132.81396 523.30115,126.37103 530.62994,104.62611 544.97333,90.48632 500.9119,54.816  495.71289,57.25706 493.67972,58.3641 488.70752,60.80341 482.42328,69.58213 479.78558,80.51518 479.18945,91.43249  472.65982,97.81238 481.69138,106.90903  "
            />
          </g>
          <g className="districten-hillegersberg-schiebroek">
            <g
              className="district-text hillegersberg-zuid"
              onClick={() => onDistrictClicked("hillegersberg-zuid")}
              onMouseEnter={() => setActiveDistrict("hillegersberg-zuid")}
              onMouseLeave={() =>
                activeDistrict === "hillegersberg-zuid" && setActiveDistrict("")
              }
            >
              <rect
                x={480.75916}
                y={172.5576}
                width={34.48294}
                height={6.46868}
              />
              <rect
                x={480.07153}
                y={166.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={502.21982}
                y={166.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 494.84555 177.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Hillegersberg-Zuid"}
              </text>
              <text
                className="text hillegersberg-zuid"
                transform="matrix(1 0 0 1 491.12903 170.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("hillegersberg-zuid")}
              </text>
              <text
                className="difference hillegersberg-zuid"
                transform="matrix(1 0 0 1 508.4213 170.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("hillegersberg-zuid")}
              </text>
            </g>
            <g
              className="district-text molenlaankwartier"
              onClick={() => onDistrictClicked("molenlaankwartier")}
              onMouseEnter={() => setActiveDistrict("molenlaankwartier")}
              onMouseLeave={() =>
                activeDistrict === "molenlaankwartier" && setActiveDistrict("")
              }
            >
              <rect
                x={565.6319}
                y={112.5576}
                width={34.73747}
                height={6.46868}
              />
              <rect
                x={565.10773}
                y={106.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={587.25598}
                y={106.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 583.32007 117.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Molenlaankwartier"}
              </text>
              <text
                className="text molenlaankwartier"
                transform="matrix(1 0 0 1 576.16516 110.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("molenlaankwartier")}
              </text>
              <text
                className="difference molenlaankwartier"
                transform="matrix(1 0 0 1 593.45746 110.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("molenlaankwartier")}
              </text>
            </g>
            <g
              className="district-text terbregge"
              onClick={() => onDistrictClicked("terbregge")}
              onMouseEnter={() => setActiveDistrict("terbregge")}
              onMouseLeave={() =>
                activeDistrict === "terbregge" && setActiveDistrict("")
              }
            >
              <rect
                x={592.69446}
                y={159.5576}
                width={20.6123}
                height={6.46868}
              />
              <rect
                x={584.63562}
                y={153.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={606.78387}
                y={153.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 602.81543 164.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Terbregge"}
              </text>
              <text
                className="text terbregge"
                transform="matrix(1 0 0 1 595.69312 157.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("terbregge")}
              </text>
              <text
                className="difference terbregge"
                transform="matrix(1 0 0 1 612.98535 157.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("terbregge")}
              </text>
            </g>
            <g
              className="district-text hillegersberg-noord"
              onClick={() => onDistrictClicked("hillegersberg-noord")}
              onMouseEnter={() => setActiveDistrict("hillegersberg-noord")}
              onMouseLeave={() =>
                activeDistrict === "hillegersberg-noord" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={513.50977}
                y={134.5576}
                width={36.98178}
                height={6.46868}
              />
              <rect
                x={513.99792}
                y={128.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={536.14624}
                y={128.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 531.59113 139.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Hillegersberg-Noord"}
              </text>
              <text
                className="text hillegersberg-noord"
                transform="matrix(1 0 0 1 525.05542 132.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("hillegersberg-noord")}
              </text>
              <text
                className="difference hillegersberg-noord"
                transform="matrix(1 0 0 1 542.34772 132.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("hillegersberg-noord")}
              </text>
            </g>
            <g
              className="district-text schiebroek"
              onClick={() => onDistrictClicked("schiebroek")}
              onMouseEnter={() => setActiveDistrict("schiebroek")}
              onMouseLeave={() =>
                activeDistrict === "schiebroek" && setActiveDistrict("")
              }
            >
              <rect
                x={483.68695}
                y={111.5576}
                width={28.62737}
                height={6.46868}
              />
              <rect
                x={479.63559}
                y={105.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={501.78387}
                y={105.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 498.22833 116.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Schiebroek"}
              </text>
              <text
                className="text schiebroek"
                transform="matrix(1 0 0 1 490.69308 109.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("schiebroek")}
              </text>
              <text
                className="difference schiebroek"
                transform="matrix(1 0 0 1 507.98538 109.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("schiebroek")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-kralingen-crooswijk" display="none">
          <polygon
            className="shape gebied gebied-kralingen-crooswijk"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points=" 536.28766,187.65446 534.14337,182.24843 602.14154,168.49174 623.08618,164.80719 628.15997,162.87431 641.29755,202.19638  647.06598,233.12248 645.8277,234.87416 642.26398,241.00502 645.58612,243.14931 640.05927,260.93787 640.02905,277.15594  640.72369,279.69287 642.95862,288.96466 656.00555,315.14917 648.21362,315.63239 641.59955,318.01828 641.14655,319.58875  633.20361,324.57196 630.48547,323.03171 625.7439,327.4411 621.90833,329.04175 610.91504,326.08203 605.96204,321.43103  605.99225,313.45789 602.3681,313.45789 601.43182,293.76666 592.16003,279.36066 590.64996,278.24319 580.41174,269.75665  564.79767,266.94794 559.57288,251.42447 558.12317,249.52179 556.43195,249.40099 530.88165,244.29697 531.90851,228.35069  532.24072,226.75002 536.3783,217.41782 541.84473,211.86079 542.59973,210.29031 536.80109,205.1259 532.51251,196.85075  "
          />
          <g
            className={`district struisenburg ${
              activeDistrict === "struisenburg" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("struisenburg")}
          >
            <polygon
              className="shape struisenburg"
              fill="#FFFFFF"
              points="584.55865,257.55801 561.64594,250.79236 559.60425,251.44705  564.82196,266.94254 580.24835,269.81128 580.47522,269.81128 590.6839,278.29132 594.54053,271.99371 598.62396,274.58112  603.16113,274.14462 596.36871,262.76254  "
            />
          </g>
          <g
            className={`district de-esch ${
              activeDistrict === "de-esch" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("de-esch")}
          >
            <polygon
              className="shape de-esch"
              fill="#FFFFFF"
              points="605.99695,321.44705 610.98779,326.11865 621.96204,329.05133  625.73358,327.42804 630.49762,323.06317 633.21997,324.59091 641.16003,319.57129 641.61377,318.04355 648.19281,315.64282  656.03644,315.16119 642.97504,289.01691 640.7124,279.63748 638.8374,282.07498 633.5874,283.32498 605.33746,276.13751  603.27496,275.63751 598.71246,276.20001 595.08746,274.13751 592.14996,279.38748 601.45972,293.81824 601.45972,294.03653  602.3692,313.46039 605.99695,313.46039  "
            />
          </g>
          <g
            className={`district kralingen-west ${
              activeDistrict === "kralingen-west" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kralingen-west")}
          >
            <polygon
              className="shape kralingen-west"
              fill="#FFFFFF"
              points="562.15002,248.73131 584.27496,255.85629 591.18121,240.76257  586.77496,236.63759 590.51959,226.44232 571.71252,215.35637 558.79065,234.01259 558.18127,249.57506  "
            />
          </g>
          <g
            className={`district oud-crooswijk ${
              activeDistrict === "oud-crooswijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oud-crooswijk")}
          >
            <polygon
              className="shape oud-crooswijk"
              fill="#FFFFFF"
              points="558.54065,231.59071 569.93127,214.24701 562.47815,203.09077  554.40002,214.76263 541.86884,211.85638 536.40009,217.38762 532.24384,226.7626 556.71252,226.62198  "
            />
          </g>
          <g
            className={`district nieuw-crooswijk ${
              activeDistrict === "nieuw-crooswijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("nieuw-crooswijk")}
          >
            <path
              className="shape nieuw-crooswijk"
              fill="#FFFFFF"
              d="M536.29749,187.63965c0.01257,0.17664-3.7724,9.21677-3.7724,9.21677 l4.34375,8.31248l5.75,5.09373l11.15619,2.5l7.625-11.03123l-5.53125-7.96873l14.625-11.81247l22.12494-10.49998l9.55225-2.96573 l-68.02972,13.78018L536.29749,187.63965z"
            />
          </g>
          <g
            className={`district kralingen-oost ${
              activeDistrict === "kralingen-oost" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kralingen-oost")}
          >
            <polygon
              className="shape kralingen-oost"
              fill="#FFFFFF"
              points="640.02582,261.26816 640.02582,261.0499 645.58398,243.1537  642.29443,240.97119 645.81073,234.89194 647.08612,233.16275 641.27356,202.12357 628.11578,162.87094 623.12488,164.80357  594.31372,172.44217 571.85468,182.91792 557.78937,194.04843 571.46252,213.26263 592.49884,225.47585 588.64221,236.16988  593.17944,240.31654 585.96246,256.45004 597.58746,261.54379 605.42987,274.58112 633.2124,281.51248 637.61865,280.57498  640.02582,277.20004 640.02582,261.48633  "
            />
          </g>
          <g
            className={`district rubroek ${
              activeDistrict === "rubroek" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("rubroek")}
          >
            <polygon
              className="shape rubroek"
              fill="#FFFFFF"
              points="530.92749,244.31232 556.52502,249.45006 557.36877,233.13759  555.65503,228.16214 531.90009,228.3876  "
            />
          </g>
          <g className="districten-kralingen-crooswijk">
            <g
              className="district-text kralingen-oost"
              onClick={() => onDistrictClicked("kralingen-oost")}
              onMouseEnter={() => setActiveDistrict("kralingen-oost")}
              onMouseLeave={() =>
                activeDistrict === "kralingen-oost" && setActiveDistrict("")
              }
            >
              <rect
                x={560.75916}
                y={239.5576}
                width={34.48294}
                height={6.46868}
              />
              <rect
                x={560.07153}
                y={233.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={582.21985}
                y={233.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 577.51349 244.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kralingen-West"}
              </text>
              <text
                className="text kralingen-west"
                transform="matrix(1 0 0 1 571.12903 237.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kralingen-west")}
              </text>
              <text
                className="difference kralingen-west"
                transform="matrix(1 0 0 1 588.42133 237.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kralingen-west")}
              </text>
            </g>
            <g
              className="district-text de-esch"
              onClick={() => onDistrictClicked("de-esch")}
              onMouseEnter={() => setActiveDistrict("de-esch")}
              onMouseLeave={() =>
                activeDistrict === "de-esch" && setActiveDistrict("")
              }
            >
              <rect
                x={618.8421}
                y={302.55759}
                width={18.31705}
                height={6.46868}
              />
              <rect
                x={610.07153}
                y={296.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={632.21985}
                y={296.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 627.83911 307.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"De Esch"}
              </text>
              <text
                className="text de-esch"
                transform="matrix(1 0 0 1 621.12903 300.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("de-esch")}
              </text>
              <text
                className="difference de-esch"
                transform="matrix(1 0 0 1 638.42133 300.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("de-esch")}
              </text>
            </g>
            <g
              className="district-text struisenburg"
              onClick={() => onDistrictClicked("struisenburg")}
              onMouseEnter={() => setActiveDistrict("struisenburg")}
              onMouseLeave={() =>
                activeDistrict === "struisenburg" && setActiveDistrict("")
              }
            >
              <rect
                x={552.41284}
                y={265.55759}
                width={27.17562}
                height={6.46868}
              />
              <rect
                x={548.07153}
                y={259.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={570.21985}
                y={259.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 566.4505 270.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Struisenburg"}
              </text>
              <text
                className="text struisenburg"
                transform="matrix(1 0 0 1 559.12903 263.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("struisenburg")}
              </text>
              <text
                className="difference struisenburg"
                transform="matrix(1 0 0 1 576.42133 263.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("struisenburg")}
              </text>
            </g>
            <g
              className="district-text oud-crooswijk"
              onClick={() => onDistrictClicked("oud-crooswijk")}
              onMouseEnter={() => setActiveDistrict("oud-crooswijk")}
              onMouseLeave={() =>
                activeDistrict === "oud-crooswijk" && setActiveDistrict("")
              }
            >
              <rect
                x={544.29828}
                y={217.5576}
                width={29.40473}
                height={6.46868}
              />
              <rect
                x={541.10773}
                y={211.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={563.25598}
                y={211.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 559.49371 222.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oud-Crooswijk"}
              </text>
              <text
                className="text oud-crooswijk"
                transform="matrix(1 0 0 1 552.16516 215.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oud-crooswijk")}
              </text>
              <text
                className="difference oud-crooswijk"
                transform="matrix(1 0 0 1 569.45746 215.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oud-crooswijk")}
              </text>
            </g>
            <g
              className="district-text nieuw-crooswijk"
              onClick={() => onDistrictClicked("nieuw-crooswijk")}
              onMouseEnter={() => setActiveDistrict("nieuw-crooswijk")}
              onMouseLeave={() =>
                activeDistrict === "nieuw-crooswijk" && setActiveDistrict("")
              }
            >
              <rect
                x={537.56311}
                y={185.5576}
                width={32.87506}
                height={6.46868}
              />
              <rect
                x={535.63562}
                y={179.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={557.78387}
                y={179.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 553.79462 190.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Nieuw-Crooswijk"}
              </text>
              <text
                className="text nieuw-crooswijk"
                transform="matrix(1 0 0 1 546.69312 183.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("nieuw-crooswijk")}
              </text>
              <text
                className="difference nieuw-crooswijk"
                transform="matrix(1 0 0 1 563.98535 183.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("nieuw-crooswijk")}
              </text>
            </g>
            <g
              className="district-text kralingen-oost"
              onClick={() => onDistrictClicked("kralingen-oost")}
              onMouseEnter={() => setActiveDistrict("kralingen-oost")}
              onMouseLeave={() =>
                activeDistrict === "kralingen-oost" && setActiveDistrict("")
              }
            >
              <rect
                x={586.78577}
                y={204.5576}
                width={30.42974}
                height={6.46868}
              />
              <rect
                x={583.99792}
                y={198.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={606.14624}
                y={198.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <rect
                x={601.99292}
                y={205.37038}
                fill="none"
                width={25.9463}
                height={4.97181}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 602.75488 208.36551)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kralingen-Oost"}
              </text>
              <text
                className="text kralingen-oost"
                transform="matrix(1 0 0 1 595.05542 202.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kralingen-oost")}
              </text>
              <text
                className="difference kralingen-oost"
                transform="matrix(1 0 0 1 612.34772 202.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kralingen-oost")}
              </text>
            </g>
            <g
              className="district-text rubroek"
              onClick={() => onDistrictClicked("rubroek")}
              onMouseEnter={() => setActiveDistrict("rubroek")}
              onMouseLeave={() =>
                activeDistrict === "rubroek" && setActiveDistrict("")
              }
            >
              <rect
                x={519.84717}
                y={242.5576}
                width={22.30688}
                height={6.46868}
              />
              <rect
                x={512.63562}
                y={236.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={534.78387}
                y={236.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 531.3465 247.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Rubroek"}
              </text>
              <text
                className="text rubroek"
                transform="matrix(1 0 0 1 523.69312 240.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("rubroek")}
              </text>
              <text
                className="difference rubroek"
                transform="matrix(1 0 0 1 540.98535 240.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("rubroek")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-prins-alexander" display="none">
          <polygon
            className="shape gebied gebied-prins-alexander"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points=" 774.40411,68.06603 784.15906,28.12713 774.85791,7.39379 695.91095,43.84088 714.51343,83.12505 713.56647,84.82989  692.06592,93.89458 690.22211,94.40942 683.64319,94.19115 671.16589,82.18761 655.7395,91.13567 645.98456,94.19115  626.70154,103.35748 619.62311,110.26366 630.33447,150.04883 627.43518,154.71997 629.42841,160.0354 630.1734,162.04883  642.81769,200.30377 643.22034,202.23663 648.69684,232.76007 648.21344,234.93457 644.96362,240.16589 647.93176,241.94122  641.56934,261.10901 641.6499,275.16266 641.71112,275.16046 642.05237,279.02844 643.98529,288.45123 660.29395,320.4646  671.28723,316.96124 677.08588,304.15588 691.8241,297.75323 686.62946,281.32373 684.33417,277.45795 677.93152,284.58545  675.63623,286.0351 669.23352,311.64581 659.20673,314.5451 645.31415,287.84723 643.74365,278.42438 643.78387,275.08673  643.82922,275.08514 643.80035,273.71906 643.80408,273.41101 643.79401,273.41821 643.54248,261.51166 645.55591,260.54523  649.04694,262.4585 668.55676,262.4585 690.56201,260.02625 700.09009,253.91541 706.44214,248.02277 709.3913,242.13007  706.44214,221.83325 706.039,220.11578 703.784,208.19635 703.49329,206.11963 700.3172,189.3147 725.15833,182.54907  727.427,181.0213 727.427,179.93011 713.47516,164.65289 703.72021,142.82837 702.89807,140.94818 701.11133,134.13  701.99658,130.8797 703.0556,127.21197 706.76056,123.67767 713.79327,129.57031 716.51562,129.57031 722.18707,127.16955  732.62256,120.62225 745.55347,111.2376 751.45172,105.56322 752.85266,104.51305 765.32996,95.78326 768.73285,95.56499  790.28448,83.77978  "
          />
          <g
            className={`district zevenkamp ${
              activeDistrict === "zevenkamp" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("zevenkamp")}
          >
            <path
              className="shape zevenkamp"
              fill="#FFFFFF"
              d="M742.96454,94.41102L741.6969,92.905l6.57898-7.42032l-8.16687-13.31302 l-14.06525,11.78528l-5.44476-0.21828l-7.03253,1.09126l-21.50061,9.06467l9.93073,36.98512l1.05896-3.66773 c0.10266-0.16269,3.70502-3.53431,3.70502-3.53431c0.22681-0.21828,7.03271,5.89264,7.03271,5.89264h2.72235l5.67145-2.40073 l10.43549-6.54734l0.22681-0.21828l12.70404-9.16634l5.89825-5.67436L742.96454,94.41102z"
            />
          </g>
          <g
            className={`district het-lage-land ${
              activeDistrict === "het-lage-land" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("het-lage-land")}
          >
            <path
              className="shape het-lage-land"
              fill="#FFFFFF"
              d="M647.23224,153.95901l-7.25952,3.05542c0,0-1.58795,3.2737-1.81488,3.2737 l-7.98444,1.76068l12.64429,38.25494l44.79541-10.77077l-10.66235-41.2484L647.23224,153.95901z"
            />
          </g>
          <g
            className={`district oosterflank ${
              activeDistrict === "oosterflank" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oosterflank")}
          >
            <path
              className="shape oosterflank"
              fill="#FFFFFF"
              d="M709.61853,156.57784l-5.89832-13.74945l-25.18146,5.23787l10.66241,41.68495 l6.35199,17.24136l2.72235,2.61899l5.21771-3.49191l-3.17603-16.80493c0-0.43654,14.97278-4.14668,14.97278-4.14668 l9.86835-2.61899l2.26862-1.52774v-1.0912l-13.95178-15.27719L709.61853,156.57784z"
            />
          </g>
          <g
            className={`district ommoord ${
              activeDistrict === "ommoord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("ommoord")}
          >
            <path
              className="shape ommoord"
              fill="#FFFFFF"
              d="M627.43512,154.71996l1.99329,5.31543l7.349-1.44966 c0,0,1.70599-2.92265,1.93286-3.14093l8.37585-3.302l30.12073-5.95973l25.69122-5.23488l-1.78674-6.81818l-10.88922-39.7206 c0,0-6.57892,0-6.57892-0.21828l-12.47729-12.00349L655.7395,91.1357l-9.75494,3.05543l-19.28302,9.16634l-7.07849,6.90621 l10.71143,39.78515C630.30914,150.18127,627.43512,154.71996,627.43512,154.71996z"
            />
          </g>
          <g
            className={`district nesselande ${
              activeDistrict === "nesselande" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("nesselande")}
          >
            <path
              className="shape nesselande"
              fill="#FFFFFF"
              d="M774.40411,68.06606l9.75494-39.93893l-9.30115-20.73334l-78.94702,36.44707 l18.60254,39.28417l6.35199-1.09119l4.4823,0.26345l15.34222-12.68453l9.78522,16.30869l-6.46307,6.82549l8.84058,11.76614 l12.47723-8.72979c0.22687,0,0.22687-0.21828,0.45374-0.21828h2.94922l21.55157-11.78522l-15.65344-15.05903 C774.40411,68.50249,774.40411,68.28427,774.40411,68.06606z"
            />
          </g>
          <g
            className={`district prinsenland ${
              activeDistrict === "prinsenland" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("prinsenland")}
          >
            <polygon
              className="shape prinsenland"
              fill="#FFFFFF"
              points="706.039,220.1158 703.784,208.19637 698.22693,211.73997  693.96515,207.86555 687.99878,191.44472 643.22034,202.23665 648.69684,232.76007  "
            />
          </g>
          <g
            className={`district s-gravenland ${
              activeDistrict === "s-gravenland" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("s-gravenland")}
          >
            <polygon
              className="shape s-gravenland"
              fill="#FFFFFF"
              points="706.44214,221.83327 648.81982,234.92801 648.13971,235.14629  644.96362,240.16588 647.93176,241.94127 641.56934,261.10901 641.6499,275.16266 643.82916,275.08517 643.48901,263.73639  643.48901,263.5181 643.54248,261.51169 645.55591,260.54526 649.04688,262.4585 668.55676,262.4585 684.6637,260.89923  690.56201,260.02625 698.95581,254.78838 700.09009,253.91539 706.44214,248.02275 709.39124,242.13007 708.03015,231.65431  "
            />
          </g>
          <g
            className={`district kralingseveer ${
              activeDistrict === "kralingseveer" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kralingseveer")}
          >
            <polygon
              className="shape kralingseveer"
              fill="#FFFFFF"
              points="642.05237,279.02844 643.98523,288.45123 660.29395,320.4646  671.28723,316.96124 677.08588,304.15591 691.8241,297.75323 686.62946,281.32373 684.33417,277.45798 677.93152,284.58548  675.63623,286.03513 669.23352,311.64581 659.20673,314.54514 645.31409,287.8472 643.74365,278.42441  "
            />
          </g>
          <g className="districten-prins-alexander">
            <g
              className="district-text het-lage-land"
              onClick={() => onDistrictClicked("het-lage-land")}
              onMouseEnter={() => setActiveDistrict("het-lage-land")}
              onMouseLeave={() =>
                activeDistrict === "het-lage-land" && setActiveDistrict("")
              }
            >
              <rect
                x={647.75916}
                y={169.5576}
                width={34.48294}
                height={6.46868}
              />
              <rect
                x={647.07153}
                y={163.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={669.21985}
                y={163.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 665.18201 174.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Het Lage Land"}
              </text>
              <text
                className="text het-lage-land"
                transform="matrix(1 0 0 1 658.12903 167.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("het-lage-land")}
              </text>
              <text
                className="difference het-lage-land"
                transform="matrix(1 0 0 1 675.42133 167.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("het-lage-land")}
              </text>
            </g>
            <g
              className="district-text zevenkamp"
              onClick={() => onDistrictClicked("zevenkamp")}
              onMouseEnter={() => setActiveDistrict("zevenkamp")}
              onMouseLeave={() =>
                activeDistrict === "zevenkamp" && setActiveDistrict("")
              }
            >
              <rect
                x={710.44696}
                y={113.5576}
                width={27.10734}
                height={6.46868}
              />
              <rect
                x={706.07153}
                y={107.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={728.21985}
                y={107.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 724.49518 118.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Zevenkamp"}
              </text>
              <text
                className="text zevenkamp"
                transform="matrix(1 0 0 1 717.12903 111.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("zevenkamp")}
              </text>
              <text
                className="difference zevenkamp"
                transform="matrix(1 0 0 1 734.42133 111.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("zevenkamp")}
              </text>
            </g>
            <g
              className="district-text oosterflank"
              onClick={() => onDistrictClicked("oosterflank")}
              onMouseEnter={() => setActiveDistrict("oosterflank")}
              onMouseLeave={() =>
                activeDistrict === "oosterflank" && setActiveDistrict("")
              }
            >
              <rect
                x={696.38177}
                y={153.5576}
                width={23.23774}
                height={6.46868}
              />
              <rect
                x={690.07153}
                y={147.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={712.21985}
                y={147.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 707.95093 158.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oosterflank"}
              </text>
              <text
                className="text oosterflank"
                transform="matrix(1 0 0 1 701.12903 151.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oosterflank")}
              </text>
              <text
                className="difference oosterflank"
                transform="matrix(1 0 0 1 718.42133 151.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oosterflank")}
              </text>
            </g>
            <g
              className="district-text ommoord"
              onClick={() => onDistrictClicked("ommoord")}
              onMouseEnter={() => setActiveDistrict("ommoord")}
              onMouseLeave={() =>
                activeDistrict === "ommoord" && setActiveDistrict("")
              }
            >
              <rect
                x={644.17322}
                y={115.5576}
                width={23.65482}
                height={6.46868}
              />
              <rect
                x={638.07153}
                y={109.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={660.21985}
                y={109.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 656.38788 120.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Ommoord"}
              </text>
              <text
                className="text ommoord"
                transform="matrix(1 0 0 1 649.12903 113.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("ommoord")}
              </text>
              <text
                className="difference ommoord"
                transform="matrix(1 0 0 1 666.42133 113.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("ommoord")}
              </text>
            </g>
            <g
              className="district-text nesselande"
              onClick={() => onDistrictClicked("nesselande")}
              onMouseEnter={() => setActiveDistrict("nesselande")}
              onMouseLeave={() =>
                activeDistrict === "nesselande" && setActiveDistrict("")
              }
            >
              <rect
                x={733.06018}
                y={53.5576}
                width={25.88092}
                height={6.46868}
              />
              <rect
                x={728.10773}
                y={47.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={750.25598}
                y={47.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 746.45557 58.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Nesselande"}
              </text>
              <text
                className="text nesselande"
                transform="matrix(1 0 0 1 739.16522 51.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("nesselande")}
              </text>
              <text
                className="difference nesselande"
                transform="matrix(1 0 0 1 756.45746 51.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("nesselande")}
              </text>
            </g>
            <g
              className="district-text prinsenland"
              onClick={() => onDistrictClicked("prinsenland")}
              onMouseEnter={() => setActiveDistrict("prinsenland")}
              onMouseLeave={() =>
                activeDistrict === "prinsenland" && setActiveDistrict("")
              }
            >
              <rect
                x={670.77374}
                y={210.5576}
                width={24.45375}
                height={6.46868}
              />
              <rect
                x={664.63562}
                y={204.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={686.78387}
                y={204.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 683.03162 215.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Prinsenland"}
              </text>
              <text
                className="text prinsenland"
                transform="matrix(1 0 0 1 675.69312 208.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("prinsenland")}
              </text>
              <text
                className="difference prinsenland"
                transform="matrix(1 0 0 1 692.98535 208.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("prinsenland")}
              </text>
            </g>
            <g
              className="district-text s-gravenland"
              onClick={() => onDistrictClicked("s-gravenland")}
              onMouseEnter={() => setActiveDistrict("s-gravenland")}
              onMouseLeave={() =>
                activeDistrict === "s-gravenland" && setActiveDistrict("")
              }
            >
              <rect
                x={677.28125}
                y={245.61577}
                width={28.96186}
                height={6.46868}
              />
              <rect
                x={673.75946}
                y={239.14648}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={695.90778}
                y={239.14555}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 691.89142 250.10776)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"s-Gravenland"}
              </text>
              <text
                className="text s-gravenland"
                transform="matrix(1 0 0 1 684.81696 243.77611)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("s-gravenland")}
              </text>
              <text
                className="difference s-gravenland"
                transform="matrix(1 0 0 1 702.10925 243.6152)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("s-gravenland")}
              </text>
            </g>
            <g
              className="district-text kralingseveer"
              onClick={() => onDistrictClicked("kralingseveer")}
              onMouseEnter={() => setActiveDistrict("kralingseveer")}
              onMouseLeave={() =>
                activeDistrict === "kralingseveer" && setActiveDistrict("")
              }
            >
              <rect
                x={687.21875}
                y={297.55759}
                width={27.56376}
                height={6.46868}
              />
              <rect
                x={682.63562}
                y={291.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={704.78387}
                y={291.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 701.05084 302.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kralingseveer"}
              </text>
              <text
                className="text kralingseveer"
                transform="matrix(1 0 0 1 693.69312 295.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kralingseveer")}
              </text>
              <text
                className="difference kralingseveer"
                transform="matrix(1 0 0 1 710.98541 295.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kralingseveer")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-overschie" display="none">
          <path
            className="shape gebied gebied-overschie"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            d="M479.97235,107.64623 l-9.10065-8.73822l-22.26837,14.73822l-21.8255-24.604l-3.38251-5.55701l-16.06708-18.84564l-44.17444,30.32208l-9.78522,6.32214 l-20.69794,11.35571l-3.10065,0.64429l-2.97986-9.34229h-2.45636l-24.76508,6.48322l20.17444,55.48981l34.6308,1.28864 l7.1275-1.08728l9.26172-5.55701l0.84564,1.44965l5.43628,9.26172l1.57043,16.02679l3.86578,11.11407l8.85901,12.04028 l0.92621,1.32886l21.38251,26.4563l30.40259-25.24829l1.5705-0.92615l9.50336-6.76508l7.97308-8.33557l14.81879-4.26843 l-1.651-25.36908L479.97235,107.64623z M392.71082,146.26361l-1.20807-0.06042l-11.95972-17.93951l-1.63086-7.24836 l29.83887-18.60394l14.255-1.93292l14.13422,21.08051L392.71082,146.26361z"
          />
          <g
            className={`district kleinpolder ${
              activeDistrict === "kleinpolder" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kleinpolder")}
          >
            <polygon
              className="shape kleinpolder"
              fill="#FFFFFF"
              points="443.89188,212.94803 440.95691,209.98044 431.65567,197.10391  419.8114,196.1964 410.46915,198.04874 392.10675,211.82053 413.44901,238.23657  "
            />
          </g>
          <g
            className={`district schieveen ${
              activeDistrict === "schieveen" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("schieveen")}
          >
            <path
              className="shape schieveen"
              fill="#FFFFFF"
              d="M361.32925,166.11281l9.52811-5.67436L392.409,147.7802l-0.90744-0.87292 l-12.3638-18.33261l-1.70148-7.6386c0-0.21828,30.1723-19.17395,30.1723-19.17395l14.74582-1.99588 c0.2269,0,14.74582,22.04286,14.74582,22.04286l-44.23752,25.09836l0.90744,0.87297l12.931,22.26108l-3.11789,1.71198 l4.1879,6.24161l6.604,5.15434l7.32886,11.19461l20.16031,1.88695l3.06259,7.6386l-2.56522,4.89053l3.06039,3.30202 l9.42279-6.68456l8.13422-8.45636l14.7785-4.26845l-1.69128-25.36908l3.98657-59.55692l-9.15063-8.8334l-22.23218,14.84064 l-21.89191-24.6618l-3.28952-5.45615l-16.10703-18.98738L363.1441,94.96485l-9.75491,6.32907l-20.6442,11.34879l-3.17603,0.65476 l-2.94913-9.35284h-2.49548l-24.72763,6.51557l20.1904,55.43439l34.70947,1.30948L361.32925,166.11281z"
            />
          </g>
          <g
            className={`district overschie ${
              activeDistrict === "overschie" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("overschie")}
          >
            <polygon
              className="shape overschie"
              fill="#FFFFFF"
              points="433.70398,197.16283 440.91202,207.47153 442.9657,203.72658  440.4288,197.68633  "
            />
            <polygon
              className="shape overschie"
              fill="#FFFFFF"
              points="382.31384,198.44498 391.16135,210.44847 409.31006,196.69896  420.05301,194.62592 413.08658,184.15614 406.52283,179.16287 401.16714,171.02866 404.26779,169.45819 392.75104,149.48508  371.42459,161.99789 376.86917,171.38245 378.45721,187.31447 381.63324,196.69896  "
            />
          </g>
          <g className="districten-overschie">
            <g
              className="district-text schieveen"
              onClick={() => onDistrictClicked("schieveen")}
              onMouseEnter={() => setActiveDistrict("schieveen")}
              onMouseLeave={() =>
                activeDistrict === "schieveen" && setActiveDistrict("")
              }
            >
              <rect
                x={349.93991}
                y={113.5576}
                width={22.12141}
                height={6.46868}
              />
              <rect
                x={342.63559}
                y={107.08831}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={364.78387}
                y={107.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 361.39255 118.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Schieveen"}
              </text>
              <text
                className="text schieveen"
                transform="matrix(1 0 0 1 353.69308 111.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("schieveen")}
              </text>
              <text
                className="difference schieveen"
                transform="matrix(1 0 0 1 370.98538 111.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("schieveen")}
              </text>
            </g>
            <g
              className="district-text kleinpolder"
              onClick={() => onDistrictClicked("kleinpolder")}
              onMouseEnter={() => setActiveDistrict("kleinpolder")}
              onMouseLeave={() =>
                activeDistrict === "kleinpolder" && setActiveDistrict("")
              }
            >
              <rect
                x={397.03314}
                y={210.5576}
                width={23.93493}
                height={6.46868}
              />
              <rect
                x={390.63559}
                y={204.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={412.78387}
                y={204.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 409.25775 215.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kleinpolder"}
              </text>
              <text
                className="text kleinpolder"
                transform="matrix(1 0 0 1 401.69308 208.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kleinpolder")}
              </text>
              <text
                className="difference kleinpolder"
                transform="matrix(1 0 0 1 418.98538 208.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kleinpolder")}
              </text>
            </g>
            <g
              className="district-text overschie"
              onClick={() => onDistrictClicked("overschie")}
              onMouseEnter={() => setActiveDistrict("overschie")}
              onMouseLeave={() =>
                activeDistrict === "overschie" && setActiveDistrict("")
              }
            >
              <rect
                x={380.38989}
                y={172.5576}
                width={21.22147}
                height={6.46868}
              />
              <rect
                x={372.63559}
                y={166.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={394.78387}
                y={166.08739}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 390.94186 177.04958)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Overschie"}
              </text>
              <text
                className="text overschie"
                transform="matrix(1 0 0 1 383.69308 170.71794)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("overschie")}
              </text>
              <text
                className="difference overschie"
                transform="matrix(1 0 0 1 400.98538 170.55705)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("overschie")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-feijenoord" display="none">
          <polygon
            className="shape gebied gebied-feijenoord"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="610.19385,384.04205  609.51331,382.51447 597.98743,361.88507 593.77258,359.19373 588.27765,352.31116 588.00568,348.34375 587.6366,343.94104  589.32782,343.21619 589.56946,339.10883 578.21381,320.86725 580.14667,319.05518 589.20703,332.10211 589.08624,315.67261  584.13324,309.63239 584.37482,307.21625 576.52252,300.08875 576.88495,312.77332 572.77753,309.02838 572.17352,297.79346  568.54938,298.39752 562.02588,304.43774 561.85931,304.62097 559.80328,301.96808 572.91058,291.29285 565.77667,289.27631  552.78143,295.08197 537.04028,312.13879 542.46625,315.21991 549.85211,310.11823 558.60681,302.97076 561.01898,305.54535  560.81781,305.7666 553.32794,313.73975 571.8111,328.35718 568.9118,331.01489 551.28094,318.62573 550.91852,320.67938  557.45648,326.01642 557.07288,327.75317 555.14001,330.04846 543.42194,322.43774 528.32129,333.91418 530.37494,335.9679  539.79779,329.20282 551.75745,333.55182 551.75745,336.20953 551.87823,337.77997 542.81787,344.90747 512.375,346.47797  497.03278,355.1759 505.60992,361.21619 545.83801,349.73969 547.28766,354.20947 551.27423,357.22961 553.19843,357.56476  552.60309,361.82019 552.23126,363.96356 548.82843,374.43933 549.28217,389.71649 549.38159,392.10199 546.67316,394.73615  546.21942,406.52136 558.01617,413.06879 575.25745,431.40137 591.59131,415.46948 609.07275,413.72614 611.55493,401.93823  618.36078,400.41046  "
          />
          <g
            className={`district afrikaanderwijk ${
              activeDistrict === "afrikaanderwijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("afrikaanderwijk")}
          >
            <polygon
              className="shape afrikaanderwijk"
              fill="#FFFFFF"
              points="567.90509,359.16251 581.1936,356.7464 573.66949,347.37692  557.5625,336.46463 556.20135,340.1748 552.60309,361.82019  "
            />
          </g>
          <g
            className={`district hillesluis ${
              activeDistrict === "hillesluis" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("hillesluis")}
          >
            <path
              className="shape hillesluis"
              fill="#FFFFFF"
              d="M582.7439,358.28915l-14.06531,2.61899l-0.68054,2.40073l15.65332,13.31302 c0.22681,0.21823,0.22681,0.43649,0.22681,0.65469l-3.62976,11.78522l23.82025-3.71017l5.44464-2.83716l-11.52594-20.62939 l-4.21478-2.69135l-5.495-6.88257l-5.07996,5.7598C583.19763,358.28915,582.97076,358.28915,582.7439,358.28915z"
            />
          </g>
          <g
            className={`district vreewijk ${
              activeDistrict === "vreewijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("vreewijk")}
          >
            <polygon
              className="shape vreewijk"
              fill="#FFFFFF"
              points="549.38159,392.10202 546.67316,394.73618 546.21936,406.52139  558.01611,413.06879 575.25745,431.4014 591.59131,415.46951 609.07275,413.72617 611.55493,401.93823 618.36078,400.41049  610.19385,384.04208 604.71033,386.94766 579.09967,391.21613  "
            />
          </g>
          <g
            className={`district bloemhof ${
              activeDistrict === "bloemhof" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("bloemhof")}
          >
            <path
              className="shape bloemhof"
              fill="#FFFFFF"
              d="M578.32007,389.49829l3.62976-12.00354l-15.6532-13.31296 c-0.22693-0.21826-0.22693-0.43655-0.22693-0.65469l0.68054-2.18253l-14.51898,2.61902l-3.40283,10.47574l0.45374,15.27719 v0.43649v0.2182L578.32007,389.49829z"
            />
          </g>
          <g
            className={`district katendrecht ${
              activeDistrict === "katendrecht" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("katendrecht")}
          >
            <polygon
              className="shape katendrecht"
              fill="#FFFFFF"
              points="505.60989,361.21619 545.83801,349.73969 547.28766,354.20947  551.27423,357.22961 554.18024,338.80017 551.87823,337.78 542.81787,344.9075 512.375,346.47797 497.03271,355.17593  "
            />
          </g>
          <g
            className={`district kop-van-zuid ${
              activeDistrict === "kop-van-zuid" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kop-van-zuid")}
          >
            <polygon
              className="shape kop-van-zuid"
              fill="#FFFFFF"
              points="551.75745,333.55182 551.75745,336.2095 554.66345,337.22971  555.99231,332.88074 558.89166,329.01498 557.07288,327.75317 555.14001,330.04846 543.42188,322.43774 528.32123,333.91422  530.37494,335.9679 539.79773,329.20282  "
            />
          </g>
          <g
            className={`district noordereiland ${
              activeDistrict === "noordereiland" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("noordereiland")}
          >
            <polygon
              className="shape noordereiland"
              fill="#FFFFFF"
              points="537.04028,312.13882 542.46625,315.21994 549.85211,310.11823  572.91058,291.29288 565.77667,289.27631 552.78143,295.08197  "
            />
          </g>
          <g
            className={`district feijenoord ${
              activeDistrict === "feijenoord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("feijenoord")}
          >
            <polygon
              className="shape feijenoord"
              fill="#FFFFFF"
              points="589.32782,343.21622 589.56946,339.10883 578.21375,320.86728  580.14667,319.05518 589.20703,332.10214 589.08624,315.67264 584.13324,309.63239 584.37482,307.21628 576.52252,300.08878  576.88489,312.77332 572.77753,309.02835 572.17352,297.79349 568.54938,298.39752 562.02588,304.43777  "
            />
          </g>
          <g
            className={`district kop-van-zuid-entrepot ${
              activeDistrict === "kop-van-zuid-entrepot" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("kop-van-zuid-entrepot")}
          >
            <polygon
              className="shape kop-van-zuid-entrepot"
              fill="#FFFFFF"
              points="560.81781,305.76663 587.63654,343.94104 588.00568,348.34378  582.44867,355.22968 574.46881,345.51151 557.79773,334.15582 560.94531,328.53174 550.91852,320.67941 551.28094,318.62573  568.91174,331.01489 571.8111,328.35718 553.32788,313.73978  "
            />
          </g>
          <g className="districten-feijenoord">
            <g
              className="district-text afrikaanderwijk"
              onClick={() => onDistrictClicked("afrikaanderwijk")}
              onMouseEnter={() => setActiveDistrict("afrikaanderwijk")}
              onMouseLeave={() =>
                activeDistrict === "afrikaanderwijk" && setActiveDistrict("")
              }
            >
              <rect
                x={547.68707}
                y={350.55759}
                width={30.62713}
                height={6.46868}
              />
              <rect
                x={545.07153}
                y={344.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={567.21985}
                y={344.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 563.04089 355.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Afrikaanderwijk"}
              </text>
              <text
                className="text afrikaanderwijk"
                transform="matrix(1 0 0 1 556.12903 348.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("afrikaanderwijk")}
              </text>
              <text
                className="difference afrikaanderwijk"
                transform="matrix(1 0 0 1 573.42133 348.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("afrikaanderwijk")}
              </text>
            </g>
            <g
              className="district-text hillesluis"
              onClick={() => onDistrictClicked("hillesluis")}
              onMouseEnter={() => setActiveDistrict("hillesluis")}
              onMouseLeave={() =>
                activeDistrict === "hillesluis" && setActiveDistrict("")
              }
            >
              <rect
                x={577.81329}
                y={369.55759}
                width={20.37463}
                height={6.46868}
              />
              <rect
                x={570.07153}
                y={363.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={592.21985}
                y={363.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 588.04089 374.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Hillesluis"}
              </text>
              <text
                className="text hillesluis"
                transform="matrix(1 0 0 1 581.12903 367.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("hillesluis")}
              </text>
              <text
                className="difference hillesluis"
                transform="matrix(1 0 0 1 598.42133 367.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("hillesluis")}
              </text>
            </g>
            <g
              className="district-text vreewijk"
              onClick={() => onDistrictClicked("vreewijk")}
              onMouseEnter={() => setActiveDistrict("vreewijk")}
              onMouseLeave={() =>
                activeDistrict === "vreewijk" && setActiveDistrict("")
              }
            >
              <rect
                x={572.87762}
                y={403.55759}
                width={22.24605}
                height={6.46868}
              />
              <rect
                x={566.07153}
                y={397.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={588.21985}
                y={397.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 585.0274 408.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Vreewijk"}
              </text>
              <text
                className="text carnisse"
                transform="matrix(1 0 0 1 577.12903 401.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("carnisse")}
              </text>
              <text
                className="difference vreewijk"
                transform="matrix(1 0 0 1 594.42133 401.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("vreewijk")}
              </text>
            </g>
            <g
              className="district-text bloemhof"
              onClick={() => onDistrictClicked("bloemhof")}
              onMouseEnter={() => setActiveDistrict("bloemhof")}
              onMouseLeave={() =>
                activeDistrict === "bloemhof" && setActiveDistrict("")
              }
            >
              <rect
                x={548.43451}
                y={376.55759}
                width={21.13223}
                height={6.46868}
              />
              <rect
                x={541.07153}
                y={370.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={563.21985}
                y={370.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 559.1673 381.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Bloemhof"}
              </text>
              <text
                className="text bloemhof"
                transform="matrix(1 0 0 1 552.12903 374.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("bloemhof")}
              </text>
              <text
                className="difference bloemhof"
                transform="matrix(1 0 0 1 569.42133 374.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("bloemhof")}
              </text>
            </g>
            <g
              className="district-text katendrecht"
              onClick={() => onDistrictClicked("katendrecht")}
              onMouseEnter={() => setActiveDistrict("katendrecht")}
              onMouseLeave={() =>
                activeDistrict === "katendrecht" && setActiveDistrict("")
              }
            >
              <rect
                x={512.04858}
                y={348.55759}
                width={25.90414}
                height={6.46868}
              />
              <rect
                x={507.07153}
                y={342.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={529.21985}
                y={342.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 525.20514 353.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Katendrecht"}
              </text>
              <text
                className="text katendrecht"
                transform="matrix(1 0 0 1 518.12903 346.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("katendrecht")}
              </text>
              <text
                className="difference katendrecht"
                transform="matrix(1 0 0 1 535.42133 346.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("katendrecht")}
              </text>
            </g>
            <g
              className="district-text kop-van-zuid"
              onClick={() => onDistrictClicked("kop-van-zuid")}
              onMouseEnter={() => setActiveDistrict("kop-van-zuid")}
              onMouseLeave={() =>
                activeDistrict === "kop-van-zuid" && setActiveDistrict("")
              }
            >
              <rect
                x={525.06018}
                y={330.55759}
                width={25.88092}
                height={6.46868}
              />
              <rect
                x={520.10773}
                y={324.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={542.25598}
                y={324.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 537.48895 335.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kop van Zuid"}
              </text>
              <text
                className="text kop-van-zuid"
                transform="matrix(1 0 0 1 531.16522 328.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kop-van-zuid")}
              </text>
              <text
                className="difference kop-van-zuid"
                transform="matrix(1 0 0 1 548.45746 328.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kop-van-zuid")}
              </text>
            </g>
            <g
              className="district-text noordereiland"
              onClick={() => onDistrictClicked("noordereiland")}
              onMouseEnter={() => setActiveDistrict("noordereiland")}
              onMouseLeave={() =>
                activeDistrict === "noordereiland" && setActiveDistrict("")
              }
            >
              <rect
                x={538.38177}
                y={299.55759}
                width={27.23773}
                height={6.46868}
              />
              <rect
                x={533.63562}
                y={293.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={555.78387}
                y={293.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 551.68445 304.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Noordereiland"}
              </text>
              <text
                className="text noordereiland"
                transform="matrix(1 0 0 1 544.69312 297.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("noordereiland")}
              </text>
              <text
                className="difference noordereiland"
                transform="matrix(1 0 0 1 561.98535 297.55707)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("noordereiland")}
              </text>
            </g>
            <g
              className="district-text feijenoord"
              onClick={() => onDistrictClicked("feijenoord")}
              onMouseEnter={() => setActiveDistrict("feijenoord")}
              onMouseLeave={() =>
                activeDistrict === "feijenoord" && setActiveDistrict("")
              }
            >
              <rect
                x={571.57532}
                y={318.61575}
                width={22.37366}
                height={6.46868}
              />
              <rect
                x={564.75946}
                y={312.14648}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={586.90778}
                y={312.14557}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 582.69891 323.10776)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Feijenoord"}
              </text>
              <text
                className="text feijenoord"
                transform="matrix(1 0 0 1 575.81696 316.77612)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("feijenoord")}
              </text>
              <text
                className="difference feijenoord"
                transform="matrix(1 0 0 1 593.10925 316.6152)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("feijenoord")}
              </text>
            </g>
            <g
              className="district-text kop-van-zuid-entrepot"
              onClick={() => onDistrictClicked("kop-van-zuid-entrepot")}
              onMouseEnter={() => setActiveDistrict("kop-van-zuid-entrepot")}
              onMouseLeave={() =>
                activeDistrict === "kop-van-zuid-entrepot" &&
                setActiveDistrict("")
              }
            >
              <rect
                x={555.63049}
                y={336.55759}
                width={42.74031}
                height={6.46868}
              />
              <rect
                x={558.63562}
                y={330.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={580.78387}
                y={330.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 577.01367 341.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Kop van Zuid - Entrepot"}
              </text>
              <text
                className="text kop-van-zuid-entrepot"
                transform="matrix(1 0 0 1 569.69312 334.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("kop-van-zuid-entrepot")}
              </text>
              <text
                className="difference kop-van-zuid-entrepot"
                transform="matrix(1 0 0 1 586.98541 334.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("kop-van-zuid-entrepot")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-ijsselmonde" display="none">
          <polyline
            className="shape gebied gebied-ijsselmonde"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="727.96185,363.24033  715.30072,427.41739 714.39319,429.8483 696.0177,429.8483 670.1557,442.28833 658.81268,451.45465 651.19348,458.66565  649.62524,459.78366 640.77765,461.96619 638.50903,462.40268 595.8595,462.40268 594.27142,452.58163 587.0119,440.79636  576.34949,433.15775 592.45648,417.66235 610.76404,415.33688 612.87384,403.91278 618.77246,402.1669 620.7951,401.16144  611.85126,382.92084 610.84454,381.26984 601.36487,364.01071 604.32733,365.90237 620.27765,364.1564 662.65967,349.53394  665.39398,351.49811 666.07758,349.97037 676.78705,348.0062 677.47064,353.6806 682.48352,354.77182 682.25574,348.44269  695.50885,352.77997 709.76385,354.04843 710.24707,356.10211 722.93158,357.73297 724.18219,354.1171 728.72546,357.45334  728.0658,361.35712 727.96185,363.24033  "
          />
          <g
            className={`district beverwaard ${
              activeDistrict === "beverwaard" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("beverwaard")}
          >
            <polygon
              className="shape beverwaard"
              fill="#FFFFFF"
              points="686.62964,365.34369 692.9115,368.06183 697.8645,374.40408  708.16724,412.2388 715.30072,427.41739 727.96185,363.24033 688.81738,355.32349  "
            />
          </g>
          <g
            className={`district lombardijen ${
              activeDistrict === "lombardijen" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("lombardijen")}
          >
            <polygon
              className="shape lombardijen"
              fill="#FFFFFF"
              points="612.87384,403.91278 610.76404,415.33688 592.45648,417.66235  576.34949,433.15775 586.78503,440.57812 587.0119,440.79636 594.27142,452.58163 594.27142,452.7999 595.8595,462.40268  638.50903,462.40268 640.77765,461.96619 649.62524,459.78366 618.77246,402.1669  "
            />
          </g>
          <g
            className={`district oud-ijsselmonde ${
              activeDistrict === "oud-ijsselmonde" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oud-ijsselmonde")}
          >
            <path
              className="shape oud-ijsselmonde"
              fill="#FFFFFF"
              d="M631.70361,370.55844l43.69116-6.90601l9.06036,0.96643l2.59735-11.41608 c0.22675,0,41.01331,8.15433,41.01331,8.15433l0.65967-3.90378l-4.54327-3.33624l-1.25061,3.61588l-12.68451-1.63086 l-0.48322-2.05368l-14.255-1.26846l-13.25311-4.33728l0.22778,6.32913l-5.01288-1.09122l-0.68359-5.67441l-10.70947,1.96417 l-0.68359,1.52774l-2.73431-1.96417l-42.38202,14.62247l-15.95032,1.74597l-2.96246-1.89166l9.47968,17.25912 L631.70361,370.55844z"
            />
          </g>
          <g
            className={`district groot-ijsselmonde ${
              activeDistrict === "groot-ijsselmonde" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("groot-ijsselmonde")}
          >
            <polygon
              className="shape groot-ijsselmonde"
              fill="#FFFFFF"
              points="706.50208,413.30334 704.60822,406.53143 704.15125,404.37976  696.11035,374.9101 691.57318,369.45392 685.67487,366.6167 675.69299,365.52545 632.58978,372.29114 611.85126,382.92084  621.51569,402.61206 622.1861,404.33575 622.28906,404.53174 622.28839,404.5318 622.33173,404.61292 622.42639,404.79315  622.42786,404.79303 651.19348,458.66565 658.81268,451.45465 670.1557,442.28833 696.0177,429.84827 696.24445,429.84827  714.39319,429.84827  "
            />
          </g>
          <g className="districten-ijsselmonde">
            <g
              className="district-text beverwaard"
              onClick={() => onDistrictClicked("beverwaard")}
              onMouseEnter={() => setActiveDistrict("beverwaard")}
              onMouseLeave={() =>
                activeDistrict === "beverwaard" && setActiveDistrict("")
              }
            >
              <rect
                x={713.65808}
                y={375.55759}
                width={24.68511}
                height={6.46868}
              />
              <rect
                x={708.07153}
                y={369.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={730.21985}
                y={369.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 725.78107 380.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Beverwaard"}
              </text>
              <text
                className="text beverwaard"
                transform="matrix(1 0 0 1 719.12903 373.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("beverwaard")}
              </text>
              <text
                className="difference beverwaard"
                transform="matrix(1 0 0 1 736.42133 373.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("beverwaard")}
              </text>
            </g>
            <g
              className="district-text lombardijen"
              onClick={() => onDistrictClicked("lombardijen")}
              onMouseEnter={() => setActiveDistrict("lombardijen")}
              onMouseLeave={() =>
                activeDistrict === "lombardijen" && setActiveDistrict("")
              }
            >
              <rect
                x={613.06018}
                y={443.55759}
                width={25.88092}
                height={6.46868}
              />
              <rect
                x={608.10773}
                y={437.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={630.25598}
                y={437.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 625.8208 448.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Lombardijen"}
              </text>
              <text
                className="text lombardijen"
                transform="matrix(1 0 0 1 619.16522 441.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("lombardijen")}
              </text>
              <text
                className="difference lombardijen"
                transform="matrix(1 0 0 1 636.45746 441.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("lombardijen")}
              </text>
            </g>
            <g
              className="district-text oud-ijsselmonde"
              onClick={() => onDistrictClicked("oud-ijsselmonde")}
              onMouseEnter={() => setActiveDistrict("oud-ijsselmonde")}
              onMouseLeave={() =>
                activeDistrict === "oud-ijsselmonde" && setActiveDistrict("")
              }
            >
              <rect
                x={626.0863}
                y={360.55759}
                width={33.82869}
                height={6.46868}
              />
              <rect
                x={624.63562}
                y={354.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={646.78387}
                y={354.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 643.23517 365.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oud-IJsselmonde"}
              </text>
              <text
                className="text oud-ijsselmonde"
                transform="matrix(1 0 0 1 635.69312 358.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oud-ijsselmonde")}
              </text>
              <text
                className="difference oud-ijsselmonde"
                transform="matrix(1 0 0 1 652.98535 358.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oud-ijsselmonde")}
              </text>
            </g>
            <g
              className="district-text groot-ijsselmonde"
              onClick={() => onDistrictClicked("groot-ijsselmonde")}
              onMouseEnter={() => setActiveDistrict("groot-ijsselmonde")}
              onMouseLeave={() =>
                activeDistrict === "groot-ijsselmonde" && setActiveDistrict("")
              }
            >
              <rect
                x={654.27362}
                y={402.61575}
                width={35.29007}
                height={6.46868}
              />
              <rect
                x={653.75946}
                y={396.14648}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={675.90778}
                y={396.14557}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 672.44586 407.10776)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Groot-IJsselmonde"}
              </text>
              <text
                className="text groot-ijsselmonde"
                transform="matrix(1 0 0 1 664.81696 400.77612)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("groot-ijsselmonde")}
              </text>
              <text
                className="difference groot-ijsselmonde"
                transform="matrix(1 0 0 1 682.10925 400.61523)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("groot-ijsselmonde")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-charlois" display="none">
          <polyline
            className="shape gebied gebied-charlois"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="550.55707,359.32553  547.22052,356.78668 547.2085,359.89127 545.36816,364.51819 510.10114,374.83954 507.27429,375.79333 501.35233,377.93375  484.32971,362.95139 472.13779,363.17169 471.995,369.05096 476.07346,371.51239 464.73669,403.01947 470.16635,405.95175  469.90524,408.28992 461.57043,429.47229 463.10956,429.89539 465.24921,445.11163 457.78149,456.16302 433.15662,469.91168  497.13733,487.67902 515.38605,484.92932 518.24426,473.2959 545.57031,463.97748 557.65002,462.27423 593.70917,462.46887  592.1701,453.37366 585.35449,441.74023 574.58118,434.12561 556.88495,414.9342 545.28766,408.65234 544.07959,406.47784  544.80444,393.99466 546.81787,391.49802 547.14001,389.64566 547.38159,387.71277 546.89838,373.94101 551.0863,361.05511  550.55707,359.32553  "
          />
          <g
            className={`district pendrecht ${
              activeDistrict === "pendrecht" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("pendrecht")}
          >
            <polygon
              className="shape pendrecht"
              fill="#FFFFFF"
              points="508.16626,463.69357 514.36151,433.69928 489.57974,429.47278  471.7157,445.33649 465.99008,453.92935 482.02182,462.30182  "
            />
          </g>
          <g
            className={`district carnisse ${
              activeDistrict === "carnisse" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("carnisse")}
          >
            <polygon
              className="shape carnisse"
              fill="#FFFFFF"
              points="505.41772,401.52576 520.99139,398.4411 528.7782,401.08511  532.07965,388.59869 507.11322,379.49805 501.06622,388.52628 499.00497,405.2713 505.18872,405.71204  "
            />
          </g>
          <g
            className={`district zuidplein ${
              activeDistrict === "zuidplein" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("zuidplein")}
          >
            <path
              className="shape zuidplein"
              fill="#FFFFFF"
              d="M532.18457,403.90079c0.22906,0,0.45807,0.22037,0.45807,0.66095l0.1618,2.31879 l8.53693-1.04697l2.73822,0.64429l0.72485-12.48318l2.01343-2.49664l0.32214-1.85236l-10.55035-1.69125l-2.255,0.48322 l-4.02686,14.73822L532.18457,403.90079z"
            />
          </g>
          <g
            className={`district oud-charlois ${
              activeDistrict === "oud-charlois" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("oud-charlois")}
          >
            <polygon
              className="shape oud-charlois"
              fill="#FFFFFF"
              points="476.07346,371.51239 464.73669,403.01947 472.52353,407.20575  480.5394,409.18866 495.65512,410.29034 496.11319,407.42609 498.65686,388.19601 507.27429,375.79333 501.35233,377.93375  484.32971,362.95139 472.13779,363.17169 471.995,369.05096  "
            />
          </g>
          <g
            className={`district tarwewijk ${
              activeDistrict === "tarwewijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("tarwewijk")}
          >
            <polygon
              className="shape tarwewijk"
              fill="#FFFFFF"
              points="533.36603,386.74429 536.9118,385.45776 547.38159,387.71277  546.89838,373.94101 551.0863,361.05511 550.55707,359.32553 547.22052,356.78668 547.2085,359.89127 545.36816,364.51819  510.10114,374.83954 508.48434,377.60468  "
            />
          </g>
          <g
            className={`district zuiderpark ${
              activeDistrict === "zuiderpark" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("zuiderpark")}
          >
            <path
              className=":patj:zuiderpark"
              fill="#FFFFFF"
              d="M555.77808,462.02826l-2.5954,0.44061h40.52649l-1.53906-9.09521 l-6.81561-11.63342l-10.77332-7.61462l-17.69623-19.19141l-11.59729-6.28186l-4.28857-0.66443l-8.39594,1.32886l-1.93286-1.08728 l-0.36243-2.83893l-1.81207-0.96643l-0.72485-1.32886l-6.64429-2.35571l-13.34894,2.41614l-0.36243,5.07379l-8.87921-0.54358 l-0.78522,4.77179l-12.68451-0.54364c0,0,0.64261,13.80185,0.42276,14.0134l-9.30197,9.66443l-2.85809,0.43747l-2.29028-3.52527 l-0.01416-1.12817l5.9364-20.72852l-5.00204-0.90781l-2.05371-1.44968l-8.33481,21.18237l1.53912,0.4231l2.13965,15.21625 l-7.46771,11.05139l-24.62488,13.74866l11.21313,2.74963l40.45508,12.05646l12.3125,2.96124l18.24872-2.74969l2.85822-11.63342 c0-0.21149,27.74225-9.31616,27.74225-9.31616l-0.4162-0.00226l-38.52515,2.00342l-26.79596-2.20331h-0.229l-16.65674-8.69312 c-0.02856,0.00739-0.05627,0.021-0.08533,0.021l3.56586-6.15546l3.92401-5.59412l18.53754-15.92737 c0-0.21234,26.87946,4.45972,26.87946,4.45972l24.33051,1.91125l17.61072-7.2204l4.86615,5.94623l6.02472,25.69623 l-3.64771,1.73529L555.77808,462.02826z"
            />
          </g>
          <g
            className={`district zuidwijk ${
              activeDistrict === "zuidwijk" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("zuidwijk")}
          >
            <polygon
              className="shape zuidwijk"
              fill="#FFFFFF"
              points="567.00488,456.46777 561.47552,433.45764 557.12653,428.86707  540.54156,435.82129 540.31256,435.82129 540.0835,435.82129 516.61658,434.34357 510.23077,463.8226 549.7146,461.88712  564.81134,458.53229  "
            />
          </g>
          <g
            className={`district wielewaal ${
              activeDistrict === "wielewaal" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("wielewaal")}
          >
            <g className="shape wielewaal">
              <polygon
                fill="#FFFFFF"
                points="478.48892,411.04852 483.19858,411.75821 483.65021,424.98398 476.03729,433.30655  474.35989,434.0162 473.19861,431.62912  "
              />
            </g>
          </g>
          <g className="districten-charlois">
            <g
              className="district-text pendrecht"
              onClick={() => onDistrictClicked("pendrecht")}
              onMouseEnter={() => setActiveDistrict("pendrecht")}
              onMouseLeave={() =>
                activeDistrict === "pendrecht" && setActiveDistrict("")
              }
            >
              <rect
                x={473.33606}
                y={449.55759}
                width={23.32912}
                height={6.46868}
              />
              <rect
                x={467.07153}
                y={443.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={489.21982}
                y={443.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 485.04086 454.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Pendrecht"}
              </text>
              <text
                className="text pendrecht"
                transform="matrix(1 0 0 1 478.12903 447.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("pendrecht")}
              </text>
              <text
                className="difference pendrecht"
                transform="matrix(1 0 0 1 495.4213 447.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("pendrecht")}
              </text>
            </g>
            <g
              className="district-text carnisse"
              onClick={() => onDistrictClicked("carnisse")}
              onMouseEnter={() => setActiveDistrict("carnisse")}
              onMouseLeave={() =>
                activeDistrict === "carnisse" && setActiveDistrict("")
              }
            >
              <rect
                x={504.19046}
                y={393.55759}
                width={19.62035}
                height={6.46868}
              />
              <rect
                x={496.07153}
                y={387.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={518.21985}
                y={387.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 515.0274 398.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Carnisse"}
              </text>
              <text
                className="text carnisse"
                transform="matrix(1 0 0 1 507.12903 391.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("carnisse")}
              </text>
              <text
                className="difference carnisse"
                transform="matrix(1 0 0 1 524.42133 391.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("carnisse")}
              </text>
            </g>
            <g
              className="district-text zuidplein"
              onClick={() => onDistrictClicked("zuidplein")}
              onMouseEnter={() => setActiveDistrict("zuidplein")}
              onMouseLeave={() =>
                activeDistrict === "zuidplein" && setActiveDistrict("")
              }
            >
              <rect
                x={537.43451}
                y={398.55759}
                width={21.13223}
                height={6.46868}
              />
              <rect
                x={530.07153}
                y={392.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={552.21985}
                y={392.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 548.43292 403.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Zuidplein"}
              </text>
              <text
                className="text zuidplein"
                transform="matrix(1 0 0 1 541.12903 396.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("zuidplein")}
              </text>
              <text
                className="difference zuidplein"
                transform="matrix(1 0 0 1 558.42133 396.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("zuidplein")}
              </text>
            </g>
            <g
              className="district-text oud-charlois"
              onClick={() => onDistrictClicked("oud-charlois")}
              onMouseEnter={() => setActiveDistrict("oud-charlois")}
              onMouseLeave={() =>
                activeDistrict === "oud-charlois" && setActiveDistrict("")
              }
            >
              <rect
                x={458.04855}
                y={381.55759}
                width={25.90414}
                height={6.46868}
              />
              <rect
                x={453.07153}
                y={375.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={475.21982}
                y={375.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 470.49911 386.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Oud-Charlois"}
              </text>
              <text
                className="text oud-charlois"
                transform="matrix(1 0 0 1 464.12903 379.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("oud-charlois")}
              </text>
              <text
                className="difference oud-charlois"
                transform="matrix(1 0 0 1 481.4213 379.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("oud-charlois")}
              </text>
            </g>
            <g
              className="district-text tarwewijk"
              onClick={() => onDistrictClicked("tarwewijk")}
              onMouseEnter={() => setActiveDistrict("tarwewijk")}
              onMouseLeave={() =>
                activeDistrict === "tarwewijk" && setActiveDistrict("")
              }
            >
              <rect
                x={512.06018}
                y={367.55759}
                width={25.88092}
                height={6.46868}
              />
              <rect
                x={507.1077}
                y={361.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={529.25598}
                y={361.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 525.55511 372.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Tarwewijk"}
              </text>
              <text
                className="text tarwewijk"
                transform="matrix(1 0 0 1 518.16522 365.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("tarwewijk")}
              </text>
              <text
                className="difference tarwewijk"
                transform="matrix(1 0 0 1 535.45746 365.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("tarwewijk")}
              </text>
            </g>
            <g
              className="district-text zuiderpark"
              onClick={() => onDistrictClicked("zuiderpark")}
              onMouseEnter={() => setActiveDistrict("zuiderpark")}
              onMouseLeave={() =>
                activeDistrict === "zuiderpark" && setActiveDistrict("")
              }
            >
              <rect
                x={509.77374}
                y={419.55759}
                width={24.45375}
                height={6.46868}
              />
              <rect
                x={503.63559}
                y={413.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={525.78387}
                y={413.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 522.82605 424.04959)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Zuiderpark"}
              </text>
              <text
                className="text zuiderpark"
                transform="matrix(1 0 0 1 514.69312 417.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("zuiderpark")}
              </text>
              <text
                className="difference zuiderpark"
                transform="matrix(1 0 0 1 531.98535 417.55707)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("zuiderpark")}
              </text>
            </g>
            <g
              className="district-text zuidwijk"
              onClick={() => onDistrictClicked("zuidwijk")}
              onMouseEnter={() => setActiveDistrict("zuidwijk")}
              onMouseLeave={() =>
                activeDistrict === "zuidwijk" && setActiveDistrict("")
              }
            >
              <rect
                x={523.93451}
                y={448.61575}
                width={17.65524}
                height={6.46868}
              />
              <rect
                x={514.75946}
                y={442.14648}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={536.90778}
                y={442.14557}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 533.11053 453.10776)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Zuidwijk"}
              </text>
              <text
                className="text zuidwijk"
                transform="matrix(1 0 0 1 525.81696 446.77609)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("zuidwijk")}
              </text>
              <text
                className="difference zuidwijk"
                transform="matrix(1 0 0 1 543.10925 446.6152)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("zuidwijk")}
              </text>
            </g>
            <g
              className="district-text wielewaal"
              onClick={() => onDistrictClicked("wielewaal")}
              onMouseEnter={() => setActiveDistrict("wielewaal")}
              onMouseLeave={() =>
                activeDistrict === "wielewaal" && setActiveDistrict("")
              }
            >
              <rect
                x={460.06772}
                y={420.55759}
                width={21.86582}
                height={6.46868}
              />
              <rect
                x={452.63559}
                y={414.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={474.78387}
                y={414.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 471.47165 425.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Wielewaal"}
              </text>
              <text
                className="text wielewaal"
                transform="matrix(1 0 0 1 463.69308 418.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("wielewaal")}
              </text>
              <text
                className="difference wielewaal"
                transform="matrix(1 0 0 1 480.98538 418.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("wielewaal")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-hoogvliet" display="none">
          <polyline
            className="shape gebied gebied-hoogvliet"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="406.30426,365.04932  355.4856,367.09021 325.48422,372.80478 288.74783,384.43796 306.29965,427.50116 307.9086,430.14526 318.54514,448.72662  337.3215,467.09482 377.32333,485.87122 399.77335,492.6062 403.03882,456.68619 402.74713,424.98398 403.24292,422.3989  411.81473,398.92844 408.95746,395.66296 409.16156,376.8866 406.30426,365.04932  "
          />
          <g
            className={`district hoogvliet-zuid ${
              activeDistrict === "hoogvliet-zuid" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("hoogvliet-zuid")}
          >
            <polygon
              className={`district hoogvliet-zuid ${
                activeDistrict === "hoogvliet-zuid" ? "active" : ""
              }`}
              onClick={() => onDistrictClicked("hoogvliet-zuid")}
              fill="#FFFFFF"
              points="307.9086,430.14526 318.54514,448.72662 337.3215,467.09482  377.32333,485.87122 399.77335,492.6062 403.03882,456.68619 402.74713,424.98398 380.68265,420.59689 340.03757,402.27435  335.77951,407.30658 338.74725,418.91946 313.84409,424.72592  "
            />
          </g>
          <g
            className={`district hoogvliet-noord ${
              activeDistrict === "hoogvliet-noord" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("hoogvliet-noord")}
          >
            <polygon
              className="shape hoogvliet-noord"
              fill="#FFFFFF"
              points="411.81473,398.92844 408.95746,395.66296 409.16156,376.8866  406.30426,365.04932 355.4856,367.09021 325.48422,372.80478 288.74783,384.43796 306.29965,427.50116 312.6265,422.19479  335.28058,417.09253 333.03558,406.88797 339.15833,399.33661 382.42563,418.11298 403.24292,422.3989  "
            />
          </g>
          <g className="districten-hoogvliet">
            <g
              className="district-text hoogvliet-zuid"
              onClick={() => onDistrictClicked("hoogvliet-zuid")}
              onMouseEnter={() => setActiveDistrict("hoogvliet-zuid")}
              onMouseLeave={() =>
                activeDistrict === "hoogvliet-zuid" && setActiveDistrict("")
              }
            >
              <rect
                x={346.0968}
                y={442.55759}
                width={29.80763}
                height={6.46868}
              />
              <rect
                x={342.63559}
                y={436.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={364.78387}
                y={436.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 360.85382 447.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Hoogvliet-Zuid"}
              </text>
              <text
                className="text hoogvliet-zuid"
                transform="matrix(1 0 0 1 353.69308 440.71796)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("hoogvliet-zuid")}
              </text>
              <text
                className="difference hoogvliet-zuid"
                transform="matrix(1 0 0 1 370.98538 440.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("hoogvliet-zuid")}
              </text>
            </g>
            <g
              className="district-text hoogvliet-noord"
              onClick={() => onDistrictClicked("hoogvliet-noord")}
              onMouseEnter={() => setActiveDistrict("hoogvliet-noord")}
              onMouseLeave={() =>
                activeDistrict === "hoogvliet-noord" && setActiveDistrict("")
              }
            >
              <rect
                x={334.18335}
                y={382.55759}
                width={33.63456}
                height={6.46868}
              />
              <rect
                x={332.63559}
                y={376.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={354.78387}
                y={376.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 350.5975 387.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Hoogvliet-Noord"}
              </text>
              <text
                className="text hoogvliet-noord"
                transform="matrix(1 0 0 1 343.69308 380.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("hoogvliet-noord")}
              </text>
              <text
                className="difference hoogvliet-noord"
                transform="matrix(1 0 0 1 360.98538 380.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("hoogvliet-noord")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-hoek-van-holland" display="none">
          <polyline
            className="shape gebied gebied-hoek-van-holland"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="77.71553,110.533  80.29617,101.50076 109.19933,111.30719 172.68307,58.14601 180.68306,64.08148 178.61855,67.43631 192.81207,87.5653  196.42497,89.11369 221.45717,100.72657 212.683,122.40394 303.7796,177.62964 294.74734,185.88768 307.65054,215.56505  361.84399,262.27463 348.68271,287.30682 264.81192,230.01663 210.87656,181.24254 163.90891,148.21034 160.55408,146.4039  77.71553,110.533  "
          />
          <g
            className={`district dorp ${
              activeDistrict === "dorp" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("dorp")}
          >
            <polygon
              className="shape dorp"
              fill="#FFFFFF"
              points="348.68271,287.30682 361.84399,262.27463 307.65054,215.56505  294.74734,185.88768 303.7796,177.62964 212.683,122.40394 221.45717,100.72657 196.42497,89.11369 191.52174,96.08141  177.58629,109.7588 180.425,114.40396 180.68306,125.75877 171.90889,133.24263 163.90891,148.21034 210.87656,181.24254  264.81192,230.01663  "
            />
          </g>
          <g
            className={`district strand-en-duin ${
              activeDistrict === "strand-en-duin" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("strand-en-duin")}
          >
            <polygon
              className="shape strand-en-duin"
              fill="#FFFFFF"
              points="160.55408,146.4039 169.32825,131.17812 177.84436,123.95232  177.07016,115.17815 173.1992,109.24268 189.19917,94.27496 192.81207,87.5653 178.61855,67.43631 180.68306,64.08148  172.68307,58.14601 109.19933,111.30719 80.29617,101.50076 77.71553,110.533  "
            />
          </g>
          <g className="districten-hoek-van-holland">
            <g
              className="district-text dorp"
              onClick={() => onDistrictClicked("dorp")}
              onMouseEnter={() => setActiveDistrict("dorp")}
              onMouseLeave={() =>
                activeDistrict === "dorp" && setActiveDistrict("")
              }
            >
              <rect
                x={244.51869}
                y={167.60513}
                width={14.119}
                height={6.46868}
              />
              <rect
                x={233.21317}
                y={161.13585}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={255.36145}
                y={161.13492}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 251.74129 172.09711)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Dorp"}
              </text>
              <text
                className="text dorp"
                transform="matrix(1 0 0 1 244.27066 165.76547)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("dorp")}
              </text>
              <text
                className="difference dorp"
                transform="matrix(1 0 0 1 261.56296 165.60458)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("dorp")}
              </text>
            </g>
            <g
              className="district-text strand-en-duin"
              onClick={() => onDistrictClicked("strand-en-duin")}
              onMouseEnter={() => setActiveDistrict("strand-en-duin")}
              onMouseLeave={() =>
                activeDistrict === "strand-en-duin" && setActiveDistrict("")
              }
            >
              <rect
                x={134.76091}
                y={107.60513}
                width={33.63456}
                height={6.46868}
              />
              <rect
                x={133.21317}
                y={101.13585}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={155.36145}
                y={101.13493}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 152.46901 112.09711)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Strand en Duin"}
              </text>
              <text
                className="text strand-en-duin"
                transform="matrix(1 0 0 1 144.27066 105.76547)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("strand-en-duin")}
              </text>
              <text
                className="difference strand-en-duin"
                transform="matrix(1 0 0 1 161.56296 105.60458)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("strand-en-duin")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-rozenburg" display="none">
          <polygon
            className="shape gebied gebied-rozenburg"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            points="174.8121,272.79074  173.84436,275.11331 188.16692,310.14551 193.39272,339.56479 258.42484,362.59702 264.42484,354.0809 268.29578,341.69382  226.29587,316.91968  "
          />
          <g
            className={`district rozenburg ${
              activeDistrict === "rozenburg" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("rozenburg")}
          >
            <polygon
              className="shape rozenburg"
              fill="#FFFFFF"
              points="174.8121,272.79074 173.84436,275.11331 188.16692,310.14551  193.39272,339.56479 258.42484,362.59702 264.42484,354.0809 268.29578,341.69382 226.29587,316.91968  "
            />
          </g>
          <g className="districten-rozenburg">
            <g
              className="district-text rozenburg"
              onClick={() => onDistrictClicked("rozenburg")}
              onMouseEnter={() => setActiveDistrict("rozenburg")}
              onMouseLeave={() =>
                activeDistrict === "rozenburg" && setActiveDistrict("")
              }
            >
              <rect
                x={207.7202}
                y={325.55759}
                width={26.56085}
                height={6.46868}
              />
              <rect
                x={202.6356}
                y={319.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={224.78389}
                y={319.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 221.71111 330.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Rozenburg"}
              </text>
              <text
                className="text rozenburg"
                transform="matrix(1 0 0 1 213.6931 323.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("rozenburg")}
              </text>
              <text
                className="difference rozenburg"
                transform="matrix(1 0 0 1 230.9854 323.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("rozenburg")}
              </text>
            </g>
          </g>
        </g>
        <g className="layer gebied-pernis" display="none">
          <path
            className="shape gebied gebied-pernis"
            fill="#05146D"
            stroke="#041056"
            strokeMiterlimit={10}
            d="M309.62042,339.43161 l4.3103-7.63861l0.90741-1.74594c0-0.21823,0.2269-0.21823,0.2269-0.21823l0.2269-0.21826l10.66235-6.76562l3.40292-8.51157 l-1.58804-3.92841l-1.36115-2.40073c-0.00421-0.22342-0.0076-0.40869-0.01172-0.63211l4.32199-8.31589l0.68054-1.52774 l-0.73877-0.39597l-25.91718-9.86163l-2.49548,6.98389l-1.19543-0.05389c-0.17215,0.17459-0.44583,0.27213-0.73276,0.27213 l-6.36649-0.32812v18.21844l9.99622,29.2507L309.62042,339.43161z"
          />
          <g
            className={`district pernis ${
              activeDistrict === "pernis" ? "active" : ""
            }`}
            onClick={() => onDistrictClicked("pernis")}
          >
            <path
              className="shape pernis"
              fill="#FFFFFF"
              d="M309.62042,339.43161l4.3103-7.63861l0.90741-1.74594 c0-0.21823,0.2269-0.21823,0.2269-0.21823l0.2269-0.21826l10.66235-6.76562l3.40292-8.51157l-1.58804-3.92841l-1.36115-2.40073 c-0.00421-0.22342-0.0076-0.40869-0.01172-0.63211l4.32199-8.31589l0.68054-1.52774l-0.73877-0.39597l-25.91718-9.86163 l-2.49548,6.98389l-1.19543-0.05389c-0.17215,0.17459-0.44583,0.27213-0.73276,0.27213l-6.36649-0.32812v18.21844 l9.99622,29.2507L309.62042,339.43161z"
            />
          </g>
          <g className="districten-pernis">
            <g
              className="district-text pernis"
              onClick={() => onDistrictClicked("pernis")}
              onMouseEnter={() => setActiveDistrict("pernis")}
              onMouseLeave={() =>
                activeDistrict === "pernis" && setActiveDistrict("")
              }
            >
              <rect
                x={291.84964}
                y={302.55759}
                width={18.30196}
                height={6.46868}
              />
              <rect
                x={282.63559}
                y={296.08832}
                fill="#FFF103"
                width={22.1484}
                height={6.46868}
              />
              <rect
                x={304.78387}
                y={296.0874}
                fill="#041056"
                width={14}
                height={6.46868}
              />
              <text
                className="name "
                transform="matrix(1 0 0 1 301.46991 307.04956)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-Bold'"
                fontSize="3.59371px"
              >
                {"Pernis"}
              </text>
              <text
                className="text pernis"
                transform="matrix(1 0 0 1 293.69308 300.71793)"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getValueForDistrict("pernis")}
              </text>
              <text
                className="difference pernis"
                transform="matrix(1 0 0 1 310.98538 300.55704)"
                fill="#FFFFFF"
                fontFamily="'ProximaNova-ExtrabldIt'"
                fontSize="3.59371px"
              >
                {getDifferenceForDistrict("pernis")}
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
