import css from "@emotion/css";
import React from "react";
import { mq } from "~src/style";

export const YearLabel = (props: any) => (
  <div
    css={css`
      display: inline-block;
      font-weight: bold;
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      position: relative;
      font-family: proxima-nova, sans-serif;
      padding: 4px;
      font-size: 0.5rem;
      ${mq("lg")} {
        font-size: inherit;
        padding: 4px 12px;
      }
    `}
    {...props}
  />
);
