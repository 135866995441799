import React from "react";

export const Bricks = (props: any) => (
  <svg viewBox="0 0 27.75 132.33" {...props}>
    <title>{"bricks-to"}</title>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path fill="#fff" d="M1.76 1.1h24.13v129.69H1.76z" />
        <rect
          fill={props.fill}
          x={0.08}
          y={45}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={0.08}
          y={67.34}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={0.08}
          y={89.67}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={0.08}
          y={112}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={19.82}
          y={55.94}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={9.75}
          y={44.99}
          width={18}
          height={9}
          rx={1.68}
        />
        <rect fill={props.fill} y={55.94} width={18} height={9} rx={1.68} />
        <rect
          fill={props.fill}
          x={19.82}
          y={78.27}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={9.75}
          y={67.32}
          width={18}
          height={9}
          rx={1.68}
        />
        <rect fill={props.fill} y={78.27} width={18} height={9} rx={1.68} />
        <rect
          fill={props.fill}
          x={19.82}
          y={100.61}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={9.75}
          y={89.66}
          width={18}
          height={9}
          rx={1.68}
        />
        <rect fill={props.fill} y={100.61} width={18} height={9} rx={1.68} />
        <rect
          fill={props.fill}
          x={19.82}
          y={123.33}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect fill={props.fill} y={123.33} width={18} height={9} rx={1.68} />
        <rect
          fill={props.fill}
          x={9.75}
          y={111.99}
          width={18}
          height={9}
          rx={1.68}
        />
        <rect
          fill={props.fill}
          x={0.08}
          y={0.01}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={0.08}
          y={22.35}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={19.82}
          y={10.95}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect fill={props.fill} x={9.75} width={18} height={9} rx={1.68} />
        <rect fill={props.fill} y={10.95} width={18} height={9} rx={1.68} />
        <rect
          fill={props.fill}
          x={19.82}
          y={33.28}
          width={7.72}
          height={9}
          rx={1.57}
        />
        <rect
          fill={props.fill}
          x={9.75}
          y={22.33}
          width={18}
          height={9}
          rx={1.68}
        />
        <rect fill={props.fill} y={33.28} width={18} height={9} rx={1.68} />
      </g>
    </g>
  </svg>
);

export default Bricks;
