import { DistrictKey } from "~src/@types/types";

export const transformTargets: {
  [key in DistrictKey]?: { scale: number; x: number; y: number }
} = {
  "gebied-centrum": {
    scale: 4,
    x: -250,
    y: -90
  },
  "gebied-delfshaven": {
    scale: 4,
    x: 60,
    y: -120
  },
  "gebied-overschie": {
    scale: 3,
    x: 120,
    y: 300
  },
  "gebied-noord": {
    scale: 4,
    x: -190,
    y: 160
  },
  "gebied-hillegersberg-schiebroek": {
    scale: 3.2,
    x: -320,
    y: 400
  },
  "gebied-kralingen-crooswijk": {
    scale: 2.5,
    x: -400,
    y: 25
  },
  "gebied-feijenoord": {
    scale: 3,
    x: -405,
    y: -280
  },
  "gebied-ijsselmonde": {
    scale: 3,
    x: -585,
    y: -450
  },
  "gebied-pernis": {
    scale: 4,
    x: 530,
    y: -240
  },
  "gebied-prins-alexander": {
    scale: 1.5,
    x: -310,
    y: 140
  },
  "gebied-charlois": {
    scale: 3.2,
    x: -208,
    y: -520
  },
  "gebied-hoogvliet": {
    scale: 4,
    x: 340,
    y: -630
  },
  "gebied-hoek-van-holland": {
    scale: 4,
    x: 890,
    y: 490
  },
  "gebied-rozenburg": {
    scale: 4,
    x: 850,
    y: -260
  },
  "gebied-overig-haven-industrie": {
    scale: 4,
    x: 0,
    y: 0
  }
};
