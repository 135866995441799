import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Box, Flex } from "@rebass/grid/emotion";
import { Link, navigateTo } from "gatsby";
import React, { useState } from "react";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosArrowUp from "react-ionicons/lib/IosArrowUp";
import IosEye from "react-ionicons/lib/IosEye";
import districts from "~data/districts";
import { WieBezitOnzeStadComponentProps } from "~src/@types/types";
import { LargeScreen, SmallScreen, mq } from "~src/style";
import { colors } from "../style/colors";
import { layers } from "~src/data";
import { Select } from "~components";

const Container = styled.div`
  color: ${colors.white};
  font-weight: 700;
  ${mq("lg")} {
    box-shadow: 0px 0px 20px #0004;
  }
`;

const Title = styled.div`
  padding: 16px;
  background: ${colors.white};
  color: ${colors.black};
  margin: 0;
`;

const StyledLink = (props: any) => (
  <Link
    activeClassName="active"
    css={css`
      border-bottom: 1px solid ${colors.white};
      padding: 8px 8px 8px 48px;
      background: ${colors.blue1};
      display: block;
      color: ${colors.white};
      text-decoration: none;
      position: relative;
      &:hover {
        background: ${colors.blue4};
      }
      &.active {
        background: ${colors.yellow};
        color: ${colors.black};
      }
    `}
    {...props}
  />
);

const LightLink = (props: any) => (
  <StyledLink
    css={css`
      background: ${colors.blue3};
      border-bottom: none;
      padding: 4px 16px 4px 48px;
      &:first-of-type {
        padding-top: 16px;
      }
      &:last-of-type {
        padding-bottom: 16px;
      }
      &::before {
        top: 8px;
      }
      &.active::after {
        top: 10px;
      }
      &:first-child::before {
        top: 20px;
      }
      &:first-child.active::after {
        top: 22px;
      }
    `}
    {...props}
  />
);

const FoldableSection = (props: any) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      css={css`
        padding: 0;
      `}
    >
      <div
        css={css`
          color: ${colors.white};
          background: ${colors.blue1};
          text-decoration: none;
          position: relative;
          padding: 16px 16px 16px 48px;
          cursor: pointer;
          &:hover {
            background: ${colors.blue4};
          }
        `}
        onClick={() => setOpen(!open)}
      >
        {props.title}
        {open ? (
          <IosArrowUp
            color={colors.white}
            fontSize="1rem"
            css={css`
              margin-left: 1rem;
              position: relative;
              top: 0.2rem;
              width: 1rem;
            `}
          />
        ) : (
          <IosArrowDown
            color={colors.white}
            fontSize="1rem"
            css={css`
              margin-left: 1rem;
              position: relative;
              top: 0.2rem;
              width: 1rem;
            `}
          />
        )}
      </div>
      {open && <div>{props.children}</div>}
    </div>
  );
};

export const Selector = (
  props: WieBezitOnzeStadComponentProps & { cssProps: any }
) => {
  const { district: districtKey, fromYear, toYear, cssProps, layer } = props;
  const district = districts[districtKey!];
  if (!district) {
    return <div />;
  }
  const selectOptions = layers.map(layer => ({
    value: layer.key,
    label: layer.title
  }));
  return (
    <Container css={cssProps}>
      <SmallScreen>
        <Flex
          css={css`
            padding: 8px;
          `}
          alignItems="center"
        >
          <Box flex={1}>
            <Select
              css={css`
                color: ${colors.black};
              `}
              options={selectOptions}
              value={selectOptions.find(option => option.value === layer)}
              onChange={({ value }: any) =>
                navigateTo(
                  `/app/${district.slug}/${value}/${fromYear}/${toYear}`
                )
              }
            />
          </Box>
        </Flex>
      </SmallScreen>
      <LargeScreen>
        <Title>
          <IosEye
            css={css`
              position: relative;
              top: 6px;
            `}
          />{" "}
          Bekijk:
        </Title>
        <StyledLink to={`/app/${district.slug}/price/${fromYear}/${toYear}`}>
          Gemiddelde verkoopprijs
        </StyledLink>
        <StyledLink to={`/app/${district.slug}/woz/${fromYear}/${toYear}`}>
          Gemiddelde WOZ-waarde
        </StyledLink>
        <FoldableSection title="Actieve partijen">
          <div>
            <LightLink
              to={`/app/${district.slug}/gemeente/${fromYear}/${toYear}`}
            >
              Gemeente
            </LightLink>
            <LightLink
              to={`/app/${district.slug}/corporaties/${fromYear}/${toYear}`}
            >
              Corporaties
            </LightLink>
            <LightLink
              to={`/app/${
                district.slug
              }/institutionele-beleggers/${fromYear}/${toYear}`}
            >
              Institutionele beleggers
            </LightLink>
            <LightLink
              to={`/app/${
                district.slug
              }/particuliere-verhuur-1-9/${fromYear}/${toYear}`}
            >
              Particulier verhuur / 1-9 woningen
            </LightLink>
            <LightLink
              to={`/app/${
                district.slug
              }/particuliere-verhuur-10-99/${fromYear}/${toYear}`}
            >
              Particulier verhuur / 10-99 woningen
            </LightLink>
            <LightLink
              to={`/app/${
                district.slug
              }/particuliere-verhuur-100/${fromYear}/${toYear}`}
            >
              Particulier verhuur / 100+ woningen
            </LightLink>
            <LightLink
              to={`/app/${
                district.slug
              }/eigenaar-bewoner/${fromYear}/${toYear}`}
            >
              Bewoner is eigenaar
            </LightLink>
          </div>
        </FoldableSection>
      </LargeScreen>
    </Container>
  );
};

export default Selector;
