import { InterpolationWithTheme } from "@emotion/core";
import css from "@emotion/css";
import { navigate } from "@reach/router";
import { Box, Flex } from "@rebass/grid/emotion";
import { range } from "lodash";
import React, { useEffect, useState } from "react";
import { getTrackBackground, Range } from "react-range";
import { Select } from "~components";
import districts from "~data/districts";
import { WieBezitOnzeStadComponentProps } from "~src/@types/types";
import { colors, LargeScreen, SmallScreen } from "~src/style";
import { YearLabel } from "./year-label";
import { navigateTo } from "gatsby";

const STEP = 1;
const MIN = 2008;
const MAX = 2019;

export const TimeSelector = (
  props: WieBezitOnzeStadComponentProps & {
    cssProp?: InterpolationWithTheme<any>;
  }
) => {
  const { district: districtKey, layer, fromYear, toYear } = props;
  const [values, setValues] = useState([
    parseInt(fromYear!, 10),
    parseInt(toYear!, 10)
  ]);
  useEffect(() => {
    navigate(`/app/${district.slug}/${layer}/${values[0]}/${values[1]}`);
  }, [values]);
  const district = districts[districtKey!];
  const withinRange = (year: number) =>
    year >= parseInt(fromYear!, 10) && year <= parseInt(toYear!, 10);
  const getMiddleYear = () => {
    return Math.floor((parseInt(fromYear!, 10) + parseInt(toYear!, 10)) / 2);
  };
  const selectOptions = range(MIN, MAX + 1).map(value => ({
    value: value.toString(),
    label: value.toString()
  }));
  if (!district) {
    return <div />;
  }
  return (
    <>
      <SmallScreen
        css={css`
          background: ${colors.blue1};
          font-weight: bold;
        `}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          css={css`
            /* padding: 0 8px; */
          `}
        >
          <Box
            css={css`
              padding: 4px 4px 4px 8px;
            `}
          >
            Vergelijk
          </Box>
          <Box
            css={css`
              padding: 4px;
              background: ${colors.yellow};
              margin: 0 16px;
            `}
            flex={1}
          >
            <Select
              color={colors.black}
              css={css`
                color: ${colors.black};
              `}
              menuPlacement="top"
              value={selectOptions.find(option => option.value === fromYear)}
              options={selectOptions}
              onChange={({ value }: any) =>
                navigateTo(`/app/${district.slug}/${layer}/${value}/${toYear}`)
              }
            />
          </Box>
          <Box
            css={css`
              padding: 4px;
            `}
          >
            Met
          </Box>
          <Box
            css={css`
              padding: 4px 8px 4px 4px;
              background: ${colors.yellow};
              margin: 0 0 0 16px;
            `}
            flex={1}
          >
            <Select
              color={colors.black}
              css={css`
                color: ${colors.black};
              `}
              menuPlacement="top"
              value={selectOptions.find(option => option.value === toYear)}
              options={selectOptions}
              onChange={({ value }: any) =>
                navigateTo(
                  `/app/${district.slug}/${layer}/${fromYear}/${value}`
                )
              }
            />
          </Box>
        </Flex>
      </SmallScreen>
      <LargeScreen>
        <Flex
          // @ts-ignore
          css={[
            css`
              background: ${colors.blue1};
              height: 100px;
              padding: 0 8px;
            `,
            props.cssProp
          ]}
          flexDirection="column"
          justifyContent="center"
        >
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={setValues}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  position: "relative",
                  width: "100%",
                  padding: "0 3%"
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "4px",
                    width: "100%",
                    position: "relative",
                    background: getTrackBackground({
                      values,
                      colors: [colors.grey, colors.yellow, colors.grey],
                      min: MIN,
                      max: MAX
                    }),
                    alignSelf: "center"
                  }}
                >
                  {children}
                  <Flex
                    css={css`
                      position: absolute;
                      top: -3px;
                      left: -5px;
                      right: -5px;
                      pointer-events: none;
                      transform: none !important;
                    `}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    {range(MIN, MAX + 1).map(value => (
                      <div
                        key={`year-dot-${value}`}
                        onClick={() =>
                          setValues([
                            value <= getMiddleYear()
                              ? value
                              : parseInt(fromYear!, 10),
                            value > getMiddleYear()
                              ? value
                              : parseInt(toYear!, 10)
                          ])
                        }
                        css={css`
                          cursor: pointer;
                          pointer-events: all;
                          width: 10px;
                          height: 10px;
                          border-radius: 100%;
                          background: ${withinRange(value)
                            ? colors.yellow
                            : colors.blue1};
                          border: ${withinRange(value)
                            ? "none"
                            : `2px solid ${colors.grey}`};
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          &::before {
                            content: "";
                            display: ${value === parseInt(fromYear!, 10) ||
                            value === parseInt(toYear!, 10)
                              ? "inline-block"
                              : "none"};
                            position: relative;
                            width: 2px;
                            height: 20px;
                            background: ${colors.yellow};
                            top: 10px;
                          }
                        `}
                      />
                    ))}
                  </Flex>
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  pointerEvents: "none"
                }}
              >
                <div
                  style={{
                    // zIndex: 100,
                    height: "16px",
                    width: "16px",
                    borderRadius: "100%",
                    backgroundColor: colors.yellow
                  }}
                />
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "100px",
                    width: "100px",
                    margin: "-40px 0 0 -40px"
                  }}
                />
              </div>
            )}
          />

          <Flex
            css={css`
              font-weight: bold;
              width: 100%;
            `}
            flexDirection="row"
            justifyContent="space-between"
          >
            {range(MIN, MAX + 1).map(value => (
              <div key={`year-label-${value}`}>
                <YearLabel
                  onClick={() =>
                    setValues([
                      value <= getMiddleYear()
                        ? value
                        : parseInt(fromYear!, 10),
                      value > getMiddleYear() ? value : parseInt(toYear!, 10)
                    ])
                  }
                  css={css`
                    cursor: pointer;
                    pointer-events: all;
                    color: ${value === parseInt(fromYear!, 10)
                      ? colors.yellow
                      : value === parseInt(toYear!, 10)
                      ? colors.blue1
                      : colors.white};
                    background: ${value === parseInt(toYear!, 10)
                      ? colors.yellow
                      : "none"};
                    border-color: ${value === parseInt(fromYear!, 10) ||
                    value === parseInt(toYear!, 10)
                      ? colors.yellow
                      : "none"};
                    pointer-events: ${value === parseInt(fromYear!, 10) ||
                    value === parseInt(toYear!, 10)
                      ? "none"
                      : "auto"};
                  `}
                >
                  <LargeScreen>{value}</LargeScreen>
                  <SmallScreen>{`'${value.toString().substr(2)}`}</SmallScreen>
                </YearLabel>
              </div>
            ))}
          </Flex>
        </Flex>
      </LargeScreen>
    </>
  );
};

export default TimeSelector;
